import { ExclamationIcon } from "@heroicons/react/solid";
import React from "react";
import { Link } from "react-router-dom";
import classNames from "../libs/utils/classNames";

interface AlertMessageProps {
  borderLeft?: boolean;
  border?: boolean;
  text: string;
  button?: string;
  href?: any;
  noTitle?: boolean;
}
const AlertMessage: React.FC<AlertMessageProps> = ({
  text,
  borderLeft,
  border,
  button,
  href,
  noTitle,
}) => {
  return (
    <div
      className={classNames(
        "bg-yellow-50 p-4 w-full",
        borderLeft ? "border-l-4 border-yellow-500" : "",
        border ? "border border-yellow-500 rounded-md" : ""
      )}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-500"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex flex-col items-start gap-2">
          <h3
            className={classNames(
              "text-sm font-medium text-yellow-700",
              noTitle ? "hidden" : ""
            )}
          >
            Attenzione!
          </h3>
          <div className="text-sm">
            <p
              className="text-yellow-700"
              dangerouslySetInnerHTML={{ __html: text }}
            />

            {button && (
              <div className="mt-4 ">
                <Link to={href}>
                  <a className="bg-yellow-700 text-white rounded-full px-4 py-1.5">
                    {button}
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertMessage;
