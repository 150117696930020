import * as Yup from 'yup';
import { RefundStepperForm } from './interfaces';
import { PaymentMethod, PaymentStatus } from '../../../interfaces/payments';

export const refundStepperFormValidator = Yup.object().shape({
  step1: Yup.object().shape({
    status: Yup.mixed<PaymentStatus>()
      .oneOf(
        [PaymentStatus.REFUNDED_PARTIAL, PaymentStatus.REFUNDED_TOTAL],
        'Invalid status'
      )
      .required('Questo campo è obbligatorio'),
  }),
  step2: Yup.object().shape({
    amount: Yup.number()
      .required('Questo campo è obbligatorio')
      .positive('Inserire un valore maggiore di zero')
      .typeError('Questo campo è obbligatorio'),
    notes: Yup.string().max(5000).optional().nullable(),
    date: Yup.string().required('Questo campo è obbligatorio'),
    method: Yup.mixed<PaymentMethod>()
      .oneOf(Object.values(PaymentMethod), 'Invalid status')
      .required('Questo campo è obbligatorio'),
  }),
}) as Yup.SchemaOf<RefundStepperForm>;
