import axios from 'axios';
import qs from 'qs';
import { AppSettings } from '../interfaces/appSettings';

const { REACT_APP_API_URL } = process.env;
const instance = axios.create();

export const find = async ({
  token,
  query = { populate: '*' },
}: {
  token?: string | null;
  query?: object;
}) => {
  const response = await instance.get<AppSettings>(
    `${REACT_APP_API_URL}/app-setting?${qs.stringify(query)}`,
    { headers: { authorization: `Bearer ${token}` } }
  );
  return response;
};

const mySettings = async ({
  token,
  query = { populate: '*' },
}: {
  token?: string | null;
  query?: object;
}) => {
  const response = await instance.get<AppSettings>(
    `${REACT_APP_API_URL}/app-setting/my-settings?${qs.stringify(query)}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response;
};

const appSettings = {
  find,
  mySettings,
};

export default appSettings;
