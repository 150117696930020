export enum RefundsStepperState {
  STEP_1 = 'STEP_1',
  VALIDATING_STEP_1 = 'VALIDATING_STEP_1',
  STEP_2 = 'STEP_2',
  VALIDATING_STEP_2 = 'VALIDATING_STEP_2',
  SUBMIT = 'SUBMIT',
  SUBMITTED = 'SUBMITTED',
}

export enum RefundsStepperAction {
  NEXT = 'NEXT',
  BACK = 'BACK',
  SUBMIT = 'SUBMIT',
  RESET = 'RESET',
}

export enum RefundsStepperService {
  VALIDATE_STEP_1 = 'VALIDATE_STEP_1',
  VALIDATE_STEP_2 = 'VALIDATE_STEP_2',
  SUBMIT_FORM = 'SUBMIT_FORM',
}
