import BadgeBoolean from '../../../components/BadgeBoolean';
import StatusPaymentModal from '../../../components/Modals/StatusPaymentModal';
import Underline from '../../../components/TextLink/Underline';
import classNames from '../../../libs/utils/classNames';
import {
  formatCurrency,
  getCouponTotalHTML,
  getEventParticipantFirstName,
  getEventParticipantLastName,
} from '../../../libs/utils/helpers';
import { Order, ProductType } from '../../../interfaces/orders';
import { Payment } from '../../../interfaces/payments';
import { getParticipantStatus } from '../../Corsi/Tables/SelectPartecipants';
import {
  EventParticipant,
  ParticipantStatus,
} from '../../../interfaces/participants';
import { useParams } from 'react-router-dom';
import NoteModal from '../../../components/Modals/NoteModal';
import { UseQueryResult } from 'react-query';
import { EventResponse } from '../../../interfaces/events';
import { StrapiData, StrapiResponse } from '../../../interfaces/commons';
import moment from 'moment';
import TableHeader from '../../../components/Table/TableHeader';
import eventParticipants from '../../../api/eventParticipants';
import RefundsManager from '../../../components/RefundsManager';
import { mapPaymentMethod } from '../../Tesoreria/utils';

const tableElements = [
  { key: 'profile.badge.badgeNumber', label: 'Tessera' },
  {
    key: 'profile.surname',
    label: 'Cognome',
    class: 'w-[120px] sticky left-0',
  },
  {
    key: 'profile.name',
    label: 'Nome',
    class: 'w-[120px] sticky left-[120px]',
  },
  { key: 'profile.user.email', label: 'Email' },
  { key: 'profile.mobilePhone', label: 'Telefono' },
  { key: 'profile.section.name', label: 'Sezione' },
  { key: 'isPresent', label: 'Presente' },
  { key: 'isCohabitants', label: 'Conviventi' },
  { key: null, label: 'Quota partecipazione' },
  { key: null, label: 'Coupon utilizzato' },
  { key: null, label: 'Quota associativa' },
  { key: null, label: 'Stato pagamento' },
  { key: null, label: 'Metodo di pagamento' },
  { key: null, label: 'Data pagamento' },
  { key: 'createdAt', label: 'Data iscrizione' },
  { key: 'status', label: 'Stato iscrizione' },
  { key: null, label: 'Data ricevuta' },
  { key: null, label: 'Numero ricevuta' },
  { key: null, label: 'Ricevuta' },
  { key: null, label: 'Note' },
  { key: null, label: 'Rimborso' },
  { key: null, label: 'Somma rimborsata' },
  { key: null, label: 'Metodo di rimborso' },
  { key: null, label: 'Data rimborso' },
  { key: null, label: 'Note rimborso' },
];

interface SelectPartecipantsEventProps {
  onCheckAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  eventDetailQuery: UseQueryResult<
    StrapiResponse<EventResponse> | undefined,
    unknown
  >;
  onUpdateParticipant: ({
    status,
    note,
  }: {
    status?: ParticipantStatus | undefined;
    note?: string;
  }) => void;
  checked: boolean;
  partecipations: Partial<
    StrapiData<EventParticipant> & { multiple?: boolean }
  >[];
  onUpdatePayment: ({
    payment,
    id,
  }: {
    payment: Partial<Payment>;
    id?: number;
  }) => void;
  selectedParticipants: Partial<
    StrapiData<EventParticipant> & { multiple?: boolean }
  >[];
  setSelectedParticipants: React.Dispatch<
    React.SetStateAction<
      Partial<StrapiData<EventParticipant> & { multiple?: boolean }>[]
    >
  >;
  onDownloadReceipt: (arr: (number | undefined)[]) => void;
  isLoading: boolean;
  refreshEventParticipants: () => void;
}

const SelectPartecipantsEvent: React.FC<SelectPartecipantsEventProps> = ({
  partecipations,
  selectedParticipants,
  setSelectedParticipants,
  onUpdatePayment,
  onDownloadReceipt,
  onCheckAll,
  checked,
  refreshEventParticipants,
  eventDetailQuery,
  isLoading,
}) => {
  const { eventId } = useParams();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='ring-1 border ring-black ring-opacity-5'>
          <table className='min-w-full table-fixed divide-y divide-gray-300'>
            <TableHeader
              columnsNames={tableElements}
              checked={checked}
              onCheckAll={onCheckAll}
            />
            <tbody className='divide-y divide-gray-200 bg-white'>
              {partecipations.map((participant) => {
                const orderData = participant.attributes?.profile?.data
                  ?.attributes?.orders?.data
                  ? participant.attributes?.profile?.data?.attributes?.orders
                      ?.data[0]
                  : null;

                const participantOrders: StrapiData<Order>[] =
                  participant.attributes?.profile?.data.attributes.orders
                    .data || [];

                const mainPayment = participantOrders.find(
                  (elem) =>
                    elem?.attributes?.productType === ProductType.EVENT &&
                    elem?.attributes?.event?.data?.id ===
                      eventDetailQuery.data?.data?.data.id
                )?.attributes.payment;

                const totalRefund = participantOrders.reduce(
                  (acc, _order) =>
                    (_order.attributes.payment?.data.attributes?.refunds.data[0]
                      ?.attributes?.amount || 0) + acc,
                  0
                );

                const mainRefund = mainPayment?.data.attributes.refunds.data[0];

                console.log('mainRefund', mainRefund);

                const refundMethod = mapPaymentMethod(
                  mainRefund?.attributes.method
                );

                return (
                  <tr
                    key={participant?.id}
                    className={
                      selectedParticipants.find(
                        (_p) => _p.id === participant.id
                      )
                        ? 'bg-sand-50'
                        : 'bg-white'
                    }
                  >
                    <td className='relative w-12 px-6 sm:w-16 sm:px-8'>
                      {selectedParticipants.find(
                        (_p) => _p.id === participant.id
                      ) && (
                        <div className='absolute inset-y-0 left-0 w-0.5 bg-primary' />
                      )}
                      <input
                        type='checkbox'
                        className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary sm:left-6'
                        value={participant?.id}
                        checked={
                          !!selectedParticipants.find(
                            (_p) => _p.id === participant.id
                          )
                        }
                        onChange={(e) =>
                          setSelectedParticipants(
                            e.target.checked
                              ? [
                                  ...selectedParticipants,
                                  { ...participant, multiple: true },
                                ]
                              : selectedParticipants.filter(
                                  (p) => p.id !== participant.id
                                )
                          )
                        }
                      />
                    </td>
                    <td
                      className={classNames(
                        'whitespace-nowrap py-4 pr-3 text-sm',
                        selectedParticipants.find(
                          (_p) => _p.id === participant.id
                        )
                          ? 'text-primary'
                          : 'text-gray-600'
                      )}
                    >
                      {
                        participant?.attributes?.profile?.data?.attributes
                          ?.badge?.data?.attributes.badgeNumber
                      }
                    </td>
                    <td
                      className={classNames(
                        'flex text-left px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-0 min-h-[65px]',
                        selectedParticipants.find(
                          (_p) => _p.id === participant.id
                        )
                          ? 'bg-sand-50'
                          : 'bg-white'
                      )}
                    >
                      <span className='my-auto'>
                        {getEventParticipantLastName(participant.attributes)}
                      </span>
                    </td>
                    <td
                      className={classNames(
                        'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-[120px] min-h-[65px]',
                        selectedParticipants.find(
                          (_p) => _p.id === participant.id
                        )
                          ? 'bg-sand-50'
                          : 'bg-white'
                      )}
                    >
                      {getEventParticipantFirstName(participant.attributes)}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {
                        participant?.attributes?.profile?.data?.attributes?.user
                          ?.data?.attributes?.email
                      }
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {participant?.attributes?.profile?.data?.attributes
                        ?.mobilePhone
                        ? participant?.attributes?.profile?.data?.attributes?.mobilePhone?.replace(
                            '+39',
                            ''
                          )
                        : '-'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {
                        participant?.attributes?.profile?.data?.attributes
                          ?.section?.data?.attributes?.name
                      }
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <BadgeBoolean
                        active={participant?.attributes?.isPresent || false}
                      />
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {/* <p className='text-black mb-3'>
                        Totale: {partecipant.attributes.guests?.length}
                      </p> */}
                      <ol className='ml-4 list-decimal'>
                        {participant?.attributes?.guests?.map((guest) => (
                          <li key={guest.firstName + guest.lastName}>
                            {guest.firstName} {guest.lastName}
                          </li>
                        ))}
                      </ol>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {formatCurrency(
                        participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                          (elem) =>
                            elem?.attributes?.productType ===
                              ProductType.EVENT &&
                            elem?.attributes?.event?.data?.id ===
                              Number(eventId)
                        )?.attributes?.payment?.data?.attributes?.amount
                      )}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: getCouponTotalHTML(
                            participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                              (elem) =>
                                elem?.attributes?.event?.data?.id ===
                                eventDetailQuery.data?.data.data.id
                            )?.attributes?.coupon?.data
                          ),
                        }}
                      />
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {formatCurrency(
                        participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                          (elem) =>
                            elem?.attributes.productType ===
                            ProductType.SUBSCRIPTION
                        )?.attributes.payment?.data.attributes.amount
                      )}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <StatusPaymentModal
                        entity='event'
                        participant={participant as any}
                        onSubmit={(data) => onUpdatePayment(data)}
                        isLoading={isLoading}
                      />
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {orderData?.attributes?.payment?.data?.attributes?.method}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {(() => {
                        const dateValue =
                          orderData?.attributes.payment?.data?.attributes?.date;

                        return dateValue
                          ? moment(dateValue).format('DD/MM/YYYY')
                          : '-';
                      })()}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {moment(participant.attributes?.createdAt).format(
                        'DD/MM/YYYY'
                      )}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {getParticipantStatus(participant?.attributes?.status)}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {orderData?.attributes?.payment?.data?.attributes?.receipt
                        ?.data?.attributes?.code &&
                        moment(
                          orderData?.attributes?.payment?.data?.attributes
                            ?.receipt?.data?.attributes?.date
                        ).format('DD-MM-YYYY')}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {
                        orderData?.attributes?.payment?.data?.attributes
                          ?.receipt?.data?.attributes?.code
                      }
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                      {orderData &&
                        !orderData?.attributes?.payment?.data?.attributes
                          ?.receipt?.data?.id &&
                        participant?.attributes?.status ===
                          ParticipantStatus.CONFIRMED && (
                          <Underline
                            href={`${participant?.id}/ricevute?productType=${
                              ProductType.EVENT
                            }&paymentId=${
                              orderData?.attributes?.payment?.data?.id
                            }&orderId=${
                              participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                                (elem) =>
                                  elem.attributes.productType ===
                                  ProductType.EVENT
                              )?.id
                            }`}
                            label='Crea ricevuta'
                          />
                        )}
                      {participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                        (elem) =>
                          Number(elem.attributes.event?.data?.id) ===
                          Number(eventDetailQuery.data?.data.data.id)
                      )?.attributes.payment?.data?.attributes.receipt?.data
                        ?.id && (
                        <button
                          onClick={() =>
                            onDownloadReceipt([
                              participant?.attributes?.profile?.data?.attributes?.orders?.data?.find(
                                (elem) =>
                                  elem.attributes.event?.data?.id ===
                                  eventDetailQuery.data?.data?.data.id
                              )?.attributes.payment?.data?.attributes.receipt
                                ?.data?.id,
                            ])
                          }
                          disabled={false}
                        >
                          <Underline label='Scarica ricevuta' />
                        </button>
                      )}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                      <NoteModal
                        partecipantName={`${` ${participant.attributes?.profile?.data?.attributes?.name} ${participant.attributes?.profile?.data?.attributes?.surname}`}`}
                        id={String(participant.id)}
                        type='event'
                        initialNote={participant.attributes?.note}
                        updateEntity={eventParticipants.update}
                      />
                    </td>

                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                      <RefundsManager
                        entityType='event'
                        entityId={Number(eventDetailQuery.data?.data.data.id)}
                        onRefundDone={refreshEventParticipants}
                        orders={participantOrders}
                      />
                    </td>

                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                      {formatCurrency(totalRefund)}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                      {refundMethod}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                      {mainRefund?.attributes.date
                        ? moment(mainRefund?.attributes.date).format(
                            'DD/MM/YYYY'
                          )
                        : '-'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
                      {mainRefund?.attributes.notes}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SelectPartecipantsEvent;
