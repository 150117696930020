import { CourseAttributes } from './courses';
import { Guest } from './event-participants';
import { EventAttributes, EventGuest, EventResponse } from './events';
import { ProductType } from './orders';
import { Profile } from './profile';

export interface ParticipantsResponse {
  attributes: Participant;
  id: number;
}

export interface EventParticipantsResponse {
  attributes: EventParticipant;
  id: number;
}

export interface Participant {
  profile?: { data: Profile };
  evaluation?: string | null;
  cumLaude?: boolean;
  note?: string | null;
  course?: { data: { attributes: CourseAttributes | null; id: number } };
  status?: ParticipantStatus;
  createdAt?: string;
  event?: { data: { attributes: EventAttributes | null; id: number } };
  guests?: Guest[];
  isPresent?: boolean;
  isFreeParticipant?: boolean;
}

export interface ParticipantDataCreation {
  profile: number;
  evaluation?: string | null;
  course?: number;
  event?: string;
  productType?: ProductType;
  guests?: EventGuest[];
  note?: string | null;
}

export type FreeEventParticipantReq = {
  event?: string;
  firstName: string;
  lastName: string;
};

export enum ParticipantStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  WAITING_LIST = 'WAITING_LIST',
}

export interface EventParticipant {
  profile?: { data: Profile };
  evaluation: string | null;
  event?: { data: { attributes: EventAttributes | null; id: number } };
  status?: ParticipantStatus;
  createdAt?: string;
  guests?: Guest[];
  isPresent?: boolean;
  isFreeParticipant: boolean;
  freeParticipant?: FreeParticipant;
  note: string;
}

export type FreeParticipant = {
  firstName: string;
  lastName: string;
};

export const PARTICIPANTS_STATUS_OPTIONS = [
  {
    id: ParticipantStatus.CONFIRMED,
    attributes: { name: 'Confermato' },
  },
  {
    id: ParticipantStatus.CANCELED,
    attributes: { name: 'Annullato' },
  },
  {
    id: ParticipantStatus.NOT_CONFIRMED,
    attributes: { name: 'Non iscritto' },
  },
  {
    id: ParticipantStatus.PENDING,
    attributes: { name: 'In attesa' },
  },
  {
    id: ParticipantStatus.WAITING_LIST,
    attributes: { name: "In lista d'attesa" },
  },
];
