import { useFormContext } from 'react-hook-form';
import { RefundStepperForm } from './interfaces';
import InputPresentational from '../../Form/InputPresentational';
import TextAreaPresentational from '../../Form/TextAreaPresentational';
import { useMemo } from 'react';
import { PaymentMethod, PaymentStatus } from '../../../interfaces/payments';
import SelectPresentational from '../../Form/SelectPresentational';
import { PAYMENT_METHOD_OPTIONS } from '../../../libs/utils/options';

const REFUNDS_PAYMENT_METHOD_OPTIONS = PAYMENT_METHOD_OPTIONS.filter(
  (_method) =>
    [
      //per i rimborsi sono disponibili i seguenti metodi di pagamento:
      PaymentMethod.BANK_TRANSFER_SITE,
      PaymentMethod.BANK_TRANSFER_SECTION,
      PaymentMethod.PAYPAL,
      PaymentMethod.AXERVE,
      PaymentMethod.CASH,
    ].includes(_method.value)
);

const PaymentRefundStep2: React.FunctionComponent = () => {
  const refundsForm = useFormContext<RefundStepperForm>();
  const {
    register,
    formState: { errors },
    watch,
    control,
  } = refundsForm;

  const isRefundTotal = useMemo(
    () => watch('step1.status') === PaymentStatus.REFUNDED_TOTAL,
    [refundsForm.watch('step1.status')]
  );

  return (
    <div className='grid grid-cols-1 gap-2'>
      <InputPresentational
        id='step2.amount'
        name='step2.amount'
        register={register}
        type='number'
        label='Totale da rimborsare'
        error={errors.step2?.amount}
        //Se lo stato del imborso è REFUND_TOTAL non si può modificare l'input
        disabled={isRefundTotal}
      />
      <SelectPresentational
        name='step2.method'
        label='Metodo di pagamento'
        control={control}
        error={{ ...errors?.step2?.method, type: 'required' }}
        options={REFUNDS_PAYMENT_METHOD_OPTIONS}
      />
      <InputPresentational
        id='step2.date'
        name='step2.date'
        register={register}
        type='date'
        label='Data rimborso'
        error={errors.step2?.date}
      />
      <TextAreaPresentational
        id='step2.notes'
        name='step2.notes'
        register={register}
        label='Note'
        error={errors.step2?.notes}
      />
    </div>
  );
};

export default PaymentRefundStep2;
