import { StrapiMeta } from './commons';
import { OnavPosition } from './onavPosition';
import { Section } from './section';

export interface AudiencesListResponse {
  meta: StrapiMeta;
  data: Audience[];
}

export interface AudienceResponse {
  data: Audience;
  meta: StrapiMeta;
}

export interface Audience {
  id: number;
  attributes: {
    title: string;
    audienceQuery: any;
    id_sendgrid_list: number;
    numberOfUsers: number;
    updatedAt: string;
  };
}

export interface ParticipantsAudienceForm {
  title?: string;
  type?: ParticipantsAudienceType;
  selectedParticipantsNumber: number;
  totalParticipantsNumber: number;
}

export enum ParticipantsAudienceType {
  SELECTION = 'SELECTION',
  WHOLE = 'WHOLE',
}

export interface ParticipantsAudienceData {
  title?: string;
  audienceQuery: object;
}
