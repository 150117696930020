import { useMutation, useQueryClient } from 'react-query';
import orders from '../../api/orders';
import { useState } from 'react';
import UnderlineButton from '../Buttons/UnderlineButton';
import ConfirmationModal from './ConfirmationModal';
import { Order } from '../../interfaces/orders';
import { useAuth } from '../../contexts/Auth';
import { PaymentStatus } from '../../interfaces/payments';
import { toast } from 'react-toastify';

interface Props {
  subscription: { attributes: Order; id: number };
}

const DisconnectOrderModal: React.FC<Props> = ({ subscription }) => {
  const [isDisconnectingOrder, setIsDisconnectingOrder] =
    useState<boolean>(false);

  const [{ profile, token }] = useAuth();

  const toggleDisconnectingOrder = () => setIsDisconnectingOrder((v) => !v);
  const queryClient = useQueryClient();

  const { mutate: orderDisconnectionMutation } = useMutation(
    'disconnectOrder',
    orders.disconnect,
    {
      onSuccess: () => {
        queryClient.refetchQueries('getOrdersForCardHistories');
        queryClient.refetchQueries('profile2');
        toggleDisconnectingOrder();
        toast.success('Operazione conclusa con successo.');
      },
      onError: () => {
        toast.error('Ooops.. qualcosa è andato storto');
      },
    }
  );

  const handleDisconnectOrder = async ({ orderId }: { orderId: number }) => {
    orderDisconnectionMutation({ id: orderId, token });
  };

  return (
    <div>
      {subscription.attributes?.payment?.data.attributes.status ===
        PaymentStatus.COMPLETED && (
        <UnderlineButton small onClick={toggleDisconnectingOrder}>
          {'Annulla tessera'}
        </UnderlineButton>
      )}
      <ConfirmationModal
        title='*ATTENZIONE*'
        subtitle='Questa operazione è irreversibile. Sei sicuro di voler procedere?'
        isOpen={isDisconnectingOrder}
        toggle={toggleDisconnectingOrder}
        onConfirm={() => handleDisconnectOrder({ orderId: subscription.id })}
        cancelTextButton='Annulla'
        textButton='Conferma'
      />
    </div>
  );
};

export default DisconnectOrderModal;
