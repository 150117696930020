import SelectPresentational from '../Form/SelectPresentational';
import FormLoading from '../Layout/Loading/FormLoading';
import { useQuery } from 'react-query';
import { match } from 'ts-pattern';
import { Control, FieldError } from 'react-hook-form';
import { useAuth } from '../../contexts/Auth';
import { IOption } from '../Form/Multiselect';
import { profileInfo } from '../../api/profile';
import { isDelegato, isDelegatoSezione } from '../../libs/utils/auth';
import sectionsAPIs from '../../api/section';

interface SectionSelectProps {
  defaultValue?: number | string | null;
  label?: string;
  control?: Control<any>;
  register?: any;
  disabled?: boolean;
  required?: boolean;
  legendIcons?: JSX.Element[];
  error?: FieldError;
  excludeCostCenters?: number[];
}

const SectionsSelect: React.FC<SectionSelectProps> = ({
  defaultValue,
  label,
  control,
  disabled = false,
  required,
  legendIcons,
  excludeCostCenters,
  error,
}) => {
  const [{ profile: user, token }] = useAuth();
  const sectionQuery = useQuery('section', () =>
    sectionsAPIs.findOptions({
      query: {
        filters: {
          costCenter: excludeCostCenters
            ? { $notIn: excludeCostCenters }
            : undefined,
        },
      },
      token,
    })
  );

  const profileQuery = useQuery({
    queryKey: ['user-profile', user?.id],
    queryFn: () => profileInfo({ id: user?.profileId!, token }),
  });

  return (
    <>
      {match(sectionQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          const { isSuccess, data: profile } = profileQuery;
          const delegateSection = profile?.data.attributes.section.data?.id;
          const options: IOption[] =
            query.data?.data.map((section) => {
              return {
                label: section.attributes.name,
                value: section.id,
              };
            }) || [];

          if (isSuccess)
            return (
              <SelectPresentational
                label={label || 'Sezione'}
                name='section'
                control={control}
                defaultValue={isDelegato(user) ? delegateSection : defaultValue}
                disabled={isDelegato(user) || disabled}
                options={options}
                required={required}
                legendIcons={legendIcons}
                error={error}
              />
            );
        })
        .exhaustive()}
    </>
  );
};

export default SectionsSelect;

export const UserSectionsSelect: React.FC<
  SectionSelectProps & {
    options?: IOption[];
    onChangeValue?: (option: IOption | undefined) => void;
  }
> = ({
  defaultValue,
  label,
  control,
  disabled = false,
  required,
  legendIcons,
  excludeCostCenters,
  onChangeValue,
  error,
  options = [],
}) => {
  const [{ profile: user, token }] = useAuth();

  const sectionQuery = useQuery('user-sections', () =>
    sectionsAPIs.findOptions({
      query: {
        filters: {
          costCenter: excludeCostCenters
            ? { $notIn: excludeCostCenters }
            : undefined,
        },
        pagination: {
          pageSize: 1000,
        },
      },
      token,
    })
  );

  const profileQuery = useQuery({
    queryKey: ['user-profile', user?.id],
    queryFn: () => profileInfo({ id: user?.profileId!, token }),
  });

  return (
    <>
      {match(sectionQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => <div>Errore...</div>)
        .with({ status: 'success' }, (query) => {
          const { isSuccess, data: profile } = profileQuery;
          const delegateSection = profile?.data.attributes.section.data?.id;
          const currentOptions: IOption[] = [
            ...(options || []),
            ...(query.data?.data.map((section) => {
              return {
                label: section.attributes.name,
                value: section.id.toString(),
              };
            }) || []),
          ];
          if (isSuccess)
            return (
              <SelectPresentational
                label={label || 'Sezione'}
                name='section'
                control={control}
                defaultValue={
                  isDelegatoSezione(user) ? delegateSection : defaultValue
                }
                disabled={isDelegatoSezione(user) || disabled}
                options={currentOptions}
                required={required}
                legendIcons={legendIcons}
                onChangeValue={onChangeValue}
                error={error}
              />
            );
        })
        .exhaustive()}
    </>
  );
};
