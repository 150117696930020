import TitlePage from '../../../components/TitlePage';
import TesoreriaTable from '../../Tesoreria/Tables/TesoreriaTable';
import { UseQueryResult, useMutation, useQuery } from 'react-query';
import receipts from '../../../api/receipts';
import { useAuth } from '../../../contexts/Auth';
import { useParams } from 'react-router';
import FiltersBar from '../../../components/Filters/FiltersBar';
import { FilterType } from '../../../interfaces/filters';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import qs from 'qs';
import Pagination from '../../../components/Pagination';
import {
  PAYMENT_METHODS,
  checkProfile,
  getDateTimeFromDate,
} from '../../../libs/utils/helpers';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { DownloadIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CourseResponse } from '../../../interfaces/courses';
import { StrapiResponse } from '../../../interfaces/commons';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

export default function RicevuteCorso({
  courseDetailQuery,
}: {
  courseDetailQuery: UseQueryResult<StrapiResponse<CourseResponse>, unknown>;
}) {
  const [{ token, profile }] = useAuth();
  const { id: courseId } = useParams();
  const [queryParams] = useSearchParams();
  const [debounceSearchParams] = useDebounce(queryParams, delay);

  const queryFiltersReceipts = {
    filters: {
      orders: { course: courseId },
      paymentMethod: {
        $in: Object.entries(qs.parse(queryParams.get('paymentMethod') + ''))
          .map(([, value]) => value)
          .map((elem: any) => elem.value),
      },
      section: {
        id: {
          $in: Object.entries(qs.parse(queryParams.get('sections') + ''))
            .map(([, value]) => value)
            .map((elem: any) => elem.value),
        },
      },
      $and: [
        {
          date: {
            $gte: qs.parse(
              Object.entries(
                qs.parse(debounceSearchParams.get('date') + '')
              ).map(([, value]) => value as any)[0]?.label
            ).from,
            $lte: getDateTimeFromDate(
              qs.parse(
                Object.entries(
                  qs.parse(debounceSearchParams.get('date') + '')
                ).map(([, value]) => value as any)[0]?.label
              ).to
            ),
          },
        },
      ],
      $or: [
        {
          section: {
            $or: [
              {
                name: { $contains: debounceSearchParams.get('search') },
              },
            ],
          },
        },

        {
          $or: [
            { name: { $contains: debounceSearchParams.get('search') } },
            { code: { $contains: debounceSearchParams.get('search') } },
            {
              notes: { $contains: debounceSearchParams.get('search') },
            },
            {
              description: {
                $contains: debounceSearchParams.get('search'),
              },
            },

            {
              fiscalCode: {
                $contains: debounceSearchParams.get('search'),
              },
            },
            {
              badgeNumber: {
                $contains: debounceSearchParams.get('search'),
              },
            },
            { type: { $contains: debounceSearchParams.get('search') } },
          ],
        },
      ],
    },
    sort: 'date:desc',
    populate: ['entries', 'section', 'profile', 'orders.coupon'],
    pagination: {
      page: debounceSearchParams?.get('page'),
      pageSize: debounceSearchParams?.get('pageSize'),
    },
  };

  const receiptsQuery = useQuery(
    ['listReceipts', ...debounceSearchParams],
    () =>
      receipts.find({
        token,
        query: queryFiltersReceipts,
      })
  );

  const canDelegatoEdit =
    courseDetailQuery.data?.data.attributes.canDelegateEdit;

  const { mutate: receiptsListCSVMutation, isLoading: isDownloadingCSV } =
    useMutation('downloadUsersCSVList', receipts.downloadCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(receiptsQuery.data?.meta?.pagination?.total) >= 10000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 10000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });

  return (
    <>
      <TitlePage title='Dettaglio corso | Ricevute' />

      <form>
        <div className='pt-6 pb-8 space-y-6'>
          <FiltersBar
            filters={[
              { type: FilterType.DATE, label: 'Data', attribute: 'date' },
              {
                type: FilterType.MULTISELECT,
                attribute: 'paymentMethod',
                label: 'Metodo di pagamento',
                key: 'paymentMethodReceipts',
                source: () => ({
                  data: PAYMENT_METHODS,
                }),
              },
              {
                attribute: 'search',
                type: FilterType.SEARCH_BAR,
                label: 'Cerca...',
              },
            ]}
          />

          <div className='flex justify-end w-full'>
            <PrimaryButton
              textSmall
              disabled={isDownloadingCSV}
              isLoading={isDownloadingCSV}
              onClick={() => {
                receiptsListCSVMutation({
                  token,
                  body: {},
                  query: queryFiltersReceipts,
                  fileName: `Lista ricevute corso ${
                    courseDetailQuery.data?.data?.attributes?.title
                  } ${new Date().getTime()}`,
                });
              }}
            >
              <span className='text-sm underline flex flex-row'>
                <DownloadIcon className='w-4 h-4 mt-[3px]' />{' '}
                <p>Scarica CSV ricevute</p>
              </span>
            </PrimaryButton>
          </div>

          <TesoreriaTable
            receiptsQuery={receiptsQuery}
            searchingWords={[
              debounceSearchParams.get('search') + '',
              ...Object.entries(qs.parse(queryParams.get('paymentMethod') + ''))
                .map(([, value]) => value)
                .map((elem: any) => elem.label),
            ]}
            disabled={checkProfile(profile, canDelegatoEdit)}
          />
          <Pagination
            pagination={receiptsQuery.data?.meta?.pagination}
            defaultPageSize={5}
          />
        </div>

        {/* <PrimaryButton onClick={() => {}}>Salva</PrimaryButton> */}
      </form>
    </>
  );
}
