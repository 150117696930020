import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAuth } from '../contexts/Auth';
import { isDelegato, isSegreteriaNazionale } from '../libs/utils/auth';
import { formatCurrency } from '../libs/utils/formatters/currency';
import BalanceTable from '../pages/Eventi/Tables/BalanceTable';
import HeadquarterBalanceTable from '../pages/Eventi/Tables/HeadquarterBalanceTable';
import UnderlineButton from './Buttons/UnderlineButton';
import InputPresentational from './Form/InputPresentational';
import PriceInputPresentational from './Form/PriceInputPresentational';
import RadioGroupPaymentCourse from './Form/RadioGroupPaymentCourse';
import StaticInputPresentational from './Form/StaticInputPresentational';
import H4SpacingLetter from './Typography/H4SpacingLetter';
import { DownloadIcon } from '@heroicons/react/outline';
import { EventRequest, EventStatus } from '../interfaces/events';
import ListaVini from './ListaVini';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import eventsAPIs from '../api/events';
import {
  calcoloTotSpeseSedeSede,
  calcoloTotSpeseSezioneSede,
  calcoloTotSpeseSezioneSezione,
} from '../libs/utils/helpers';
import CustomSwitch from './Form/CustomSwitch';
import BaseModal from './Modals/BaseModal';

interface FormCostiEventiProps {
  estimatedIncome: number;
  estimatedOutflow: number;
  finalIncome: number;
  finalOutflow: number;
  estimatedHeadquarterOutflow: number;
  finalHeadquarterOutflow: number;
  disabled?: boolean;
  disableEditConsuntivo: boolean;
  eventStatus?: EventStatus;
  disableSubscriptions?: boolean;
}

const FormCostiEventi: React.FC<FormCostiEventiProps> = ({
  estimatedIncome,
  estimatedOutflow,
  finalIncome,
  finalOutflow,
  estimatedHeadquarterOutflow,
  finalHeadquarterOutflow,
  disabled,
  eventStatus,
  disableEditConsuntivo,
}) => {
  const {
    register,
    watch,
    clearErrors,
    setError,
    formState: { errors },
    setValue,
  } = useFormContext<EventRequest>();
  const [{ profile, settings, token }] = useAuth();
  const { eventId } = useParams();
  const [confirmDisableSubscriptionModal, setConfirmDisableSubscriptionModal] =
    useState(false);

  const subscriptionAmount = watch('subscriptionAmount');
  const initialSubscriptionAmount = watch('initialSubscriptionAmount');
  const subscriptionQuote = watch(
    'headquarterBalance.administrations.estimatedPriceUnit'
  );

  const speseSedeSede = useMemo(
    () => calcoloTotSpeseSedeSede(watch('headquarterBalance')),
    [watch('balance')]
  );

  const speseSezioneSezione = useMemo(
    () => calcoloTotSpeseSezioneSezione(watch('balance')),
    [watch('balance')]
  );

  const speseSezioneSede = useMemo(
    () => calcoloTotSpeseSezioneSede(watch('balance')),
    [watch('balance')]
  );

  const totSpese = useMemo(
    () => speseSedeSede + speseSezioneSede + speseSezioneSezione,
    [speseSedeSede, speseSezioneSede, speseSezioneSezione]
  );

  const estimatedProfit = estimatedIncome - estimatedOutflow;
  const finalProfit = finalIncome - finalOutflow;
  const TransferSum = subscriptionAmount
    ? (subscriptionAmount || 0) + (subscriptionQuote || 0)
    : (initialSubscriptionAmount || 0) + (subscriptionQuote || 0);
  const TransferMarkup = TransferSum * (settings?.bankTransferFee || 0.2);
  const bankTransferTotal = Math.ceil(TransferSum + TransferMarkup);

  const { mutate: downloadBalanceCSVMutation } = useMutation(
    ['downloadBalanceCSV'],
    eventsAPIs.downloadCostsCSV,
    {
      onSuccess: () => {
        toast.success('Operazione avvenuta con successo');
      },
      onError: (error: any) => {
        toast.error(
          error.response?.data?.error?.message ||
            'Ooops... Qualcosa è andato storto'
        );
      },
    }
  );

  useEffect(() => {
    if (
      watch('maxParticipants') < Number(watch('initialMaxParticipants')) &&
      isDelegato(profile) &&
      watch('status') !== EventStatus.DRAFT
    ) {
      setError('maxParticipants', {
        message: 'Puoi solo incrementare il numero massimo di partecipanti!',
      });
    } else {
      clearErrors('maxParticipants');
    }
  }, [watch('maxParticipants')]);

  const toggleConfirmDisableSubscriptions = () =>
    setConfirmDisableSubscriptionModal((v) => !v);

  return (
    <div className='pt-6 pb-8 space-y-8'>
      <div className='space-y-4'>
        <H4SpacingLetter>Quota evento</H4SpacingLetter>
        <p className='mt-1 mb-0 italic text-orange-400 text-xs'>
          Al prezzo unitario sarà aggiunto, se dovuto, il valore della tessera
          dell'anno in corso
        </p>
        <div className='grid md:grid-cols-3 items-end gap-4'>
          <PriceInputPresentational
            id='subscriptionAmount'
            label='Quota evento unitaria GIÀ SCONTATA'
            name='subscriptionAmount'
            type='number'
            register={register}
            required
            error={{
              ...errors.subscriptionAmount,
              type: 'required',
            }}
            disabled={disabled || eventStatus === EventStatus.PUBLISHED}
          />
          <PriceInputPresentational
            id=''
            label='Prezzo unitario di vendita'
            name=''
            type='number'
            value={bankTransferTotal}
            disabled
          />
        </div>
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Modalità di pagamento</H4SpacingLetter>
        <p className='mt-1 mb-0 italic text-orange-400 text-xs'>
          Al prezzo unitario sarà aggiunto, se dovuto, il valore della tessera
          dell'anno in corso
        </p>
        <RadioGroupPaymentCourse isCourseCanceled={disabled} />
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>Numero di partecipanti</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-start gap-4'>
          <InputPresentational
            id='minParticipants'
            label='Numero minimo di partecipanti'
            name='minParticipants'
            type='number'
            register={register}
            required
            error={{
              ...errors.minParticipants,
              type: 'required',
            }}
            disabled={disabled}
          />
          <InputPresentational
            id='maxParticipants'
            label='Numero massimo di partecipanti'
            name='maxParticipants'
            type='number'
            required
            error={{
              ...errors.maxParticipants,
              type: 'required',
            }}
            disabled={disabled}
            register={register}
          />
        </div>
        <CustomSwitch
          checked={!!watch('disableSubscriptions')}
          onChange={() => {
            toggleConfirmDisableSubscriptions();
          }}
          label='Disabilita iscrizioni'
        />

        <BaseModal
          isOpen={confirmDisableSubscriptionModal}
          toggle={toggleConfirmDisableSubscriptions}
          onConfirm={() => {
            toggleConfirmDisableSubscriptions();
            setValue('disableSubscriptions', !watch('disableSubscriptions'));
          }}
          title={`${
            watch('disableSubscriptions') ? 'Attivazione' : 'Sospensione'
          } iscrizioni evento`}
          subtitle={`Sei sicuro di voler <strong>${
            watch('disableSubscriptions') ? 'attivare' : 'sospendere'
          }</strong> le iscrizioni per l'evento ${watch(
            'title'
          )}? </br>Questa variazione sarà valida solo dopo il salvataggio dell'evento.`}
          onCancel={toggleConfirmDisableSubscriptions}
        />
      </div>

      <div className='space-y-4'>
        <H4SpacingLetter>
          Numero di partecipanti CONFERMATI PAGANTI
        </H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-end gap-4'>
          <InputPresentational
            type='number'
            defaultValue={watch('confirmedParticipants')}
            disabled
          />
        </div>
      </div>
      <ListaVini disabled={disabled} />

      <div className='space-y-4 bg-neutral-100 px-3 py-4'>
        <H4SpacingLetter>Andamento dell'evento</H4SpacingLetter>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
          <StaticInputPresentational
            big
            label='Margine preventivo'
            text={formatCurrency(
              estimatedIncome - estimatedOutflow - estimatedHeadquarterOutflow
            )}
          />
          <StaticInputPresentational
            big
            label='Margine consuntivo'
            text={formatCurrency(finalIncome - finalOutflow)}
          />
          <StaticInputPresentational
            big
            label='Scostamento Margine'
            text={formatCurrency(finalProfit - estimatedProfit)}
          />
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
          {
            <StaticInputPresentational
              big
              label='Tot spese di SEZIONE che evade la SEDE'
              text={formatCurrency(speseSezioneSede)}
            />
          }
          <StaticInputPresentational
            big
            label='Tot spese di SEZIONE che evade la SEZIONE'
            text={formatCurrency(speseSezioneSezione)}
          />
          {
            <StaticInputPresentational
              big
              label='Tot spese della SEDE che evade la SEDE'
              text={formatCurrency(speseSedeSede)}
            />
          }
          {
            <StaticInputPresentational
              big
              label='Totale spese'
              text={formatCurrency(totSpese)}
            />
          }
        </div>
      </div>

      <div className='space-y-4'>
        <div className='flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-between gap-4'>
          <H4SpacingLetter>Bilancio</H4SpacingLetter>
          <UnderlineButton
            onClick={() => {
              downloadBalanceCSVMutation({
                token,
                id: eventId,
                fileName: `BILANCIO EVENTO ${watch(
                  'title'
                )}_${new Date().getTime()}`,
              });
            }}
          >
            <DownloadIcon className='w-4 h-4' /> Scarica CSV bilancio
          </UnderlineButton>
        </div>

        <BalanceTable
          type='evento'
          estimatedOutflow={estimatedOutflow}
          estimatedIncome={estimatedIncome}
          finalOutflow={finalOutflow}
          finalIncome={finalIncome}
          disabled={disabled && disableEditConsuntivo}
          //per isEventPublished si intende se l'evento è stato pubblicato o consuntivato
          isEventPublished={[
            EventStatus.PUBLISHED,
            EventStatus.FINAL_BALANCE_DELEGATION,
            EventStatus.FINAL_BALANCE_NATIONAL,
          ].includes(watch('status'))}
        />
      </div>

      {isSegreteriaNazionale(profile) && (
        <div className='space-y-4'>
          <H4SpacingLetter>Spese della sede</H4SpacingLetter>
          <HeadquarterBalanceTable
            type='corso'
            estimatedOutflow={estimatedHeadquarterOutflow}
            finalOutflow={finalHeadquarterOutflow}
            disabled={disabled && disableEditConsuntivo}
            //per isEventPublished si intende se l'evento è stato pubblicato o consuntivato
            isEventPublished={[
              EventStatus.PUBLISHED,
              EventStatus.FINAL_BALANCE_DELEGATION,
              EventStatus.FINAL_BALANCE_NATIONAL,
            ].includes(watch('status'))}
          />
        </div>
      )}
    </div>
  );
};

export default FormCostiEventi;
