import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React from 'react';
import { useMutation, useQueryClient, UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import IconLink from '../../../components/TextLink/IconLink';
import { match } from 'ts-pattern';
import { EventListResponse, EventStatus } from '../../../interfaces/events';
import BadgeStatus from '../../../components/BadgeStatus';

import moment from 'moment';
import {
  Balance,
  HeadquarterBalance,
} from '../../../interfaces/strapiComponents/balance';
import { formatCurrency } from '../../../libs/utils/helpers';
import { deleteEventById } from '../../../api/events';

import { useAuth } from '../../../contexts/Auth';
import DeleteEventModal from '../../../components/Modals/DeleteEventModal';
import { ParticipantStatus } from '../../../interfaces/participants';

const tableElements = [
  'Evento',
  'Sezione',
  'Data',
  'Minimo partecipanti',
  'Partecipanti effettivi',
  'di cui conviventi',
  'Quota di Partecipazione',
  'Entrate Consuntivo',
  'Uscite Consuntivo',
  'Margine Consuntivo',
  'Margine preventivo',
  'Stato',
  '',
];

interface EventsTableProps {
  query: UseQueryResult<EventListResponse>;
}
const EventsTable: React.FC<EventsTableProps> = ({ query }) => {
  const [{ token }] = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: deleteEventMutation } = useMutation({
    mutationFn: (eventId: string) => deleteEventById(eventId, token),
    onSuccess: () => queryClient.invalidateQueries('events'),
  });

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'idle' }, { status: 'loading' }, () => (
              <TableLoading tableElements={tableElements} />
            ))
            .with({ status: 'error' }, () => <div>Errore...</div>)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead className='bg-gray-50 text-sm uppercase text-gray-500'>
                      <tr>
                        {tableElements.map((element, index) => (
                          <th
                            key={index}
                            className='font-normal px-6 py-3 text-left tracking-wide'
                          >
                            {element}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data.data.map((event) => {
                        const { balance, status } = event.attributes;

                        const balanceVoiceKeys = Object.keys(
                          balance || {}
                        ) as unknown as (keyof Balance)[];

                        const getEstimatedOutflow = (
                          voicesToSkip?: string[]
                        ) => {
                          const estimatedOutflows = balanceVoiceKeys.reduce(
                            (estimatedOutflows, balanceVoice) => {
                              const skipVoice =
                                voicesToSkip?.indexOf(balanceVoice)! > -1 ||
                                balanceVoice === 'id';
                              if (skipVoice) return estimatedOutflows;
                              return (
                                estimatedOutflows +
                                balance[balanceVoice]?.estimatedOutflow
                              );
                            },
                            0
                          );
                          return estimatedOutflows;
                        };

                        const getEstimatedIncome = () => {
                          const estimatedIncomes = balanceVoiceKeys.reduce(
                            (estimatedIncomes, balanceVoice) => {
                              if (balanceVoice === 'id')
                                return estimatedIncomes;
                              return (
                                estimatedIncomes +
                                balance[balanceVoice]?.estimatedIncome
                              );
                            },
                            0
                          );
                          return estimatedIncomes;
                        };

                        const getFinalOutflow = (voicesToSkip?: string[]) => {
                          const finalOutflows = balanceVoiceKeys.reduce(
                            (finalOutflows, balanceVoice) => {
                              const skipVoice =
                                voicesToSkip?.indexOf(balanceVoice)! > -1 ||
                                balanceVoice === 'id';
                              if (skipVoice) return finalOutflows;
                              return (
                                finalOutflows +
                                balance[balanceVoice]?.finalOutflow
                              );
                            },
                            0
                          );

                          return finalOutflows;
                        };

                        const getFinalIncome = () => {
                          const finalIncomes = balanceVoiceKeys.reduce(
                            (finalIncomes, balanceVoice) => {
                              if (balanceVoice === 'id') return finalIncomes;
                              return (
                                finalIncomes +
                                balance[balanceVoice]?.finalIncome
                              );
                            },
                            0
                          );
                          return finalIncomes;
                        };

                        let income = 0;
                        let outflow = 0;

                        const calculateFinalMargin: boolean =
                          status === EventStatus.FINAL_BALANCE_DELEGATION ||
                          status === EventStatus.FINAL_BALANCE_NATIONAL;

                        if (calculateFinalMargin) {
                          income = getFinalIncome();
                          outflow = getFinalOutflow();
                        } else {
                          income = getEstimatedIncome();
                          outflow = getEstimatedOutflow();
                        }

                        const canDelete: boolean = status === EventStatus.DRAFT;

                        const deleteEvent = () => {
                          deleteEventMutation(String(event.id));
                        };

                        const effectiveParticipants =
                          event.attributes.partecipations?.data.filter(
                            (partecipation) =>
                              partecipation.attributes.status ===
                              ParticipantStatus.CONFIRMED
                          );

                        const totalGuests = effectiveParticipants?.reduce(
                          (totalGuests, partecipation) => {
                            const partecipationGuest =
                              partecipation.attributes.guests?.length || 0;

                            return totalGuests + partecipationGuest;
                          },
                          0
                        );

                        return (
                          <tr
                            className='hover:bg-neutral-100 cursor-pointer'
                            key={event.id}
                            onClick={() => navigate('modifica/' + event.id)}
                          >
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {event.attributes.title}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {event.attributes.section?.data?.attributes.name}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {moment(event.attributes.startDate).format(
                                'DD/MM/YYYY'
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {event.attributes.minParticipants}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {event.attributes?.programParticipants
                                ?.partecipants || 0}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {event.attributes?.programParticipants
                                ?.totalGuests || 0}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                event.attributes?.participationUnit || 0
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                event.attributes?.programBalance
                                  ?.consuntivoEntrate || 0
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                event.attributes?.programBalance
                                  ?.consuntivoUscite || 0
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                Number(
                                  event.attributes?.programBalance
                                    ?.consuntivoUnitario || 0
                                )
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              {formatCurrency(
                                Number(
                                  event.attributes?.programBalance
                                    ?.preventivoUnitario || 0
                                )
                              )}
                            </td>
                            <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                              <BadgeStatus status={event.attributes.status} />
                            </td>

                            <td className='whitespace-nowrap flex px-6 py-4 text-sm text-gray-500 gap-6'>
                              <IconLink orangeTxt href={'modifica/' + event.id}>
                                <PencilIcon className='w-6 h-6' />
                              </IconLink>

                              <DeleteEventModal
                                title='Eliminazione corso:'
                                buttonType='icon'
                                buttonTxt='Elimina corso'
                                buttonDisabled={!canDelete}
                                onConfirm={deleteEvent}
                                description="Sei sicuro di voler eliminare l'evento"
                                item={event.attributes.title}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default EventsTable;
