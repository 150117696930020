import { CourseStatus } from '../interfaces/commons';

interface StatusOption {
  id: CourseStatus;
  title: string;
}

enum Categories {
  ASSAGGIATORE = 'Assaggiatore',
  SOMMELIER = 'Sommelier',
  ONLINE = 'Online',
  MASTER = 'Master',
  APPROFONDIMENTO = 'Approfondimento',
}

interface LessonCategory {
  id: Categories;
  title: string;
}

export const courseStatus: StatusOption[] = [
  { id: CourseStatus.DRAFT, title: 'Bozza' },
  { id: CourseStatus.TO_APPROVE, title: 'In approvazione' },
  { id: CourseStatus.NOT_APPROVED, title: 'Non approvato' },
  { id: CourseStatus.PUBLISHED, title: 'Pubblicato' },
  {
    id: CourseStatus.FINAL_BALANCE_DELEGATION,
    title: 'Consuntivato da sezione',
  },
  {
    id: CourseStatus.FINAL_BALANCE_NATIONAL,
    title: 'Consuntivato da segreteria nazionale',
  },
  { id: CourseStatus.CANCELED, title: 'Annullato' },
];

export const lessonCategory: LessonCategory[] = [
  { id: Categories.ASSAGGIATORE, title: 'Assaggiatore' },
  { id: Categories.SOMMELIER, title: 'Sommelier' },
  { id: Categories.ONLINE, title: 'Online' },
  { id: Categories.MASTER, title: 'Master' },
  { id: Categories.APPROFONDIMENTO, title: 'Approfondimento' },
];

export const IBAN: string = '';
export const BANK_TRANSFER_MAX_DAYS: number = 5;
export const FINAL_BALANCE_EXPIRATION_DAYS = 90;
