import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import NoteInputTable from '../../../components/Form/NoteInputTable';
import PriceInputTable from '../../../components/Form/PriceInputTable';
import BalanceTableHeaders from '../../Corsi/Tables/Commons/BalanceTableHeader';
import { EventRequest } from '../../../interfaces/events';
import { formatCurrency } from '../../../libs/utils/formatters/currency';
import { CourseRequest } from '../../../interfaces/courses';

interface BalanceTableProps {
  estimatedIncome: number;
  estimatedOutflow: number;
  finalIncome: number;
  finalOutflow: number;
  type?: 'evento' | 'corso';
  disabled?: boolean;
  isCoursePublished?: boolean;
}

const BalanceTable: React.FC<BalanceTableProps> = ({
  type = '',
  estimatedIncome,
  estimatedOutflow,
  finalIncome,
  finalOutflow,
  disabled,
  isCoursePublished,
}) => {
  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden border'>
          <table className='min-w-full divide-y divide-gray-300'>
            <BalanceTableHeaders />
            <tbody className='divide-y divide-gray-200 bg-white'>
              <PriceRow type={type} disabled={disabled} />
              <ContributesRow
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              <CouponsVirtuosity disabled />
              <WinesRow disabled={disabled} />
              <FoodsRow
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              <RoomsRow
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              <SponsorshipRow
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              <TeacherPaymentsRow disabled={disabled} />
              <TeacherRefundsRow disabled={disabled} />

              <DirectionRow disabled={disabled} />
              <CouponsCosts
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              <DiscountExpenses
                disabled={disabled}
                isCoursePublished={isCoursePublished}
              />
              <ExtrasRow disabled={disabled} />
              <OutflowIncomeRow
                estimatedOutflow={estimatedOutflow}
                estimatedIncome={estimatedIncome}
                finalOutflow={finalOutflow}
                finalIncome={finalIncome}
              />

              {/* <ProfitRow
                estimatedOutflow={estimatedOutflow}
                estimatedIncome={estimatedIncome}
                finalOutflow={finalOutflow}
                finalIncome={finalIncome}
              /> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BalanceTable;

const PriceRow = ({ type = '', disabled = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const balance = watch('balance');
  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Quota {type}
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(balance?.price?.estimatedPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(balance?.price?.estimatedOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(balance?.price?.estimatedIncome)}
      </td>

      {/* Bilancio consuntivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(balance?.price?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(balance?.price?.finalOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(balance?.price?.finalIncome)}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.price.notes'
          name='balance.price.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const ContributesRow = ({ disabled = false, isCoursePublished = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const balance = useMemo(() => watch('balance'), [watch('balance')]);

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Contributi
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(balance?.contributes?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(balance?.contributes?.estimatedOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        <PriceInputTable
          id='balance.contributes.estimatedIncome'
          name='balance.contributes.estimatedIncome'
          type='number'
          register={register}
          disabled={disabled || isCoursePublished}
        />
      </td>

      {/* Bilancio consuntivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(balance?.contributes?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(balance?.contributes?.finalOutflow)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        <PriceInputTable
          id='balance.contributes.finalIncome'
          name='balance.contributes.finalIncome'
          type='number'
          register={register}
          disabled={disabled}
        />
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.contributes.notes'
          name='balance.contributes.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const WinesRow = ({ disabled = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const wines = useMemo(() => watch('balance.wines'), [watch('balance')]);

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Acquisto vini
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(wines?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(wines?.estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(wines?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(wines?.finalPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.wines.finalOutflow'
          name='balance.wines.finalOutflow'
          type='number'
          disabled={disabled}
          register={register}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(wines?.estimatedIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.wines.notes'
          name='balance.wines.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const FoodsRow = ({ disabled = false, isCoursePublished = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const food = useMemo(() => watch('balance.food'), [watch('balance')]);

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Cibo
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.food.estimatedPriceUnit'
          name='balance.food.estimatedPriceUnit'
          register={register}
          type='number'
          disabled={disabled || isCoursePublished}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(food?.estimatedOutflow)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(food?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(food?.finalPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.food.finalOutflow'
          name='balance.food.finalOutflow'
          register={register}
          type='number'
          disabled={disabled}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(food?.estimatedIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.food.notes'
          name='balance.food.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const RoomsRow = ({ disabled = false, isCoursePublished = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const rooms = useMemo(() => watch('balance.rooms'), [watch('balance')]);

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Costo sala
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(rooms?.estimatedPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.rooms.estimatedOutflow'
          name='balance.rooms.estimatedOutflow'
          register={register}
          type='number'
          disabled={disabled || isCoursePublished}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(rooms?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(rooms?.finalPriceUnit)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.rooms.finalOutflow'
          name='balance.rooms.finalOutflow'
          register={register}
          type='number'
          disabled={disabled}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(rooms?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.rooms.notes'
          name='balance.rooms.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const SponsorshipRow = ({ disabled = false, isCoursePublished = false }) => {
  const { register } = useFormContext<EventRequest>();

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Spese per promozione corso
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(sponsorship?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.sponsorship.estimatedOutflow'
          name='balance.sponsorship.estimatedOutflow'
          register={register}
          type='number'
          disabled={disabled || isCoursePublished}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(sponsorship?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(sponsorship?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.sponsorship.finalOutflow'
          name='balance.sponsorship.finalOutflow'
          register={register}
          type='number'
          disabled={disabled}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(sponsorship?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.sponsorship.notes'
          name='balance.sponsorship.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const ExtrasRow = ({ disabled = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const extras = useMemo(() => watch('balance.extras'), [watch('balance')]);
  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Extra - Eventuali costi non preventivabili
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(extras?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(extras?.estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(extras?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(extras?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.extras.finalOutflow'
          name='balance.extras.finalOutflow'
          register={register}
          type='number'
          disabled={disabled}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(extras?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.extras.notes'
          name='balance.extras.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const TeacherPaymentsRow = ({ disabled = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const teacherPayments = useMemo(
    () => watch('balance.teacherPayments'),
    [watch('balance')]
  );

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Totale compensi docenti da saldare
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(teacherPayments?.estimatedIncome)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teacherPayments?.estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teacherPayments?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(teacherPayments?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teacherPayments?.finalOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teacherPayments?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.teacherPayments.notes'
          name='balance.teacherPayments.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const TeacherRefundsRow = ({ disabled = false }) => {
  const { register, watch } = useFormContext<EventRequest>();
  const teacherRefunds = useMemo(
    () => watch('balance.teacherRefunds'),
    [watch('balance')]
  );

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Totale rimborsi docenti da saldare
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(teacherRefunds?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teacherRefunds?.estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teacherRefunds?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(teacherRefunds?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(teacherRefunds?.finalOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(teacherRefunds?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.teacherRefunds.notes'
          name='balance.teacherRefunds.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const CouponsCosts = ({
  disabled = false,
  isCoursePublished,
}: {
  disabled?: boolean;
  isCoursePublished?: boolean;
}) => {
  const { register } = useFormContext<EventRequest>();

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Spese totali per coupon
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(couponsExpenses?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.couponsExpenses.estimatedOutflow'
          name='balance.couponsExpenses.estimatedOutflow'
          register={register}
          type='number'
          disabled={disabled || isCoursePublished}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(couponsExpenses?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(couponsExpenses?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(couponsExpenses?.finalOutflow)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(couponsExpenses?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.couponsExpenses.notes'
          name='balance.couponsExpenses.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const DiscountExpenses = ({
  disabled = false,
  isCoursePublished,
}: {
  disabled?: boolean;
  isCoursePublished?: boolean;
}) => {
  const { register } = useFormContext<EventRequest>();

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Spese totali per sconti
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(discountExpenses?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.discountExpenses.estimatedOutflow'
          name='balance.discountExpenses.estimatedOutflow'
          register={register}
          type='number'
          disabled={disabled || isCoursePublished}
        />
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(discountExpenses?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(discountExpenses?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <PriceInputTable
          id='balance.discountExpenses.finalOutflow'
          name='balance.discountExpenses.finalOutflow'
          register={register}
          type='number'
          disabled={disabled}
        />
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(discountExpenses?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.discountExpenses.notes'
          name='balance.discountExpenses.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const CouponsVirtuosity = ({
  disabled = false,
}: {
  disabled?: boolean;
  isCoursePublished?: boolean;
}) => {
  const { register, watch } = useFormContext<EventRequest>();
  const couponsVirtuosity = useMemo(
    () => watch('balance.couponsVirtuosity'),
    [watch('balance')]
  );

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Coupon virtuosità
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(couponsVirtuosity?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(couponsVirtuosity?.estimatedOutflow)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(couponsVirtuosity?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(couponsVirtuosity?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(couponsVirtuosity?.finalOutflow)} */}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {formatCurrency(couponsVirtuosity?.finalIncome)}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.couponsVirtuosity.notes'
          name='balance.couponsVirtuosity.notes'
          type='text'
          register={register}
        />
      </td>
    </tr>
  );
};

const DirectionRow = ({ disabled = false }) => {
  const { register, watch, getValues } = useFormContext<CourseRequest>();
  const direction = useMemo(
    () => watch('balance.direction'),
    [watch('balance')]
  );

  return (
    <tr>
      {/* Voce */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r'>
        Eventuale direzione corso
      </td>

      {/* Bilancio preventivo */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(direction?.estimatedPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(direction?.estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(direction?.estimatedIncome)} */}
      </td>

      {/* Bilancio consuntivo */}

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {/* {formatCurrency(direction?.finalPriceUnit)} */}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        {formatCurrency(direction?.finalOutflow)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-r'>
        {/* {formatCurrency(direction?.finalIncome)} */}
      </td>

      {/* Note */}
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
        <NoteInputTable
          id='balance.direction.notes'
          name='balance.direction.notes'
          type='text'
          disabled={disabled}
          register={register}
        />
      </td>
    </tr>
  );
};

const OutflowIncomeRow = ({
  estimatedOutflow = 0,
  estimatedIncome = 0,
  finalOutflow = 0,
  finalIncome = 0,
}) => {
  return (
    <tr className='bg-gray-50'>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r uppercase'>
        Totale uscite / entrate
      </td>

      <td />
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium'>
        {formatCurrency(estimatedOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'>
        {formatCurrency(estimatedIncome)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium'>
        {formatCurrency(finalOutflow)}
      </td>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'>
        {formatCurrency(finalIncome)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
    </tr>
  );
};

const ProfitRow = ({
  estimatedOutflow = 0,
  estimatedIncome = 0,
  finalOutflow = 0,
  finalIncome = 0,
}) => {
  return (
    <tr className='bg-gray-50'>
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600 border-r uppercase'>
        Margine
      </td>

      {/* Preventivo */}
      <td />
      <td />
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r '>
        {formatCurrency(estimatedIncome - estimatedOutflow)}
      </td>
      {/* Consuntivo */}
      <td />
      <td />
      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-800 font-medium border-r'>
        {formatCurrency(finalIncome - finalOutflow)}
      </td>

      <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'></td>
    </tr>
  );
};
