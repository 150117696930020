import { match } from 'ts-pattern';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddEventParticipantsModal from '../../../../components/Modals/AddEventParticipantsModal';
import TitlePage from '../../../../components/TitlePage';
import { useAuth } from '../../../../contexts/Auth';
import SelectPartecipantsEvent from '../../Tables/SelectPartecipants';
import EventReportModal from '../../../../components/Modals/EventReportModal';
import UnderlineButton from '../../../../components/Buttons/UnderlineButton';
import { CheckIcon } from '@heroicons/react/outline';
import { useDebounce } from 'use-debounce';
import eventParticipants from '../../../../api/eventParticipants';
import { ProductType } from '../../../../interfaces/orders';
import { FilterType } from '../../../../interfaces/filters';
import FiltersBar from '../../../../components/Filters/FiltersBar';
import sectionsAPIs from '../../../../api/section';
import _ from 'lodash';
import payments from '../../../../api/payments';
import { StrapiData, StrapiResponse } from '../../../../interfaces/commons';
import { EventResponse, EventStatus } from '../../../../interfaces/events';
import receipts from '../../../../api/receipts';
import qs from 'qs';
import { ArrowPathIcon } from '../../../../components/CustomIcons';
import {
  EventParticipant,
  PARTICIPANTS_STATUS_OPTIONS,
  Participant,
  ParticipantStatus,
} from '../../../../interfaces/participants';
import CancelParticipationsModal from '../../../../components/Modals/CancelParticipationsModal';
import Pagination from '../../../../components/Pagination';
import RenewParticipationsModal from '../../../../components/Modals/RenewParticipationsModal';
import BaseModal from '../../../../components/Modals/BaseModal';
import moment from 'moment';
import { DownloadIcon } from '@heroicons/react/solid';
import {
  getEventParticipantsListNames,
  getParticipantsAudienceQuery,
  getParticipantsQuery,
} from '../../../../libs/utils/helpers';
import { createEventParticipantsAudience } from '../../../../api/audiences';
import { ParticipantsAudienceForm } from '../../../../interfaces/audiences';
import CreateNewParticipantsAudienceModal from '../../../../components/Modals/CreateNewParticipantsAudienceModal';
import { PAYMENT_STATUSES_OPTIONS_SOURCE } from '../../../../interfaces/payments';

interface Props {
  eventDetailQuery: UseQueryResult<
    StrapiResponse<EventResponse> | undefined,
    unknown
  >;
}

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const PartecipantiEvento: React.FC<Props> = ({ eventDetailQuery }) => {
  const [{ token }] = useAuth();
  const { eventId } = useParams();
  const queryClient = useQueryClient();
  const [queryParams] = useSearchParams();
  const [debouncedSearchParams] = useDebounce(queryParams, delay);
  const [isCancelingParticipations, setIsCancelingParticipations] =
    useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [isRenewingParticipations, setIsRenewingParticipations] =
    useState<boolean>(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = React.useState<
    Partial<StrapiData<EventParticipant> & { multiple?: boolean }>[]
  >([]);

  const toggleWarningModal = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
  };

  const QUERY = useMemo(
    () =>
      getParticipantsQuery({
        queryParams,
        id: eventId,
        entityName: 'event',
      }),
    [debouncedSearchParams]
  );

  const partecipationsQueryFn = () => {
    return eventParticipants.find({
      token,
      query: QUERY,
    });
  };

  const refreshEventParticipants = () => {
    partecipationsQuery.refetch();
    queryClient.invalidateQueries(['eventParticipants', eventId]);
    queryClient.invalidateQueries(['getEvent', eventId]);
  };

  const { mutate: mutatePaymentUpdate, isLoading: isUpdatingPayment } =
    useMutation('updatePayment', payments.update, {
      onSuccess: () => {
        refreshEventParticipants();
        toast.success(`Operazione conclusa con successo!`);
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    });

  const onPartecipationsQuerySuccess = () => {
    queryClient.invalidateQueries([
      'getEventReport',
      eventDetailQuery.data?.data.data.id,
    ]);
    setSelectedParticipants([]);
  };

  const onPartecipationsQueryError = () => {
    toast.error('Errore durante il recupero dei partecipanti');
  };

  const partecipationsQuery = useQuery({
    queryKey: ['eventParticipants', QUERY],
    queryFn: partecipationsQueryFn,
    onSuccess: onPartecipationsQuerySuccess,
    onError: onPartecipationsQueryError,
    staleTime: 0,
  });

  const profileAudienceTotals = useMemo(() => {
    const totalParticipants = partecipationsQuery.data?.meta.pagination?.total;
    const selectedParticipantsWithProfile = selectedParticipants.filter(
      (_participant) => _participant.attributes?.profile?.data?.id
    ).length;

    return {
      selectedParticipantsNumber: selectedParticipantsWithProfile,
      totalParticipantsNumber: totalParticipants,
    };
  }, [partecipationsQuery.data, selectedParticipants]);

  /**
   *
   * Creazione audience
   */

  const {
    mutate: createAudienceMutation,
    isLoading: isCreatingParticipantsAudience,
  } = useMutation({
    mutationFn: createEventParticipantsAudience,
    onSuccess: () => {
      toast.success('Audience salvata con successo');
      queryClient.refetchQueries(['audiences'], { exact: false });
    },
    onError: (err: any) => {
      toast.error(
        err.response.data.error.message || "Qualcosa e' andato storto!"
      );
    },
  });

  const onCreateEventParticipantsAudience = (
    values: ParticipantsAudienceForm
  ) => {
    createAudienceMutation({
      token,
      data: getParticipantsAudienceQuery({
        formValues: values,
        query: QUERY,
        selectedProfilesIds: selectedParticipants.map(
          (_participant) => _participant.attributes?.profile?.data?.id
        ),
      }),
      id: String(eventId),
    });
  };

  const onAddPartecipationMutationSuccess = () => {
    toast.success('Partecipante aggiunto');
    partecipationsQuery.refetch();
  };

  const onAddPartecipationmutationError = (error: any) => {
    toast.error(
      error?.response?.data?.error?.message ||
        "Errore durante l'inserimento del partecipante"
    );
  };

  const addPartecipationMutation = useMutation({
    mutationKey: ['addPartecipation', eventId],
    mutationFn: eventParticipants.create,
    onSuccess: onAddPartecipationMutationSuccess,
    onError: onAddPartecipationmutationError,
  });

  const addFreeParticipantMutation = useMutation({
    mutationKey: ['addFreeParticipant', eventId],
    mutationFn: eventParticipants.addFreeParticipant,
    onSuccess: onAddPartecipationMutationSuccess,
    onError: onAddPartecipationmutationError,
  });

  const { mutate: mutateDownloadReceipt } = useMutation(
    'downloadUserReceipt',
    receipts.downloadReceipt,
    {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto');
      },
      onSuccess: () => {
        toast.success('Ricevuta scaricata con successo');
      },
    }
  );

  const isEventCanceled = useMemo(
    () =>
      eventDetailQuery.data?.data.data.attributes.status ===
      EventStatus.CANCELED,
    [eventDetailQuery.data?.data.data.attributes.status]
  );

  const confirmedParticipants = useMemo(
    () =>
      partecipationsQuery.data?.data.filter(
        (elem) => elem.attributes.status === ParticipantStatus.CONFIRMED
      ),
    [partecipationsQuery.data?.data]
  );

  const maxParticipants = useMemo(
    () => eventDetailQuery.data?.data.data.attributes.maxParticipants,
    [eventDetailQuery.data?.data.data.attributes.maxParticipants]
  );

  const onCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    setSelectedParticipants(
      e.target.checked
        ? partecipationsQuery.data?.data.map((elem) => ({
            ...elem,
            multiple: true,
          })) || []
        : []
    );
  };

  const toggleCancelingParticipations = () => {
    if (
      selectedParticipants.length === 1 &&
      !selectedParticipants[0].multiple
    ) {
      setSelectedParticipants([]);
      setChecked(false);
      return setIsCancelingParticipations((v) => !v);
    }

    if (
      !selectedParticipants.every(
        (elem) => elem?.attributes?.status !== ParticipantStatus.CANCELED
      )
    ) {
      setChecked(false);
      return setSelectedParticipants((oldValues) =>
        oldValues.filter(
          (elem) => elem?.attributes?.status !== ParticipantStatus.CANCELED
        )
      );
    }
    setIsCancelingParticipations((v) => !v);
  };
  const toggleRenewingParticipations = () => {
    const totalParticipants =
      (confirmedParticipants?.length || 0) + selectedParticipants?.length;

    if (totalParticipants > (maxParticipants || 0)) {
      return setIsWarningModalOpen(true);
    }
    if (
      selectedParticipants.length === 1 &&
      !selectedParticipants[0].multiple
    ) {
      setSelectedParticipants([]);
      setChecked(false);
      return setIsRenewingParticipations((v) => !v);
    }

    if (
      selectedParticipants.some(
        (elem) =>
          elem?.attributes?.status === ParticipantStatus.PENDING ||
          elem?.attributes?.status === ParticipantStatus.CONFIRMED
      )
    ) {
      setChecked(false);
      return setSelectedParticipants((oldValues) =>
        oldValues.filter(
          (elem) =>
            elem?.attributes?.status !== ParticipantStatus.PENDING &&
            elem?.attributes?.status !== ParticipantStatus.CONFIRMED
        )
      );
    }
    setIsRenewingParticipations((v) => !v);
  };

  const bulkUpdateParticipants = (
    participants: { id: number; data: Participant }[]
  ) => {
    mutateBulkUpdateParticipants({
      token,
      body: { participants },
    });
  };

  const {
    mutate: mutateBulkUpdateParticipants,
    isLoading: isUpdatingParticipants,
  } = useMutation(
    'bulkUpdateParticipants',
    eventParticipants.bulkUpdateParticipants,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['eventParticipants']);
        queryClient.invalidateQueries(['getEvent', eventId]);
        setSelectedParticipants([]);
        setChecked(false);
        toast.success('Operazione conclusa con successo');
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    }
  );

  const onUpdateParticipant = ({
    status,
    isPresent,
    note,
  }: {
    status?: ParticipantStatus;
    isPresent?: boolean;
    note?: string;
  }) => {
    mutateBulkUpdate({
      token,
      body: {
        participantsIds: selectedParticipants.map((elem) => elem?.id || 0),
        data: { status, isPresent, note },
      },
    });
  };

  const { mutate: participantsCSVMutation, isLoading: isDownloadingCSV } =
    useMutation(
      'downloadCourseParticipantsCSVList',
      eventParticipants.downloadCSV,
      {
        onError: () => {
          toast.error('Ooops... Qualcosa è andato storto.');
        },
        onSuccess: () => {
          if (
            Number(partecipationsQuery.data?.meta?.pagination?.total) >= 10000
          )
            toast.warning(
              "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 10000 elementi."
            );
          else toast.success('Documento CSV scaricato con successo');
        },
      }
    );

  const { mutate: mutateBulkUpdate, isLoading: isUpdatingBulk } = useMutation(
    'bulkUpdateEventParticipants',
    eventParticipants.bulkUpdate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['eventParticipants']);
        queryClient.invalidateQueries(['getEvent', eventId]);

        setSelectedParticipants([]);
        setChecked(false);
        toast.success('Operazione conclusa con successo');
      },
      onError: (error: any) => {
        toast.error(
          error.response.data.error.message || 'Ooops qualcosa è andato storto'
        );
      },
    }
  );

  return (
    <>
      <TitlePage title='Dettaglio evento | Partecipanti' />

      <div>
        <div className='pt-6 pb-8 space-y-6'>
          {/* <FilterPartecipants /> */}
          <FiltersBar
            filters={[
              {
                type: FilterType.MULTISELECT,
                attribute: 'status',
                label: 'Stato pagamento',
                key: 'lessonStatusesList',
                source: () => ({
                  data: PAYMENT_STATUSES_OPTIONS_SOURCE,
                }),
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'subscriptionStatus',
                label: 'Stato iscrizione',
                key: 'subscriptionStatus',
                source: () => ({
                  data: PARTICIPANTS_STATUS_OPTIONS,
                }),
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'sections',
                label: 'Sezione',
                key: 'sectionsListFilters',
                source: (data: any) =>
                  sectionsAPIs.findOptions({
                    ...data,
                    query: { ...data.query },
                    enableAuthentication: false,
                  }),
                searchForAttributes: ['name'],
              },
              {
                type: FilterType.SEARCH_BAR,
                attribute: 'search',
                label: 'Cerca...',
              },
            ]}
          />

          <div className='flex flex-col md:flex-row justify-between'>
            <div className='flex items-center justify-end gap-4'>
              <UnderlineButton
                onClick={() => onUpdateParticipant({ isPresent: true })}
                disabled={selectedParticipants?.length < 1 || isEventCanceled}
              >
                <CheckIcon className='w-4 h-4' /> Segna come presenti
              </UnderlineButton>

              <UnderlineButton
                disabled={
                  partecipationsQuery?.data?.data?.filter(
                    (elem) =>
                      elem.attributes.status !== ParticipantStatus.CANCELED
                  ).length === 0 ||
                  selectedParticipants.every(
                    (elem) =>
                      elem?.attributes?.status === ParticipantStatus.CANCELED ||
                      !elem.multiple
                  ) ||
                  isEventCanceled
                }
                onClick={toggleCancelingParticipations}
              >
                Annulla iscrizione{' '}
                {!selectedParticipants.every(
                  (elem) =>
                    elem?.attributes?.status === ParticipantStatus.CANCELED
                ) &&
                  selectedParticipants.some(
                    (elem) =>
                      elem?.attributes?.status === ParticipantStatus.CANCELED
                  ) && <ArrowPathIcon className='h-5 w-5 mx-2' />}
              </UnderlineButton>
              <UnderlineButton
                disabled={
                  partecipationsQuery?.data?.data?.filter(
                    (elem) =>
                      elem.attributes.status !== ParticipantStatus.CONFIRMED &&
                      elem.attributes.status !== ParticipantStatus.PENDING
                  ).length === 0 ||
                  selectedParticipants.every(
                    (elem) =>
                      elem?.attributes?.status ===
                        ParticipantStatus.CONFIRMED ||
                      elem?.attributes?.status === ParticipantStatus.PENDING ||
                      !elem.multiple
                  ) ||
                  isEventCanceled
                }
                onClick={toggleRenewingParticipations}
              >
                Riattiva iscrizione{' '}
                {!selectedParticipants.every(
                  (elem) =>
                    elem?.attributes?.status === ParticipantStatus.CONFIRMED
                ) &&
                  selectedParticipants.some(
                    (elem) =>
                      elem?.attributes?.status === ParticipantStatus.CONFIRMED
                  ) && <ArrowPathIcon className='h-5 w-5 mx-2' />}
              </UnderlineButton>

              <CancelParticipationsModal
                isOpen={isCancelingParticipations}
                toggle={toggleCancelingParticipations}
                partecipantName={getEventParticipantsListNames(
                  selectedParticipants
                )}
                onConfirm={(status) => onUpdateParticipant({ status })}
                isLoading={
                  isUpdatingPayment || isUpdatingBulk || isUpdatingParticipants
                }
              />

              <RenewParticipationsModal
                isOpen={isRenewingParticipations}
                isLoading={
                  isUpdatingPayment || isUpdatingBulk || isUpdatingParticipants
                }
                toggle={toggleRenewingParticipations}
                onConfirm={(participants) =>
                  bulkUpdateParticipants(participants)
                }
                partecipantName={getEventParticipantsListNames(
                  selectedParticipants
                )}
                selectedParticipants={selectedParticipants}
                eventDetailQuery={eventDetailQuery}
              />
              <BaseModal
                isOpen={isWarningModalOpen}
                toggle={toggleWarningModal}
                title='Attenzione'
                confirmButtonText='Ok'
                onConfirm={() => setIsWarningModalOpen(false)}
                subtitle={`È stato raggiunto il numero massimo di partecipanti, impossibile riattivare lo stato d'iscrizione ${
                  selectedParticipants.length > 1
                    ? 'di questi utenti.'
                    : 'di questo utente.'
                }`}
              />
              <CreateNewParticipantsAudienceModal
                isLoading={isCreatingParticipantsAudience}
                onSubmit={onCreateEventParticipantsAudience}
                selectedParticipantsNumber={
                  profileAudienceTotals.selectedParticipantsNumber
                }
                totalParticipantsNumber={
                  profileAudienceTotals.totalParticipantsNumber
                }
              />
            </div>
            <div className='flex items-center justify-end gap-4'>
              <AddEventParticipantsModal
                participantsQuery={partecipationsQuery}
                entityDate={
                  eventDetailQuery.data?.data?.data?.attributes?.startDate ||
                  new Date().toISOString()
                }
                onConfirm={(_participant) => {
                  if (_participant.type === 'PREMIUM') {
                    addPartecipationMutation.mutate({
                      token,
                      body: {
                        profile: _participant?.profile?.value as number,
                        guests: _participant.guests?.map((_guest) =>
                          _.omit(_guest, 'id')
                        ),
                        event: eventId + '',
                        productType: ProductType.EVENT,
                      },
                      query: {
                        populate: '*',
                      },
                    });
                  } else if (_participant.type === 'FREE') {
                    addFreeParticipantMutation.mutate({
                      token,
                      body: {
                        firstName: _participant.firstName,
                        lastName: _participant.lastName,
                        event: eventId,
                      },
                    });
                  }
                }}
                isLoading={
                  addPartecipationMutation.isLoading ||
                  addFreeParticipantMutation.isLoading
                }
                disabled={isEventCanceled}
              />
              <EventReportModal
                id={eventDetailQuery.data?.data.data.id}
                disabled={isEventCanceled}
              />
              <UnderlineButton
                onClick={() => {
                  participantsCSVMutation({
                    token,
                    body: {},
                    query: QUERY,
                    fileName: `Partecipanti evento ${
                      eventDetailQuery.data?.data.data.id
                    } ${moment().format('HH[:]mm[:]ss')}`,
                  });
                }}
                disabled={isDownloadingCSV}
              >
                <DownloadIcon className='w-4 h-4' /> Scarica CSV
              </UnderlineButton>
            </div>
          </div>

          <>
            <SelectPartecipantsEvent
              eventDetailQuery={eventDetailQuery}
              selectedParticipants={selectedParticipants}
              refreshEventParticipants={refreshEventParticipants}
              setSelectedParticipants={setSelectedParticipants}
              partecipations={partecipationsQuery?.data?.data || []}
              onCheckAll={onCheckAll}
              onUpdateParticipant={onUpdateParticipant}
              checked={checked}
              isLoading={isUpdatingPayment}
              onUpdatePayment={(data) =>
                mutatePaymentUpdate({
                  token,
                  id: data?.id,
                  body: data.payment,
                })
              }
              onDownloadReceipt={(receipts) =>
                mutateDownloadReceipt({
                  token,
                  body: { receipts },
                  fileName: `Ricevute evento ${eventDetailQuery.data?.data.data.attributes.title}`,
                })
              }
            />
            <Pagination
              pagination={partecipationsQuery.data?.meta?.pagination}
            />
          </>
        </div>
      </div>
    </>
  );
};

export default PartecipantiEvento;
