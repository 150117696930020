import moment from 'moment';
import { ReactNode, useMemo } from 'react';
import { useQuery } from 'react-query';
import appSettings from '../../api/appSettings';
import { useAuth } from '../../contexts/Auth';
import statisticsAPIs from '../../api/statisticsAPIs';
import { Statistics } from '../../interfaces/statistics';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/outline';

export function useNextYearStatisticsAvailable() {
  const [{ token }] = useAuth();

  const appSettingsQuery = useQuery(['appSettings'], () =>
    appSettings.find({ token })
  );

  const showNextYear = useMemo(() => {
    const [day, month] =
      appSettingsQuery.data?.data?.displayNextSubscriptionDayMonth?.split(
        '/'
      ) || [moment().day(), moment().month()];
    return moment(`${month}/${day}/${new Date().getFullYear()}`).isSameOrBefore(
      moment().toISOString()
    );
  }, [appSettingsQuery.data]);

  return showNextYear;
}

function calculateChange(
  a: number,
  b: number
): {
  absolute: ReactNode;
  percentage: ReactNode;
} {
  const difference = a - b;
  const percentageChange = ((a - b) / b) * 100;
  const percentage =
    b === 0
      ? '-'
      : (percentageChange >= 0 ? '+' : '') + percentageChange.toFixed(1) + '%';
  return {
    absolute: difference.toFixed(0),
    percentage,
  };
}

function calculateComparison(
  prevYear: Statistics,
  currentYear: Statistics,
  nextYear?: Statistics
) {
  return {
    subscriptions: {
      total: {
        prevYear: prevYear.subscriptionsReports.totals,
        currentYear: currentYear.subscriptionsReports.totals,
        nextYear: nextYear ? nextYear.subscriptionsReports.totals : null,
        nextOrCurrentOnPrevChange: calculateChange(
          nextYear
            ? nextYear.subscriptionsReports.totals
            : currentYear.subscriptionsReports.totals,
          prevYear.subscriptionsReports.totals
        ),
        nextOnCurrentChange: nextYear
          ? calculateChange(
              nextYear.subscriptionsReports.totals,
              currentYear.subscriptionsReports.totals
            )
          : null,
      },
      juniors: {
        prevYear: prevYear.subscriptionsReports.juniors,
        currentYear: currentYear.subscriptionsReports.juniors,
        nextYear: nextYear ? nextYear.subscriptionsReports.juniors : null,
        nextOrCurrentOnPrevChange: calculateChange(
          nextYear
            ? nextYear.subscriptionsReports.juniors
            : currentYear.subscriptionsReports.juniors,
          prevYear.subscriptionsReports.juniors
        ),
        nextOnCurrentChange: nextYear
          ? calculateChange(
              nextYear.subscriptionsReports.juniors,
              currentYear.subscriptionsReports.juniors
            )
          : null,
      },
      renewals: {
        prevYear: prevYear.subscriptionsReports.renewals,
        currentYear: currentYear.subscriptionsReports.renewals,
        nextYear: nextYear ? nextYear.subscriptionsReports.renewals : null,
        nextOrCurrentOnPrevChange: calculateChange(
          nextYear
            ? nextYear.subscriptionsReports.renewals
            : currentYear.subscriptionsReports.renewals,
          prevYear.subscriptionsReports.renewals
        ),
        nextOnCurrentChange: nextYear
          ? calculateChange(
              nextYear.subscriptionsReports.renewals,
              currentYear.subscriptionsReports.renewals
            )
          : null,
      },
      reenrollments: {
        prevYear: prevYear.subscriptionsReports.reenrollments,
        currentYear: currentYear.subscriptionsReports.reenrollments,
        nextYear: nextYear ? nextYear.subscriptionsReports.reenrollments : null,
        nextOrCurrentOnPrevChange: calculateChange(
          nextYear
            ? nextYear.subscriptionsReports.reenrollments
            : currentYear.subscriptionsReports.reenrollments,
          prevYear.subscriptionsReports.reenrollments
        ),
        nextOnCurrentChange: nextYear
          ? calculateChange(
              nextYear.subscriptionsReports.reenrollments,
              currentYear.subscriptionsReports.reenrollments
            )
          : null,
      },
      newSubs: {
        prevYear: prevYear.subscriptionsReports.newSubs,
        currentYear: currentYear.subscriptionsReports.newSubs,
        nextYear: nextYear ? nextYear.subscriptionsReports.newSubs : null,
        nextOrCurrentOnPrevChange: calculateChange(
          nextYear
            ? nextYear.subscriptionsReports.newSubs
            : currentYear.subscriptionsReports.newSubs,
          prevYear.subscriptionsReports.newSubs
        ),
        nextOnCurrentChange: nextYear
          ? calculateChange(
              nextYear.subscriptionsReports.newSubs,
              currentYear.subscriptionsReports.newSubs
            )
          : null,
      },
    },
  };
}

export function useComparisonStatistics({
  year = new Date().getFullYear(),
  date: propsDate,
  sectionId,
}: {
  year?: number;
  date?: string;
  sectionId?: string;
}) {
  const [{ token }] = useAuth();
  const showNextYear = useNextYearStatisticsAvailable();

  const date = moment(propsDate).endOf('day').toISOString();
  return useQuery(['comparisonStatistics', year, date, sectionId], async () => {
    const [currentYear, previousYear, nextYear] = await Promise.all([
      statisticsAPIs.calculate({
        token,
        query: {
          year,
          date: showNextYear
            ? moment(date).subtract(1, 'year').toISOString()
            : moment(date).toISOString(),
          sectionId,
        },
      }),
      statisticsAPIs.calculate({
        token,
        query: {
          year: year - 1,
          date: moment(date)
            .subtract(showNextYear ? 2 : 1, 'year')
            .toISOString(),
          sectionId,
        },
      }),
      showNextYear
        ? statisticsAPIs.calculate({
            token,
            query: {
              year: year + 1,
              date: moment(date).toISOString(),
              sectionId,
            },
          })
        : Promise.resolve(null),
    ]);
    return calculateComparison(
      previousYear,
      currentYear,
      nextYear ?? undefined
    );
  });
}
