import React, {
  useContext,
  createContext,
  ReactNode,
  useReducer,
  useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import { useDebounce } from 'use-debounce';
import { me } from '../api/login';
import { IUser } from '../interfaces/login';
import { addErrorInterceptor } from './addErrorInterceptor';
import { authReducer, INITIAL_AUTH_STATE } from './authReducer';
import { AppSettings } from '../interfaces/appSettings';
import appSettings from '../api/appSettings';
import { getOneProfile } from '../api/profile';
import { ProfileAttributes } from '../interfaces/profile';

enum AuthErrorStates {
  Unauthorized = 401,
}

export enum AuthActionType {
  Login = 'login',
  Logout = 'logout',
  SetProfile = 'setProfile',
  SetAppLoaded = 'setAppLoaded',
  setSettings = 'setSettings',
}

export interface AuthState {
  appLoaded: boolean;
  isAuthenticated: boolean;
  token: string | null;
  settings?: AppSettings;
  profile?: IUser & ProfileAttributes;
}

export interface AuthAction {
  type: AuthActionType;
  token?: string;
  profile?: IUser & ProfileAttributes;
  remember?: boolean;
  settings?: AppSettings;
}

export interface IAction {}

const AuthContext = createContext<[AuthState, (_action: AuthAction) => void]>([
  { ...INITIAL_AUTH_STATE },
  (_action: AuthAction) => {},
]);

const useAuth = () => useContext(AuthContext);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const { token } = cookies;
  const isAuthenticated = !!token;
  const initialState = { ...INITIAL_AUTH_STATE, isAuthenticated, token };
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { appLoaded } = state;
  const [shouldCallMe] = useDebounce(isAuthenticated && !appLoaded, 100);

  const setCookiesAndDispatch = (action: AuthAction) => {
    const { type, token = null, remember = false } = action;
    switch (type) {
      case AuthActionType.Login:
        remember && setCookie('token', token);
        break;
      case AuthActionType.Logout:
        removeCookie('token');
        break;
      default:
        break;
    }
    return dispatch(action);
  };

  useEffect(() => {
    addErrorInterceptor(AuthErrorStates.Unauthorized, () =>
      setCookiesAndDispatch({
        type: AuthActionType.Logout,
      })
    );
  }, []);

  useEffect(() => {
    shouldCallMe
      ? (async () => {
          try {
            const { data } = await me(token);
            const { data: profileData } = await getOneProfile({
              token,
              id: data.profileId + '',
              query: {
                populate: '*',
              },
            });
            const { data: settingsData } = await appSettings.find({ token });
            dispatch({
              type: AuthActionType.SetProfile,
              profile: { ...profileData.attributes, ...data },
            });
            dispatch({
              type: AuthActionType.setSettings,
              settings: settingsData,
            });
          } catch (error) {
            setCookiesAndDispatch({ type: AuthActionType.Logout });
          }
        })()
      : dispatch({ type: AuthActionType.SetAppLoaded });
  }, [shouldCallMe]);

  return (
    <AuthContext.Provider value={[state, setCookiesAndDispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
