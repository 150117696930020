import React from "react";

interface H3StyledProps {
  children: any;
}

const H3Styled: React.FC<H3StyledProps> = ({ children }) => {
  return (
    <h3 className="text-gray-700 text-2xl font-IBM font-light">{children}</h3>
  );
};

export default H3Styled;
