import { useMemo, useState } from 'react';
import { StrapiData } from '../../../interfaces/commons';
import _ from 'lodash';
import ConfirmationModal from '../ConfirmationModal';
import Underline from '../../TextLink/Underline';
import { Payment } from '../../../interfaces/payments';
import { Order, ProductType } from '../../../interfaces/orders';
import RefundBox from './RefundBox';

interface Props {
  participantOrders: StrapiData<Order>[];
}

const PaymentRefundModalVisualisation: React.FunctionComponent<Props> = (
  props
) => {
  const { participantOrders } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen((v) => !v);
  };

  const mainOrder = useMemo(
    () =>
      participantOrders.find(
        (_order) => _order.attributes.productType !== ProductType.SUBSCRIPTION
      ),
    [participantOrders]
  );

  const subscriptionOrder = useMemo(
    () =>
      participantOrders.find(
        (_order) => _order.attributes.productType === ProductType.SUBSCRIPTION
      ),
    [participantOrders]
  );

  const mainRefund = useMemo(
    // l'accesso al primo elemento della lista "refunds.data" è tollerabile per via del fatto che
    //attualmente i pagamenti hanno soltanto un rimborso
    () => mainOrder?.attributes.payment?.data?.attributes?.refunds?.data[0],
    [mainOrder]
  );

  const subscriptionRefund = useMemo(
    () =>
      // l'accesso al primo elemento della lista "refunds.data" è tollerabile per via del fatto che
      //attualmente i pagamenti hanno soltanto un rimborso
      subscriptionOrder?.attributes.payment?.data?.attributes?.refunds?.data[0],
    [participantOrders]
  );

  const mainRefundEntityName = useMemo(
    () =>
      mainOrder?.attributes.productType === ProductType.COURSE
        ? 'corso'
        : 'evento',
    [mainOrder]
  );

  return (
    <>
      <button
        onClick={() => {
          toggle();
        }}
      >
        <Underline label={'Vedi rimborso'} />
      </button>
      <ConfirmationModal
        isOpen={isOpen}
        toggle={toggle}
        onConfirm={toggle}
        textButton='Chiudi'
        title='Riepilogo rimborso pagamento'
        cancelTextButton='Annulla'
      >
        <div className='py-6 px-6'>
          {mainRefund && (
            <RefundBox
              title={`Rimborso ${mainRefundEntityName}`}
              refund={mainRefund}
            />
          )}
          {subscriptionRefund && (
            <RefundBox
              title='Rimborso quota associativa'
              refund={subscriptionRefund}
            />
          )}
        </div>
      </ConfirmationModal>
    </>
  );
};

export default PaymentRefundModalVisualisation;
