import { assign, createMachine } from 'xstate';
import {
  RefundsStepperAction,
  RefundsStepperService,
  RefundsStepperState,
} from './interfaces';
/**
 * Questa macchina gestisce lo stepper per i rimborsi.
 * NB: Se modifichi la macchina, aggiornare il commento
 *
 * Stati Macchina:
 *  - STEP_1: stato iniziale, è lo step iniziale
 *  - VALIDATING_STEP_1: stato dove viene invocato il servizio di validazione dello STEP_1, VALIDATE_STEP_1
 *  - STEP_2: Secondo step. L'utente inserisce dati del rimborso
 *  - VALIDATING_STEP_2: stato dove viene invocato il servizio di validazione dello STEP_2, VALIDATE_STEP_2
 *  - SUBMIT: Stato dove viene invocato il servizio SUBMIT_FORM per inviare il form
 *  - SUBMITTED: Stato finale della macchina
 *
 * Azioni macchina:
 * NEXT, BACK, SUBMIT, RESET
 *
 * Stato iniziale: STEP_1
 *
 * Azioni valide (Riporto soltanto gli step controllati dall'utente):
 *
 *  - STEP_1 -> NEXT
 *  - STEP_2 -> BACK, SUBMIT, RESET
 *
 * Transazioni valide:
 *  - STEP_1 -> VALIDATING_STEP_1
 *  - VALIDATING_STEP_1 -> STEP_1, STEP_2
 *  - STEP_2 -> STEP_1, VALIDATING_STEP_2
 *  - VALIDATING_STEP_2 -> SUBMIT, STEP_2
 *  - SUBMIT -> SUBMITTED, STEP_2
 *  - SUBMITTED -> STEP_1
 *
 * Dettagli transazioni
 *
 *  - Da STEP_1 si può andare in VALIDATING_STEP_1, dove avviene la validazione dello STEP_1
 * utilizzando il servizio VALIDTE_STEP_1
 *  - Dallo steto VALIDATING_STEP_1 ci sono due target automatici, uno va nello STEP_2,
 *    l'altro nello STEP_1 (in caso di errore del servizio di validazione)
 *  - Da STEP_2 si può andare in VALIDATING_STEP_2, dove avviene la validazione dello STEP_2
 *  utilizzando il servizio VALIDTE_STEP_2, Oppure in STEP_1 qualora venga invocata l'azione BACK, oppure quando viene invocata l'azione RESET.
 *  - Dallo steto VALIDATING_STEP_2 ci sono due target automatici, uno va nello SUBMIT,
 *    l'altro nello STEP_2 (in caso di errore del servizio di validazione)
 *  - Nello stato SUBMIT avviene l'invio del form tramite il servizio SUBMIT_FORM.
 *    Ci sono due target automatici, uno va nello SUBMITTED, e l'altro nello STEP_2 in caso di errore.
 *  - Nello stato SUBMITTED viene invocato automaticamento l'azione RESET, che riporta nello STEP_1
 */
const refundsStepperMachine = createMachine(
  {
    id: 'refundsStepper',
    initial: RefundsStepperState.STEP_1,
    states: {
      [RefundsStepperState.STEP_1]: {
        on: {
          [RefundsStepperAction.NEXT]: {
            target: RefundsStepperState.VALIDATING_STEP_1,
          },
        },
      },
      [RefundsStepperState.VALIDATING_STEP_1]: {
        invoke: {
          src: RefundsStepperService.VALIDATE_STEP_1,
          onDone: {
            target: RefundsStepperState.STEP_2,
          },
          onError: {
            target: RefundsStepperState.STEP_1,
            actions: assign({
              validationError: (context, event: any) => {
                return event.data;
              },
            }),
          },
        },
      },
      [RefundsStepperState.STEP_2]: {
        on: {
          [RefundsStepperAction.BACK]: {
            target: RefundsStepperState.STEP_1,
          },
          [RefundsStepperAction.SUBMIT]: {
            target: RefundsStepperState.VALIDATING_STEP_2,
          },
          [RefundsStepperAction.RESET]: {
            target: RefundsStepperState.STEP_1,
          },
        },
      },
      [RefundsStepperState.VALIDATING_STEP_2]: {
        invoke: {
          src: RefundsStepperService.VALIDATE_STEP_2,
          onDone: {
            target: RefundsStepperState.SUBMIT,
          },
          onError: {
            target: RefundsStepperState.STEP_2,
            actions: assign({
              validationError: (context, event) => event.data,
            }),
          },
        },
      },
      [RefundsStepperState.SUBMIT]: {
        invoke: {
          src: RefundsStepperService.SUBMIT_FORM,
          onDone: {
            target: RefundsStepperState.SUBMITTED,
          },
          onError: {
            target: RefundsStepperState.STEP_2,
            actions: assign({
              validationError: (context, event) => event.data,
            }),
          },
        },
      },
      [RefundsStepperState.SUBMITTED]: {
        on: {
          [RefundsStepperAction.RESET]: {
            target: RefundsStepperState.STEP_1,
          },
        },
      },
    },
  },
  {}
);

export default refundsStepperMachine;
