import { IOption } from '../components/Form/Selects';
import { Badge, BadgeStatus } from './badge';
import { CardHistory } from './cardHistory';
import { StrapiData, StrapiMeta } from './commons';
import { IUserResponse } from './login';
import { Media } from './media';
import { Nation } from './nation';
import { OnavPosition } from './onavPosition';
import { Order } from './orders';
import { ProfileCategory } from './profileCategory';
import { ProvinceData } from './province';
import { RegionData } from './region';
import { Section } from './section';

export interface ProfileResponse {
  meta: StrapiMeta;
  data: Profile;
}

export interface ProfileListResponse {
  meta: StrapiMeta;
  data: Profile[];
}
export interface Profile {
  id: number;
  attributes: ProfileAttributes;
}

export interface ProfileAttributes {
  name: string;
  surname: string;
  address: string;
  zipcode: string;
  city: string;
  badgeStatus?: BadgeStatus;
  altPhone: string;
  mobilePhone: string;
  createdAt: string;
  yearsInONAV?: number;
  updatedAt: string;
  province: {
    data: ProvinceData | null;
  };
  fiscalCode: string;
  birthPlace: string;
  birthDate: string;
  near: string;
  gender: string;
  isTeacher: boolean;
  isHonoraryMember: boolean;
  isVolunteer: boolean;
  cardHistory: CardHistory[];
  printDigitalBadge: boolean;
  printDigitalMagazine: boolean;
  profession: string;
  qualification: string;
  streetNumber: string;
  orders: { data: StrapiData<Order>[] };
  homeAddress: {
    address: string;
    zipcode: string;
    city: string;
    near: string;
    region: {
      data: RegionData | null;
    };
    nation: {
      data: Nation | null;
    };
    province: {
      data: ProvinceData | null;
    };
    streetNumber: string;
  };

  printPreferences: {
    badge: string;
    magazine: string;
  };
  badge: { data?: Badge | null };
  region: {
    data: RegionData | null;
  };
  nation: {
    data: Nation | null;
  };
  section: {
    data: Section | null;
  };
  delegation: any;
  secondarySections: {
    data: Section[] | null;
  };
  onavPosition: {
    data?: OnavPosition;
  };
  profileCategory: {
    data?: ProfileCategory | null;
  };
  user: {
    data?: IUserResponse | null;
  };
  profileImage: {
    data: Media | null;
  };
  addressesCoincide: boolean;
  settings?: {
    subscription: {
      amount: number;
    };
  };
}

export interface ProfileRequest {
  email?: string;
  gender?: string;
  badgeNumber?: string;
  surname: string;
  name: string;
  fiscalCode: string;
  section?: number;
  mobilePhone: string;
  altPhone?: string;
  birthDate?: string;
  birthPlace?: string;
  addressesCoincide: boolean;
  region?: number;
  province?: number;
  homeAddress?: { region?: number };
}

export interface PrintDiplomaForm {
  category: IOption;
  profile: IOption;
  courseLevel: string;
  evaluation?: number;
  cumLaude: boolean;
  customLocation: string;
  customDate?: string;
  withBackground?: boolean;
}

export enum ProfileSubscriptionStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
}

export enum ProfileBadgeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_RENEWED = 'NOT_RENEWED',
  RENEWED = 'RENEWED',
  NOT_EXISTING = 'NOT_EXISTING',
  REISCRIZIONE_NEXT_YEAR = 'REISCRIZIONE_NEXT_YEAR',
  NEW_SUBSCRIBED_NEXT_YEAR = 'NEW_SUBSCRIBED_NEXT_YEAR',
  ACTIVE_PAST_YEAR = 'ACTIVE_PAST_YEAR',
  CURRENT_INACTIVE_AND_LAST_YEAR_ACTIVE = 'CURRENT_INACTIVE_AND_LAST_YEAR_ACTIVE',
}
