import { useEffect, useMemo, useState } from 'react';

import { useMachine } from '@xstate/react';
import {
  PaymentStatus,
  RefundPaymentRequest,
} from '../../../interfaces/payments';
import { StrapiData } from '../../../interfaces/commons';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import {
  RefundsStepperAction,
  RefundsStepperService,
  RefundsStepperState,
} from '../../../libs/machines/refundsStepperMachine/interfaces';
import { RefundStepperForm } from './interfaces';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { refundStepperFormValidator } from './validators';
import PaymentRefundStep1 from './PaymentRefundStep1';
import PaymentRefundStep2 from './PaymentRefundStep2';
import { useMutation } from 'react-query';
import payments from '../../../api/payments';
import { useAuth } from '../../../contexts/Auth';
import { toast } from 'react-toastify';
import { Order } from '../../../interfaces/orders';
import {
  getMainOrderPaymentID,
  getTotalOrders,
} from '../../../libs/utils/helpers';
import ConfirmationModal from '../ConfirmationModal';
import Underline from '../../TextLink/Underline';
import refundsStepperMachine from '../../../libs/machines/refundsStepperMachine/index.ts';
import { isSegreteriaNazionale } from '../../../libs/utils/auth';
import Spinner from '../../Layout/Loading/Spinner';

interface Props {
  orders: StrapiData<Order>[];
  onRefundDone: () => void;
}

const PaymentRefundModalCreation: React.FunctionComponent<Props> = (props) => {
  const { orders, onRefundDone } = props;
  const [{ token, profile }] = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const totalPaid = useMemo(() => getTotalOrders(orders), [orders]);
  const mainOrderId = useMemo(() => getMainOrderPaymentID(orders), [orders]);
  const isUserSegreteria = useMemo(
    () => isSegreteriaNazionale(profile),
    [profile]
  );

  //I valori di default del form sono lo stato di rimborso e il totale (che viene resettato se si cambia lo stato)
  const DEFAULT_FORM_VALUES = {
    step1: { status: PaymentStatus.REFUNDED_PARTIAL },
    step2: { amount: PaymentStatus.REFUNDED_PARTIAL ? 0 : totalPaid },
  };

  const refundsForm = useForm<RefundStepperForm>({
    resolver: useYupValidationResolver(refundStepperFormValidator),
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const refundStatus = useMemo(
    () => refundsForm.watch('step1.status'),
    [refundsForm.watch('step1.status')]
  );

  const { handleSubmit, reset } = refundsForm;

  const [state, send] = useMachine(refundsStepperMachine, {
    //i servizi della macchina vengono invocati nella macchina stessa (refundsStepperMachine)
    //perché sono azione asincrone e hanno bisogno dell'esposizione del form
    services: {
      [RefundsStepperService.VALIDATE_STEP_1]: async () => {
        //all'azione VALIDATE_STEP_1 triggero lo step1 nel form
        const isValid = await refundsForm.trigger('step1');
        if (!isValid) {
          throw new Error('Step 1 validation failed');
        }
      },
      [RefundsStepperService.VALIDATE_STEP_2]: async () => {
        //all'azione VALIDATE_STEP_2 triggero lo step2 nel form
        const isValid = await refundsForm.trigger('step2');
        if (!isValid) {
          throw new Error('Step 2 validation failed');
        }
      },
      [RefundsStepperService.SUBMIT_FORM]: async () => {
        try {
          //il form va inviato allo stato SUBMIT_FORM della macchina
          await handleSubmit(onSubmit)();
        } catch (error) {
          throw new Error('Error during the submission');
        }
      },
    },
  });

  /**
   * Testo pulsanti:
   * STEP_1: Annulla/Prossimo
   * STEP_2: Indietro/Salva
   */
  const buttonsText = useMemo(() => {
    switch (state.value as RefundsStepperState) {
      case RefundsStepperState.STEP_1:
        return { cancel: 'Annulla', confirm: 'Prossimo' };
      case RefundsStepperState.STEP_2:
        return { cancel: 'Indietro', confirm: 'Salva' };
      default:
        return {};
    }
  }, [state.value]);

  /**
   * alla chiusura e riapertura della modale resetto la macchina e il form del rimborso
   */
  const toggle = () => {
    setIsOpen((v) => !v);
    reset(DEFAULT_FORM_VALUES);
    send(RefundsStepperAction.RESET);
  };

  /**
   * Il pulsante di conferma invoca diverse azioni a seconda dello step
   * al primo step invoco l'azione NEXT
   * al secondo step invoco il SUBMIT
   * @param step
   */
  const handleNext = (step: RefundsStepperState) => {
    switch (step) {
      case RefundsStepperState.STEP_1:
        send(RefundsStepperAction.NEXT);
        break;
      case RefundsStepperState.STEP_2:
        send(RefundsStepperAction.SUBMIT);
        break;
      default:
        break;
    }
  };

  /**
   * Il pulsante di cancellazione invoca diverse azioni a seconda dello step
   * al primo step chiudo la modale
   * al secondo step invoco l'azione BACK della macchina
   * @param step
   */
  const handleBack = (step: RefundsStepperState) => {
    switch (step) {
      case RefundsStepperState.STEP_1:
        toggle();
        break;
      case RefundsStepperState.STEP_2:
        send(RefundsStepperAction.BACK);
        break;
      default:
        break;
    }
  };

  const { mutateAsync, isLoading } = useMutation(
    'refundPayment',
    payments.refund,
    {
      onSuccess: () => {
        toggle();
        onRefundDone();
        toast.success('Operazione conclusa con successo');
      },
      onError: (error: any) => {
        const errorMessage =
          error.response?.data?.error?.message ||
          'Ooops... Qualcosa è andato storto';
        toast.error(errorMessage);

        send(RefundsStepperState.STEP_2);
      },
    }
  );

  const onSubmit = async (data: RefundStepperForm) => {
    const {
      step1: { status },
      step2: { amount, notes, date, method },
    } = data;

    const formattedData: Partial<RefundPaymentRequest> = {
      status,
      notes,
      date,
      method,
    };

    //Solo per i rimborsi parziali specifichiamo la somma da rimborsare
    if (status === PaymentStatus.REFUNDED_PARTIAL) {
      formattedData.amount = amount;
    }

    await mutateAsync({ token, body: formattedData, id: mainOrderId });
  };

  //Al cambio delllo stato (tipologia) del rimborso bisogna settare la somma
  //se totale si rimborsa tutto
  //se parziale inizializzo a 0
  useEffect(() => {
    if (refundStatus === PaymentStatus.REFUNDED_TOTAL) {
      console.log('totalPaid', totalPaid);
      console.log('refundStatus', refundStatus);
      refundsForm.setValue('step2.amount', totalPaid);
    } else {
      refundsForm.setValue('step2.amount', 0);
    }
  }, [refundStatus]);

  // soltanto segreteria può creare i rimborsi
  if (!isUserSegreteria) return <div></div>;

  return (
    <>
      <button
        onClick={() => {
          toggle();
        }}
      >
        <Underline label={'Rimborsa'} />
      </button>
      <ConfirmationModal
        isOpen={isOpen}
        cancelTextButton={buttonsText.cancel}
        textButton={buttonsText.confirm}
        toggle={toggle}
        onConfirm={() => {
          handleNext(state.value as RefundsStepperState);
        }}
        onClose={() => {
          handleBack(state.value as RefundsStepperState);
        }}
        title='Rimborsa pagamento'
        disabled={isLoading}
      >
        <form className=''>
          <div className='py-6 px-6'>
            <FormProvider {...refundsForm}>
              <form className=''>
                {state.value === RefundsStepperState.STEP_1 && (
                  <PaymentRefundStep1 />
                )}
                {state.value === RefundsStepperState.STEP_2 && (
                  <PaymentRefundStep2 />
                )}
                {isLoading && (
                  <Spinner className='flex w-full justify-center' />
                )}
              </form>
            </FormProvider>
          </div>
        </form>
      </ConfirmationModal>
    </>
  );
};

export default PaymentRefundModalCreation;
