import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import {
  Participant,
  ParticipantStatus,
  ParticipantsResponse,
} from '../../interfaces/participants';
import { UseQueryResult } from 'react-query';
import { StrapiResponse } from '../../interfaces/commons';
import { CourseResponse } from '../../interfaces/courses';
import { PaymentStatus } from '../../interfaces/payments';
import { EventResponse } from '../../interfaces/events';

interface RenewParticipationsModalProps {
  partecipantName: string;
  isOpen: boolean;
  toggle: () => void;
  onConfirm: (participants: { id: number; data: Participant }[]) => void;
  selectedParticipants: Partial<
    ParticipantsResponse & {
      multiple?: boolean | undefined;
    }
  >[];
  courseDetailQuery?: UseQueryResult<
    StrapiResponse<CourseResponse> | undefined,
    unknown
  >;
  eventDetailQuery?: UseQueryResult<
    StrapiResponse<EventResponse> | undefined,
    unknown
  >;
  isLoading: boolean;
}

const RenewParticipationsModal: React.FC<RenewParticipationsModalProps> = ({
  partecipantName,
  isOpen,
  toggle,
  onConfirm,
  selectedParticipants,
  courseDetailQuery,
  eventDetailQuery,
  isLoading,
}) => {
  useEffect(() => {
    let hadSubmitted = false;
    if (isLoading) {
      hadSubmitted = true;
    }

    if (hadSubmitted && !isLoading) {
      toggle();
    }
  }, [isLoading]);

  const getCourseEnrolmentStatus = () => {
    const updatedParticipants = selectedParticipants.map((participant) => {
      const isCompleted =
        participant.attributes?.profile?.data.attributes.orders.data.some(
          (order) =>
            order.attributes.course?.data.id ===
              courseDetailQuery?.data?.data.id &&
            [
              PaymentStatus.COMPLETED,
              PaymentStatus.REFUNDED_PARTIAL,
              PaymentStatus.REFUNDED_TOTAL,
            ].includes(
              order.attributes.payment?.data.attributes.status as PaymentStatus
            )
        );

      const status = isCompleted
        ? ParticipantStatus.CONFIRMED
        : ParticipantStatus.PENDING;

      return {
        id: participant.id || 0,
        data: {
          status,
        },
      };
    });

    return onConfirm(updatedParticipants);
  };
  const getEventEnrolmentStatus = () => {
    const updatedParticipants = selectedParticipants.map((participant) => {
      let isCompleted = null;

      if (participant.attributes?.isFreeParticipant) {
        isCompleted = true;
      } else {
        isCompleted =
          participant.attributes?.profile?.data.attributes.orders.data.some(
            (order) =>
              order.attributes.event?.data.id ===
                eventDetailQuery?.data?.data.data.id &&
              order.attributes.payment?.data.attributes.status ===
                PaymentStatus.COMPLETED
          );
      }

      const status = isCompleted
        ? ParticipantStatus.CONFIRMED
        : ParticipantStatus.PENDING;

      return {
        id: participant.id || 0,
        data: {
          status,
        },
      };
    });

    return onConfirm(updatedParticipants);
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={toggle}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Rinnova iscrizione
                </h4>

                <div className='py-10'>
                  <p className='text-gray-700'>
                    Confermi di voler riattivare l’iscrizione di{' '}
                    <i>{partecipantName}</i> ?
                  </p>
                </div>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton
                    small
                    onClick={() => {
                      courseDetailQuery
                        ? getCourseEnrolmentStatus()
                        : getEventEnrolmentStatus();
                    }}
                    isLoading={isLoading}
                  >
                    Conferma
                  </PrimaryButton>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RenewParticipationsModal;
