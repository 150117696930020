import { StrapiData } from '../interfaces/commons';
import { Order, ProductType } from '../interfaces/orders';
import { PaymentStatus } from '../interfaces/payments';
import PaymentRefundModalCreation from './Modals/PaymentRefundModalCreation';
import PaymentRefundModalVisualisation from './Modals/PaymentRefundModalVisualisation';

interface Props {
  orders: StrapiData<Order>[];
  entityId: number;
  entityType: 'event' | 'course';
  onRefundDone: () => void;
}

const RefundsManager: React.FunctionComponent<Props> = (props) => {
  const { orders, entityId, entityType, onRefundDone } = props;

  const productType =
    entityType == 'event' ? ProductType.EVENT : ProductType.COURSE;

  const mainPayment = orders?.find(
    (elem) =>
      elem?.attributes?.productType === productType &&
      elem?.attributes?.[entityType]?.data?.id === entityId
  )?.attributes.payment;

  const showRefundButton =
    mainPayment?.data.attributes.status === PaymentStatus.COMPLETED;

  const isParticipationRefunded = [
    PaymentStatus.REFUNDED_PARTIAL,
    PaymentStatus.REFUNDED_TOTAL,
  ].includes(mainPayment?.data?.attributes?.status as PaymentStatus);

  return (
    <>
      {showRefundButton ? (
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right'>
          <PaymentRefundModalCreation
            orders={orders}
            onRefundDone={onRefundDone}
          />
        </td>
      ) : isParticipationRefunded ? (
        <PaymentRefundModalVisualisation participantOrders={orders} />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default RefundsManager;
