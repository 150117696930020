import qs from 'qs';
import {
  EventParticipant,
  EventParticipantsResponse,
  FreeEventParticipantReq,
  Participant,
  ParticipantDataCreation,
  ParticipantStatus,
} from '../interfaces/participants';
import axios from 'axios';
import { EventParticipation } from '../interfaces/event-participants';
import { StrapiData, StrapiResponseList } from '../interfaces/commons';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

export const find = async ({
  token,
  query,
}: {
  token?: string | null;
  query?: object;
}) => {
  const { data } = await instance.get<
    StrapiResponseList<StrapiData<EventParticipant>>
  >(
    `${REACT_APP_API_URL}/admin/event-participants?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const findOne = async ({
  token,
  query,
  id,
}: {
  token?: string | null;
  query?: object;
  id?: string;
}) => {
  const { data } = await instance.get<{ data: EventParticipation }>(
    `${REACT_APP_API_URL}/event-participants/${id}?${qs.stringify(query, {
      skipNulls: true,
    })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const create = async ({
  body,
  token,
  query,
}: {
  body: ParticipantDataCreation;
  token?: string | null;
  query?: object;
}) => {
  const { data } = await instance.post<{ data: any }>(
    `${REACT_APP_API_URL}/event-participants?${qs.stringify(query)}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const update = async ({
  body,
  token,
  id,
}: {
  body: Partial<ParticipantDataCreation>;
  token?: string | null;
  id: string;
}) => {
  const { data } = await instance.put<{ data: any }>(
    `${REACT_APP_API_URL}/event-participants/${id}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const addFreeParticipant = async ({
  body,
  token,
  query,
}: {
  body: FreeEventParticipantReq;
  token?: string | null;
  query?: object;
}) => {
  const { data } = await instance.post<{ data: any }>(
    `${REACT_APP_API_URL}/event-participants/free?${qs.stringify(query)}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};
export const bulkUpdate = async ({
  body,
  token,
}: {
  body: {
    participantsIds: number[];
    data: { status?: ParticipantStatus; isPresent?: boolean; note?: string };
  };
  token?: string | null;
}) => {
  const { data } = await instance.put<{ data: EventParticipantsResponse }>(
    `${REACT_APP_API_URL}/event-participants/bulk-update`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const bulkUpdateParticipants = async ({
  body,
  token,
}: {
  body: { participants: { id: number; data: Participant }[] };
  token?: string | null;
}) => {
  const { data } = await instance.put<{ data: EventParticipantsResponse[] }>(
    `${REACT_APP_API_URL}/event-participants/bulk-update-participants`,
    { participants: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const downloadCSV = async ({
  token,
  body,
  query,
  fileName,
}: {
  token?: string | null;
  body: Object;
  query?: Object;
  fileName: string;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/event-participant/csv?${qs.stringify(query)}`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

const eventParticipants = {
  find,
  create,
  findOne,
  bulkUpdate,
  bulkUpdateParticipants,
  downloadCSV,
  addFreeParticipant,
  update,
};

export default eventParticipants;
