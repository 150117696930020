import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import eventsAPIs from '../../../api/events';
import Pagination from '../../../components/Pagination';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import { useAuth } from '../../../contexts/Auth';
import EventsTable from '../Tables/eventsTable';
import qs from 'qs';
import { FilterType } from '../../../interfaces/filters';
import { EventStatus } from '../../../interfaces/events';
import FiltersBar from '../../../components/Filters/FiltersBar';
import sectionsAPIs from '../../../api/section';
import { getDateTimeFromDate } from '../../../libs/utils/helpers';
import { isDelegato } from '../../../libs/utils/auth';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { DownloadIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { useState } from 'react';
import moment from 'moment';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const ListaEventi = () => {
  const [searchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const [eventListFilters, seteventListFilters] = useState<Object>();

  const eventListQuery = useQuery(
    ['eventsList', ...debounceSearchParams],
    () => {
      const query = {
        filters: {
          title: { $contains: debounceSearchParams.get('search') },
          section: {
            id: {
              $in: Object.entries(
                qs.parse(debounceSearchParams.get('sections') + '')
              )
                .map(([, value]) => value)
                .map((elem: any) => elem.value),
            },
          },
          startDate: { $contains: debounceSearchParams.getAll('year') },
          $and: [
            {
              startDate: {
                $gte: qs.parse(
                  Object.entries(
                    qs.parse(debounceSearchParams.get('date') + '')
                  ).map(([, value]) => value as any)[0]?.label
                ).from,
                $lte: getDateTimeFromDate(
                  qs.parse(
                    Object.entries(
                      qs.parse(debounceSearchParams.get('date') + '')
                    ).map(([, value]) => value as any)[0]?.label
                  ).to
                ),
              },
            },
            {
              status: {
                $in: Object.entries(
                  qs.parse(debounceSearchParams.get('status') + '')
                )
                  .map(([, value]) => value)
                  .map((elem: any) => elem.value),
              },
            },
          ],
        },
        populate: [
          '*',
          'partecipations.guests',
          'section',
          'balance.price',
          'balance.contributes',
          'balance.wines',
          'balance.food',
          'balance.rooms',
          'balance.sponsorship',
          'balance.contributes',
          'balance.extras',
          'balance.teacherPayments',
          'balance.teacherRefunds',
          'balance.direction',
          'headquarterBalance.cards',
          'headquarterBalance.administrations',
          'headquarterBalance.teachingMaterial',
          'headquarterBalance.shipping',
        ],
        pagination: {
          page: searchParams.get('page'),
          pageSize: searchParams.get('pageSize'),
        },
        sort: 'startDate:desc',
      };

      seteventListFilters(query);

      return eventsAPIs.find({
        token,
        query,
      });
    }
  );

  const { mutate: eventListCSVMutation, isLoading: isDownloadingCSV } =
    useMutation('downloadEventsCSVList', eventsAPIs.downloadEventsCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(eventListQuery.data?.meta?.pagination?.total) >= 30000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 30000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });
  return (
    <>
      <TitlePage title='Tutti gli eventi' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Tutti gli eventi</H1Styled>

        <div className='space-y-5 mt-10'>
          <FiltersBar
            filters={[
              {
                type: FilterType.DATE,
                attribute: 'date',
                label: 'Data',
                key: 'data',
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'sections',
                label: 'Sezione',
                key: 'sectionsListFilters',
                source: (data: any) => {
                  return sectionsAPIs.findOptions({
                    ...data,
                    query: {
                      ...data.query,
                      ...(isDelegato(profile)
                        ? { filters: { id: profile?.section.data?.id } }
                        : {}),
                    },
                  });
                },
                searchForAttributes: ['name'],
              },
              {
                type: FilterType.MULTISELECT,
                attribute: 'status',
                label: 'Stato',
                key: 'courseStatus',
                source: () => ({
                  data: [
                    { id: EventStatus.DRAFT, attributes: { name: 'Bozza' } },
                    {
                      id: EventStatus.PUBLISHED,
                      attributes: { name: 'Pubblicato' },
                    },
                    {
                      id: EventStatus.FINAL_BALANCE_DELEGATION,
                      attributes: { name: 'Consuntivato da sezione' },
                    },
                    {
                      id: EventStatus.FINAL_BALANCE_NATIONAL,
                      attributes: {
                        name: 'Consuntivato da segreteria nazionale',
                      },
                    },
                    {
                      id: EventStatus.CANCELED,
                      attributes: { name: 'Annullato' },
                    },
                  ],
                }),
              },
              {
                type: FilterType.SEARCH_BAR,
                attribute: 'search',
                label: 'Cerca...',
              },
            ]}
          />
          <div className='flex justify-end w-full'>
            <PrimaryButton
              textSmall
              disabled={isDownloadingCSV}
              isLoading={isDownloadingCSV}
              onClick={() => {
                eventListCSVMutation({
                  token,
                  query: eventListFilters,
                  fileName: `Lista eventi ONAV ${moment().format('DD-MM-YY')}`,
                });
              }}
            >
              <span className='text-sm underline flex flex-row'>
                <DownloadIcon className='w-4 h-4 mt-[3px]' /> <p>Scarica CSV</p>
              </span>
            </PrimaryButton>
          </div>

          <EventsTable query={eventListQuery} />
          <Pagination pagination={eventListQuery.data?.meta.pagination} />
        </div>
      </div>
    </>
  );
};

export default ListaEventi;
