import { PaymentStatus } from '../../../interfaces/payments';
import { useFormContext } from 'react-hook-form';
import { RefundStepperForm } from './interfaces';
import { useMemo } from 'react';
import CustomRadioGroup from '../../Radio/CustomRadioGroup';
import { CustomRadioGroupOption } from '../../Radio/interfaces';

const OPTIONS: CustomRadioGroupOption<PaymentStatus>[] = [
  // {
  //   type: PaymentStatus.REFUNDED_TOTAL,
  //   name: 'Totale',
  //   description: 'Il rimborso che verrà effettuato sarà pari al totale pagato',
  // },
  {
    type: PaymentStatus.REFUNDED_PARTIAL,
    name: 'Parziale',
    description:
      'Il rimborso che verrà effettuato sarà pari alla somma inserita',
  },
];

const PaymentRefundStep1: React.FunctionComponent = () => {
  const refundsForm = useFormContext<RefundStepperForm>();

  const refundStatus = useMemo(
    () => refundsForm.watch('step1.status'),
    [refundsForm.watch('step1.status')]
  );

  const onChangeRefundStatus = (value: PaymentStatus) => {
    refundsForm.setValue('step1.status', value);
  };

  return (
    <CustomRadioGroup<PaymentStatus>
      value={refundStatus}
      onChange={onChangeRefundStatus}
      options={OPTIONS}
    />
  );
};

export default PaymentRefundStep1;
