import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import Filters from '../../../components/Filters/Filters';
import Pagination from '../../../components/Pagination';
import TitlePage from '../../../components/TitlePage';
import H1Styled from '../../../components/Typography/H1Styled';
import { useAuth } from '../../../contexts/Auth';
import SectionTable from '../Tables/sectionTable';
import { isDelegato } from '../../../libs/utils/auth';
import { useMemo } from 'react';
import sectionsAPIs from '../../../api/section';

const { REACT_APP_DEBOUNCE_DELAY } = process.env;
const delay = Number(REACT_APP_DEBOUNCE_DELAY);

const ListaSezioni = () => {
  const [searchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();

  const isUserDelegato = useMemo(() => isDelegato(profile), [profile]);
  const [debounceSearchParams] = useDebounce(searchParams, delay);

  const query = useQuery(['sections', ...debounceSearchParams], () => {
    const query: any = {};
    if (isUserDelegato) query.id = profile?.section?.data?.id;
    return sectionsAPIs.find({
      token,
      query: {
        populate: {
          region: {
            fields: ['name'],
          },
          province: {
            fields: ['name'],
          },
          type: {
            fields: ['type'],
          },
        },
        filters: {
          $or: [
            {
              name: { $contains: debounceSearchParams.get('search') },
            },
            {
              province: {
                name: { $contains: debounceSearchParams.get('search') },
              },
            },
            {
              region: {
                name: { $contains: debounceSearchParams.get('search') },
              },
            },
          ],
        },
        pagination: {
          page: debounceSearchParams.get('page'),
          pageSize: debounceSearchParams.get('pageSize'),
        },
        sort: { costCenter: 'asc' },
      },
    });
  });

  return (
    <>
      <TitlePage title='Tutte le sezioni' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Tutte le sezioni</H1Styled>

        <div className='mt-10 space-y-5'>
          <Filters searching hideAppliedFilters />
          <SectionTable query={query} />
          <Pagination pagination={query.data?.meta.pagination} />
        </div>
      </div>
    </>
  );
};

export default ListaSezioni;
