import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { CustomRadioGroupOption } from './interfaces';

interface Props<T> {
  value: T;
  onChange: (value: T) => void;
  options: CustomRadioGroupOption<T>[];
}

const CustomRadioGroup = <T,>(
  props: Props<T>
): React.FunctionComponentElement<Props<T>> => {
  const { value, onChange, options } = props;
  return (
    <RadioGroup value={value} onChange={onChange}>
      <RadioGroup.Label className='sr-only'>Server size</RadioGroup.Label>
      <div className='space-y-2 grid-cols-2 grid grid-flow-col space-x-4'>
        {options.map((_mode) => (
          <RadioGroup.Option
            key={_mode.name}
            value={_mode.type}
            className={({ active, checked }) =>
              `${
                active
                  ? 'bg-primary/5 ring-2 ring-primary/60 ring-offset-2 ring-offset-primary/75'
                  : ''
              }
                  ${checked ? 'border-primary/75 border-2' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
            }
          >
            {({ active, checked }) => (
              <>
                <div className='flex w-full items-center justify-between'>
                  <div className='flex items-center'>
                    <div className='text-sm'>
                      <RadioGroup.Label
                        as='p'
                        className={`font-medium  ${'text-gray-900'}`}
                      >
                        {_mode.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as='span'
                        className={`inline ${'text-gray-500'}`}
                      >
                        <span>{_mode.description}</span>
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {checked && (
                    <div className='shrink-0 text-white'>
                      <CheckIcon className='h-6 w-6 bg-primary rounded-full' />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default CustomRadioGroup;
