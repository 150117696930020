interface Props {
  definition: string;
  value?: string | null;
}

const DefinitionLabel: React.FunctionComponent<Props> = (props) => {
  const { definition, value } = props;
  return (
    <div className='flex flex-row'>
      <p>{definition}</p>:<p className='px-2'>{value || '-'}</p>
    </div>
  );
};

export default DefinitionLabel;
