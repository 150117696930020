import axios from 'axios';
import { Payment, RefundPaymentRequest } from '../interfaces/payments';
import { StrapiResponse } from '../interfaces/commons';
import QueryString from 'qs';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

const update = async ({
  body,
  token,
  id,
}: {
  body: Partial<Payment>;
  token?: string | null;
  id?: number | string;
}) => {
  const { data } = await instance.put<PaymentResponse>(
    `${REACT_APP_API_URL}/payments/${id}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const refund = async ({
  body,
  token,
  id,
}: {
  body: Partial<RefundPaymentRequest>;
  token?: string | null;
  id?: number | string;
}) => {
  await instance.post(`${REACT_APP_API_URL}/payments/${id}/refund`, body, {
    headers: { authorization: `Bearer ${token}` },
  });
};

export const findOne = async ({
  token,
  id,
  query = {},
}: {
  token?: string | null;
  id?: number | string | null;
  query?: object;
}) => {
  const { data } = await instance.get<StrapiResponse<{ attributes: Payment }>>(
    `${REACT_APP_API_URL}/payments/${id}?${QueryString.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const find = async ({
  token,
  query = {},
}: {
  token?: string | null;
  id?: number | string | null;
  query?: object;
}) => {
  const { data } = await instance.get<StrapiResponse<{ attributes: Payment }>>(
    `${REACT_APP_API_URL}/payments?${QueryString.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const payments = {
  update,
  findOne,
  find,
  refund,
};

export default payments;
