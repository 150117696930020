import { useState } from 'react';
import { match } from 'ts-pattern';
import { UserSectionsSelect } from '../../components/FetchSelect/SectionsSelect';
import Datetime from '../../components/Form/Datetime';
import {
  StaticInputLabel,
  StaticInputValue,
} from '../../components/Form/StaticInputPresentational';
import FormLoading from '../../components/Layout/Loading/FormLoading';
import H3Styled from '../../components/Typography/H3Styled';
import classNames from '../../libs/utils/classNames';
import {
  useComparisonStatistics,
  useNextYearStatisticsAvailable,
} from './hooks';
import { useAuth } from '../../contexts/Auth';
import {
  isDelegato,
  isDelegatoSezione,
  isSegreteriaNazionale,
} from '../../libs/utils/auth';
import InputPresentational from '../../components/Form/InputPresentational';
import moment from 'moment';

const DateFormatter = new Intl.DateTimeFormat('it-IT', {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
});

export function ComparisonStatistic() {
  const showNextYear = useNextYearStatisticsAvailable();
  const [{ profile }] = useAuth();
  const [sectionId, setSectionId] = useState<string | undefined>(
    isDelegato(profile) ? profile?.section.data?.id.toString() : 'ALL_SECTIONS'
  );
  const [date, setDate] = useState<string | undefined>(
    new Date().toISOString()
  );
  const year = new Date().getFullYear();
  const previousYear = year - 1;
  const nextYear = showNextYear ? year + 1 : null;
  const statisticsQuery = useComparisonStatistics({
    year,
    sectionId: sectionId === 'ALL_SECTIONS' ? undefined : sectionId,
    date,
  });

  return (
    <div className='bg-neutral-50 py-8 px-4 rounded-lg space-y-8'>
      <div className='space-y-4'>
        <div className='flex items-center justify-between'>
          <H3Styled>Statistiche comparative</H3Styled>
        </div>
        <div className='flex flex-row items-center flex-wrap gap-2 sm:flex-nowrap sm:max-w-lg'>
          <InputPresentational
            register={() => ({
              onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                setDate(event.target.value),
            })}
            defaultValue={moment(date).format('YYYY-MM-DD')}
            id='end-date'
            label='Data di riferimento'
            name='end-date'
            type='date'
          />
          {!isDelegatoSezione(profile) && (
            <UserSectionsSelect
              options={
                isSegreteriaNazionale(profile)
                  ? [
                      {
                        label: 'Tutte le sezioni',
                        value: 'ALL_SECTIONS',
                      },
                    ]
                  : undefined
              }
              defaultValue={sectionId}
              onChangeValue={(value) => setSectionId(value?.value.toString())}
            />
          )}
        </div>
      </div>

      {match(statisticsQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={12} />
        ))
        .with({ status: 'error' }, () => (
          <div>Errore nel recupero delle statistiche comparative...</div>
        ))
        .with({ status: 'success' }, ({ data: statisticsData }) => {
          return (
            <>
              <div className='space-y-4 overflow-x-auto -mx-4'>
                <div
                  className={classNames(
                    'grid   gap-4   place-items-start [&>*]:px-4',
                    showNextYear && nextYear
                      ? 'grid-cols-[0.5fr_1fr_1fr_1fr_.5fr_0.5fr]'
                      : 'grid-cols-[0.5fr_1fr_1fr_.5fr]'
                  )}
                >
                  <StaticInputLabel>Tipologia</StaticInputLabel>

                  {showNextYear && nextYear && (
                    <StaticInputLabel>
                      <span className='hidden md:inline'>
                        Tessere {nextYear} acquisite al{' '}
                        {DateFormatter.format(moment(date).toDate())}
                      </span>
                      <span className='md:hidden'>{nextYear}</span>
                    </StaticInputLabel>
                  )}

                  <StaticInputLabel>
                    <span className='hidden md:inline'>
                      Tessere {year} acquisite al{' '}
                      {DateFormatter.format(
                        moment(date)
                          .subtract(showNextYear ? 1 : 0, 'year')
                          .toDate()
                      )}
                    </span>
                    <span className='md:hidden'>{year}</span>
                  </StaticInputLabel>

                  <StaticInputLabel>
                    <span className='hidden md:inline'>
                      Tessere {previousYear} acquisite al{' '}
                      {DateFormatter.format(
                        moment(date)
                          .subtract(showNextYear ? 2 : 1, 'year')
                          .toDate()
                      )}
                    </span>
                    <span className='md:hidden'>{previousYear}</span>
                  </StaticInputLabel>

                  {showNextYear && nextYear && (
                    <StaticInputLabel>
                      {nextYear}/{year}
                    </StaticInputLabel>
                  )}

                  <StaticInputLabel>
                    {showNextYear ? nextYear : year}/{previousYear}
                  </StaticInputLabel>

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Sottoscrizioni totali</StaticInputLabel>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {statisticsData.subscriptions.total.nextYear}
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {statisticsData.subscriptions.total.currentYear}
                  </StaticInputValue>
                  <StaticInputValue>
                    {statisticsData.subscriptions.total.prevYear}
                  </StaticInputValue>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {
                        statisticsData.subscriptions.total.nextOnCurrentChange
                          ?.percentage
                      }
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {
                      statisticsData.subscriptions.total
                        .nextOrCurrentOnPrevChange?.percentage
                    }
                  </StaticInputValue>

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Rinnovi</StaticInputLabel>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {statisticsData.subscriptions.renewals.nextYear}
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {statisticsData.subscriptions.renewals.currentYear}
                  </StaticInputValue>
                  <StaticInputValue>
                    {statisticsData.subscriptions.renewals.prevYear}
                  </StaticInputValue>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {
                        statisticsData.subscriptions.renewals
                          .nextOnCurrentChange?.percentage
                      }
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {
                      statisticsData.subscriptions.renewals
                        .nextOrCurrentOnPrevChange?.percentage
                    }
                  </StaticInputValue>

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Nuove sottoscrizioni</StaticInputLabel>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {statisticsData.subscriptions.newSubs.nextYear}
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {statisticsData.subscriptions.newSubs.currentYear}
                  </StaticInputValue>
                  <StaticInputValue>
                    {statisticsData.subscriptions.newSubs.prevYear}
                  </StaticInputValue>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {
                        statisticsData.subscriptions.newSubs.nextOnCurrentChange
                          ?.percentage
                      }
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {
                      statisticsData.subscriptions.newSubs
                        .nextOrCurrentOnPrevChange?.percentage
                    }
                  </StaticInputValue>

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Riattivazioni</StaticInputLabel>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {statisticsData.subscriptions.reenrollments.nextYear}
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {statisticsData.subscriptions.reenrollments.currentYear}
                  </StaticInputValue>
                  <StaticInputValue>
                    {statisticsData.subscriptions.reenrollments.prevYear}
                  </StaticInputValue>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {
                        statisticsData.subscriptions.reenrollments
                          .nextOnCurrentChange?.percentage
                      }
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {
                      statisticsData.subscriptions.reenrollments
                        .nextOrCurrentOnPrevChange?.percentage
                    }
                  </StaticInputValue>

                  <div className='col-span-full w-full h-px  bg-gray-200'></div>

                  <StaticInputLabel>Junior</StaticInputLabel>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {statisticsData.subscriptions.juniors.nextYear}
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {statisticsData.subscriptions.juniors.currentYear}
                  </StaticInputValue>
                  <StaticInputValue>
                    {statisticsData.subscriptions.juniors.prevYear}
                  </StaticInputValue>
                  {showNextYear && nextYear && (
                    <StaticInputValue>
                      {
                        statisticsData.subscriptions.juniors.nextOnCurrentChange
                          ?.percentage
                      }
                    </StaticInputValue>
                  )}
                  <StaticInputValue>
                    {
                      statisticsData.subscriptions.juniors
                        .nextOrCurrentOnPrevChange?.percentage
                    }
                  </StaticInputValue>
                </div>
              </div>
            </>
          );
        })
        .exhaustive()}
    </div>
  );
}
