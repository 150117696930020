import moment from 'moment';
import { StrapiData } from '../../../interfaces/commons';
import { PaymentStatusLabel, Refund } from '../../../interfaces/payments';
import { formatCurrency } from '../../../libs/utils/helpers';
import { mapPaymentMethod } from '../../../pages/Tesoreria/utils';
import CustomUnorderedList from '../../Lists/CustomUnorderedList';
import DefinitionLabel from '../../Labels/DefinitionLabel';
import { useMemo } from 'react';

interface Props {
  refund: StrapiData<Refund>;
  title: string;
}

const RefundBox: React.FunctionComponent<Props> = (props) => {
  const { refund, title } = props;
  const { amount, date, method, status, notes } = refund.attributes;

  const refundMethod = useMemo(() => mapPaymentMethod(method), []);
  const formattedDate = useMemo(() => moment(date).format('DD/MM/YYYY'), []);
  const formattedAmount = useMemo(() => formatCurrency(amount), []);

  return (
    <div className='text-gray-600'>
      <h4 className='text-lg font-medium'>{title}</h4>

      <CustomUnorderedList>
        <DefinitionLabel
          definition='Tipologia rimborso'
          value={PaymentStatusLabel[status]}
        />
        <DefinitionLabel
          definition='Somma rimborsata'
          value={formattedAmount}
        />
        <DefinitionLabel definition='Data' value={formattedDate} />
        <DefinitionLabel definition='Metodo rimborso' value={refundMethod} />
        <DefinitionLabel definition='Note rimborso' value={notes} />
      </CustomUnorderedList>
    </div>
  );
};

export default RefundBox;
