import axios from 'axios';
import qs from 'qs';

import { ParticipantsResponse } from '../interfaces/participants';
import { ReceiptRequest, ReceiptResponse } from '../interfaces/receipts';
import { StrapiResponse, StrapiResponseList } from '../interfaces/commons';
import moment from 'moment';
const instance = axios.create();

const { REACT_APP_API_URL } = process.env;

export const findOne = async ({
  id,
  token,
  query,
}: {
  id: string;
  token?: string | null;
  query?: object;
}) => {
  const queryParams = qs.stringify({ populate: '*', ...query });

  const { data } = await instance.get<StrapiResponse<ReceiptResponse>>(
    `${REACT_APP_API_URL}/receipts/${id}?${queryParams}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const find = async ({
  token,
  query,
}: {
  token?: string | null;
  query?: Object;
}) => {
  const { data: receipts } = await instance.get<
    StrapiResponseList<ReceiptResponse>
  >(`${REACT_APP_API_URL}/receipts?${qs.stringify(query)}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return receipts;
};

export const create = async ({
  token,
  body,
}: {
  token?: string | null;
  body: ReceiptRequest;
}) => {
  const { data } = await instance.post<StrapiResponse<ReceiptResponse>>(
    `${REACT_APP_API_URL}/receipts`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const bulkCreate = async ({
  token,
  body,
}: {
  token?: string | null;
  body: {
    orderIds: number[];
  };
}) => {
  const { data } = await instance.post<any>(
    `${REACT_APP_API_URL}/receipts/bulk-create`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const createFree = async ({
  token,
  query,
  body,
}: {
  token?: string | null;
  query?: object;
  body: ReceiptRequest;
}) => {
  const { data } = await instance.post<{ data: ParticipantsResponse }>(
    `${REACT_APP_API_URL}/receipts/free?${qs.stringify(query)}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const createCode = async ({
  token,
  query,
  section,
}: {
  token?: string | null;
  query?: object;
  section?: string | number | null;
}) => {
  const { data } = await instance.post<{ code: string }>(
    `${REACT_APP_API_URL}/receipts/generate-code?${qs.stringify(query)}`,
    { section },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const update = async ({
  token,
  body,
  receiptId,
}: {
  token?: string | null;
  body: ReceiptRequest;
  receiptId: number;
}) => {
  const { data } = await instance.put<{ data: ParticipantsResponse }>(
    `${REACT_APP_API_URL}/receipts/${receiptId}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

const downloadReceipt = async ({
  token,
  body,
  fileName,
}: {
  token?: string | null;
  body: {
    receipts: (number | undefined)[];
  };
  fileName: string;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/receipts/print`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.pdf`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const downloadCSV = async ({
  token,
  body,
  query,
  fileName,
}: {
  token?: string | null;
  body: Object;
  query?: Object;
  fileName: string;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/receipts/csv?${qs.stringify(query)}`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const downloadPayPalReconciliation = async ({
  token,
  body,
  fileName,
  query,
}: {
  token?: string | null;
  body: Object;
  fileName: string;
  query?: Object;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/paypal/csv?${qs.stringify(query)}`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const downloadAxervelReconciliation = async ({
  token,
  fileName,
  query,
}: {
  token?: string | null;
  fileName: string;
  query?: Object;
}) => {
  const { data } = await instance.get(
    `${REACT_APP_API_URL}/axerve/csv?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};
const receipts = {
  findOne,
  create,
  bulkCreate,
  createFree,
  find,
  update,
  downloadReceipt,
  createCode,
  downloadCSV,
  downloadPayPalReconciliation,
  downloadAxervelReconciliation,
};

export default receipts;
