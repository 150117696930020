import { useNavigate } from 'react-router-dom';
import React from 'react';
import BadgeBoolean from '../../../components/BadgeBoolean';
import { UseQueryResult } from 'react-query';
import { Profile, ProfileListResponse } from '../../../interfaces/profile';
import { match } from 'ts-pattern';
import TableLoading from '../../../components/Layout/Loading/TableLoading';
import { PencilIcon } from '@heroicons/react/outline';
import IconLink from '../../../components/TextLink/IconLink';
import { BadgeStatus } from '../../../interfaces/badge';
import TableHeader from '../../../components/Table/TableHeader';
import Checkbox from '../../../components/Form/Checkbox';

interface UsersTableProps {
  query: UseQueryResult<ProfileListResponse>;
}
const UtentiTable: React.FC<UsersTableProps> = ({ query }) => {
  const tableElements = [
    { key: null, label: 'Tessera' },
    {
      key: 'surname',
      label: 'Cognome',
      class: 'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-0',
    },
    {
      key: 'name',
      label: 'Nome',
      class: 'px-3 py-4 text-sm text-gray-500 w-[120px] sticky left-[120px]',
    },
    { key: null, label: 'Email' },
    { key: null, label: 'Titolo' },
    //{ key: null, label: 'Carica' },
    { key: null, label: 'Tessera attiva' },
    { key: null, label: 'Docente' },
    { key: null, label: 'Volontario' },
    { key: null, label: 'Sezione' },
    { key: null, label: 'Delegazione regionale di appartenenza' },
    { key: null, label: 'Data registrazione' },
    { key: null, label: 'Rivista digitale' },
    { key: null, label: '' },
  ];

  const navigate = useNavigate();

  return (
    <div className='-my-2 overflow-x-auto'>
      <div className='inline-block min-w-full py-2 align-middle'>
        <div className='overflow-hidden ring-1 border ring-black ring-opacity-5 '>
          {match(query)
            .with({ status: 'success' }, (query) => {
              if (query.data) {
                return (
                  <table className='min-w-full divide-y divide-gray-300'>
                    <TableHeader columnsNames={tableElements} />
                    <tbody className='divide-y divide-gray-200 bg-white'>
                      {query.data.data.map((user) => (
                        <tr
                          key={user.id}
                          onClick={() => {
                            navigate('modifica/' + user.id);
                          }}
                          className='hover:cursor-pointer hover:bg-neutral-100'
                        >
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-600'>
                            {
                              user.attributes.badge?.data?.attributes
                                .badgeNumber
                            }
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {user.attributes.surname}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {user.attributes.name}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {user.attributes.user?.data?.attributes.email}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {
                              user.attributes.profileCategory?.data?.attributes
                                .title
                            }
                          </td>
                          {/* <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {
                              user.attributes.onavPosition?.data?.attributes
                                .name
                            }
                          </td> */}
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            <BadgeBoolean
                              active={
                                user.attributes.badgeStatus ===
                                BadgeStatus.VALID
                              }
                            />
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            <BadgeBoolean active={user.attributes.isTeacher} />
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            <BadgeBoolean
                              active={user.attributes.isVolunteer}
                            />
                          </td>

                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {user.attributes.section?.data?.attributes.name}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {user.attributes.delegation?.name}
                          </td>

                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            {new Date(
                              user.attributes.createdAt
                            ).toLocaleDateString('it-IT')}
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                            <BadgeBoolean
                              active={user.attributes.printDigitalMagazine}
                            />
                          </td>
                          <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500 hover:text-orange-500'>
                            <IconLink orangeTxt href={`modifica/${user.id}`}>
                              <PencilIcon className='w-6 h-6' />
                            </IconLink>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                );
              } else {
                return (
                  <div className='text-gray-500 my-20 text-center'>
                    Non hai i permessi
                  </div>
                );
              }
            })
            .with({ status: 'idle' }, { status: 'loading' }, () => {
              return <p>Caricamento Utenti...</p>;
            })
            .with({ status: 'error' }, () => {
              return <p>Errore caricamento Utenti...</p>;
            })
            .exhaustive()}
        </div>
      </div>
    </div>
  );
};

export default UtentiTable;
