import React from 'react';
import InputPresentational from './Form/InputPresentational';
import SimpleCheckboxPresentational from './Form/SimpleCheckboxPresentational';
import RegionsSelect from './FetchSelect/RegionsSelect';
import NationsSelect from './FetchSelect/NationsSelect';
import ProvincesSelect from './FetchSelect/ProvincesSelect';
import SectionTypeSelect from './FetchSelect/SectionTypeSelect';
import { Section, SectionRequest } from '../interfaces/section';
import { useFormContext } from 'react-hook-form';

const FormModificaDatiPrincipaliSezione = ({
  sectionData,
}: {
  sectionData: Section;
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<SectionRequest>();

  return (
    <div className='mt-12' key={sectionData.id}>
      <div className='grid grid-cols-4 gap-4 pt-6 pb-8 mt-6'>
        <div className='col-span-4 md:col-span-2'>
          <InputPresentational
            id='sectionName'
            label='Nome sezione'
            name='name'
            type='text'
            register={register}
            required
            error={{ ...errors.name, type: 'required' }}
          />
        </div>
        <div className='col-span-2 md:col-span-1'>
          <SectionTypeSelect control={control} />
        </div>
        <div className='col-span-2 mt-8 md:col-span-1'>
          <SimpleCheckboxPresentational
            id='enabled'
            label='Abilita'
            name='enabled'
            register={register}
          />
        </div>
        <div className='col-span-4 md:col-span-1'>
          <InputPresentational
            id='city'
            label='Città'
            name='city'
            type='text'
            register={register}
            required
            error={{ ...errors.city, type: 'required' }}
          />
        </div>
        <div className='col-span-4 md:col-span-1'>
          <InputPresentational
            id='cap'
            label='CAP'
            name='cap'
            type='text'
            register={register}
            required
            error={{ ...errors.cap, type: 'required' }}
          />
        </div>
        <div className='col-span-4 md:col-span-1'>
          <NationsSelect
            control={control}
            required
            error={{ ...errors.nation, type: 'required' }}
          />
        </div>

        <div className='col-span-4 md:col-span-1'></div>

        <div className='col-span-4 md:col-span-1'>
          <RegionsSelect
            control={control}
            required
            error={{ ...errors.region, type: 'required' }}
          />
        </div>

        <div className='col-span-4 md:col-span-1'>
          <ProvincesSelect
            control={control}
            required
            error={{ ...errors.province, type: 'required' }}
          />
        </div>

        <div className='col-span-4 md:col-span-2'></div>
        <div className='col-span-4 md:col-span-1'>
          <InputPresentational
            id='delegationPhone'
            label='Telefono delegato di sezione'
            name='phoneDelegate'
            type='text'
            register={register}
          />
        </div>
        <div className='col-span-4 md:col-span-1'>
          <InputPresentational
            id='tesoreriePhone'
            label='Telefono tesorerie di sezione'
            name='phoneTreasurer'
            type='text'
            register={register}
          />
        </div>
        <div className='col-span-4 md:col-span-2'></div>
        <div className='col-span-4 md:col-span-1'>
          <InputPresentational
            id='sectionEmail'
            label='Email di sezione'
            name='email'
            type='text'
            register={register}
          />
        </div>
        <div className='col-span-4 md:col-span-1'>
          <InputPresentational
            id='tesorerieEmail'
            label='Email tesorerie di sezione'
            name='emailTreasurer'
            type='text'
            register={register}
          />
        </div>
        <div className='col-span-4 md:col-span-2'></div>
        <div className='col-span-4 md:col-span-2'>
          <InputPresentational
            id='iban'
            label='IBAN di sezione'
            name='iban'
            type='text'
            register={register}
            required
            error={{ ...errors.iban, type: 'required' }}
          />
        </div>
        <div className='col-span-4 md:col-span-1'>
          <InputPresentational
            id='costCenter'
            label='Centro di costo'
            name='costCenter'
            type='number'
            register={register}
            required
            error={{ ...errors.costCenter, type: 'required' }}
          />
        </div>
      </div>
    </div>
  );
};

export default FormModificaDatiPrincipaliSezione;
