import TitlePage from '../../components/TitlePage';
import H1Styled from '../../components/Typography/H1Styled';
import UtentiTable from './Tables/UtentiTable';
import { useMutation, useQuery } from 'react-query';
import { downloadProfilesCSV, getProfiles } from '../../api/profile';
import { useSearchParams } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { useAuth } from '../../contexts/Auth';
import CreateNewAudienceModal from '../../components/Modals/CreateNewAudienceModal';
import { useDebounce } from 'use-debounce';
import { FilterType } from '../../interfaces/filters';
import FiltersBar from '../../components/Filters/FiltersBar';
import sectionsAPIs from '../../api/section';
import { profileCategories } from '../../api/profileCategories';
import eventsAPIs from '../../api/events';
import coursesAPIs from '../../api/courses';
import { isDelegato } from '../../libs/utils/auth';
import { useMemo, useState } from 'react';
import moment from 'moment';
import { DownloadIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import _ from 'lodash';
import { Profile, ProfileBadgeStatus } from '../../interfaces/profile';
import { getGroupFilter } from '../../libs/utils/helpers';
import { getQuerySearchFilters } from '../../libs/utils/profile';
import { buildPrintDigitalMagazineFilters } from '../../libs/utils/users';
import QueryString from 'qs';

const ListaUtenti = () => {
  const [searchParams] = useSearchParams();
  const [{ token, profile }] = useAuth();
  const { REACT_APP_DEBOUNCE_DELAY } = process.env;
  const delay = Number(REACT_APP_DEBOUNCE_DELAY);
  const [debounceSearchParams] = useDebounce(searchParams, delay);
  const [usersListFilters, setUsersListFilters] = useState<Object>();

  const DIGITAL_MAGAZINE_OPTIONS = [
    { attributes: { name: 'Rivista solo digitale' }, id: 'true' },
    { attributes: { name: 'Rivista cartacea' }, id: 'false' },
  ];
  const CARD_OPTIONS = [
    {
      attributes: { name: 'Tessera attiva anno in corso' },
      id: ProfileBadgeStatus.ACTIVE,
    },
    {
      attributes: { name: 'Tessera NON attiva in corso' },
      id: ProfileBadgeStatus.INACTIVE,
    },
    {
      attributes: { name: 'Tessera rinnovata anno nuovo' },
      id: ProfileBadgeStatus.RENEWED,
    },
    {
      attributes: { name: 'Reiscrizione anno nuovo' },
      id: ProfileBadgeStatus.REISCRIZIONE_NEXT_YEAR,
    },
    {
      attributes: { name: 'Prima associazione anno nuovo' },
      id: ProfileBadgeStatus.NEW_SUBSCRIBED_NEXT_YEAR,
    },
    {
      attributes: { name: 'Tessera attiva e non ancora rinnovata' },
      id: ProfileBadgeStatus.NOT_RENEWED,
    },
    {
      attributes: { name: 'Tessera attiva anno precedente' },
      id: ProfileBadgeStatus.ACTIVE_PAST_YEAR,
    },
    {
      attributes: { name: 'NON attiva anno in corso e attiva anno precedente' },
      id: ProfileBadgeStatus.CURRENT_INACTIVE_AND_LAST_YEAR_ACTIVE,
    },
    {
      attributes: { name: 'Senza tessera' },
      id: ProfileBadgeStatus.NOT_EXISTING,
    },
  ];

  const usersListQuery = useQuery(
    ['usersList', ...debounceSearchParams],
    () => {
      const searchInput = debounceSearchParams.get('search');
      let searchFilters: any[] = [];

      if (!!searchInput) {
        if (searchInput.trim().split(' ').length === 1) {
          if (!Number.isNaN(parseInt(searchInput))) {
            searchFilters = searchFilters.concat({
              badge: { badgeNumber: { $contains: searchInput } },
            });
          } else {
            searchFilters = getQuerySearchFilters(searchInput);
            searchFilters = searchFilters.concat({
              user: { email: { $contains: searchInput } },
            });
          }
        } else {
          searchFilters = getQuerySearchFilters(searchInput);
        }
      }
      const sortParams = JSON.parse(searchParams.get('sort') ?? '{}');

      const query = {
        populate: [
          'orders.payment',
          'badge',
          'section.region',
          'secondarySections',
          'user',
          'profileCategory',
          'orders.cardHistory',
        ],
        filters: {
          $or: [...searchFilters],
          courses: getGroupFilter({ filterName: 'courses', searchParams }),
          events: getGroupFilter({ filterName: 'events', searchParams }),
          section: getGroupFilter({ filterName: 'sections', searchParams }),
          badgeStatuses: getGroupFilter({
            filterName: 'badgeStatuses',
            searchParams,
          }),
          secondarySections: getGroupFilter({
            filterName: 'secondarySections',
            searchParams,
          }),
          ...buildPrintDigitalMagazineFilters(searchParams),
          profileCategory: getGroupFilter({
            filterName: 'profileCategories',
            searchParams,
          })
            ? {
                id: {
                  $in: getGroupFilter({
                    filterName: 'profileCategories',
                    searchParams,
                  }),
                },
              }
            : undefined,
          onavPosition: getGroupFilter({
            filterName: 'onavPositions',
            searchParams,
          })
            ? {
                id: {
                  $in: getGroupFilter({
                    filterName: 'onavPositions',
                    searchParams,
                  }),
                },
              }
            : undefined,
          isTeacher: searchParams.get('isTeacher')
            ? { $eq: searchParams.get('isTeacher') }
            : undefined,
          isVolunteer: searchParams.get('isVolunteer')
            ? { $eq: searchParams.get('isVolunteer') }
            : undefined,
        },
        pagination: {
          page: searchParams.get('page'),
          pageSize: searchParams.get('pageSize'),
        },
        sort:
          JSON.stringify(sortParams) === '{}'
            ? { updatedAt: 'desc' }
            : sortParams,
      };

      setUsersListFilters(query);

      return getProfiles({
        token,
        query,
      });
    }
  );

  const filterBar = useMemo(() => {
    const sectionFilters = {
      type: FilterType.MULTISELECT,
      attribute: 'sections',
      label: 'Sezioni',
      searchForAttributes: ['name'],
      key: 'sectionsListFilters',
      source: (data: any) =>
        sectionsAPIs.findOptions({
          ...data,
          query: { ...data.query },
        }),
    };

    const secondarySectionsFilters = {
      type: FilterType.MULTISELECT,
      attribute: 'secondarySections',
      label: 'Sezioni seguite',
      searchForAttributes: ['name'],
      key: 'secondarySectionsListFilters',
      source: (data: any) =>
        sectionsAPIs.findOptions({
          ...data,
          query: { ...data.query },
        }),
    };

    const filters = [
      // {
      //   type: FilterType.MULTISELECT,
      //   attribute: 'events',
      //   label: 'Eventi',
      //   key: 'eventsListFilter',
      //   searchForAttributes: ['title'],
      //   source: eventsAPIs.filtersFind,
      // },
      // {
      //   type: FilterType.MULTISELECT,
      //   attribute: 'courses',
      //   label: 'Corsi',
      //   key: 'coursesListFilter',
      //   source: coursesAPIs.filtersFind,
      //   searchForAttributes: ['title'],
      // },
      {
        type: FilterType.MULTISELECT,
        attribute: 'profileCategories',
        label: 'Titolo',
        key: 'profileCategoriesFilter',
        searchForAttributes: ['title'],
        source: profileCategories,
      },
      {
        type: FilterType.MULTISELECT,
        attribute: 'printDigitalMagazine',
        label: 'Rivista digitale',
        key: 'printDigitalMagazineFilter',
        source: () => ({
          data: DIGITAL_MAGAZINE_OPTIONS,
        }),
        isSearchable: false,
      },
      {
        type: FilterType.MULTISELECT,
        attribute: 'badgeStatuses',
        label: 'Tessera',
        key: 'badgeStatuses',
        source: () => ({
          data: CARD_OPTIONS,
        }),
        isSearchable: false,
        isMutualEsclusion: true,
      },
      {
        type: FilterType.CHECKBOX,
        attribute: 'isVolunteer',
        label: 'Volontario',
      },
      {
        type: FilterType.CHECKBOX,
        attribute: 'isTeacher',
        label: 'Docente',
      },
      {
        type: FilterType.SEARCH_BAR,
        attribute: 'search',
        label: 'Cerca...',
      },
    ];

    if (isDelegato(profile)) {
      return [sectionFilters, ...filters];
    }

    return [sectionFilters, secondarySectionsFilters, ...filters];
  }, [profile]);

  const { mutate: usersListCSVMutation, isLoading: isDownloadingCSV } =
    useMutation('downloadUsersCSVList', downloadProfilesCSV, {
      onError: () => {
        toast.error('Ooops... Qualcosa è andato storto.');
      },
      onSuccess: () => {
        if (Number(usersListQuery.data?.meta?.pagination?.total) >= 30000)
          toast.warning(
            "Documento CSV scaricato con successo.\nL'export csv è stato limitato a 30000 elementi."
          );
        else toast.success('Documento CSV scaricato con successo');
      },
    });

  return (
    <>
      <TitlePage title='Utenti' />

      <div className='col-span-12 lg:col-span-9 xl:col-span-10'>
        <H1Styled>Utenti</H1Styled>

        <div className='space-y-5'>
          <FiltersBar filters={filterBar} />
          <div className='flex justify-end w-full'>
            <CreateNewAudienceModal
              activeFilters={_.omit(usersListFilters, 'pagination')}
              buttonType='text'
              buttonTxt='Salva Audience'
            />
            {/* <UnderlineButton
              children={'Salva audience'}
              onClick={() => {
                createAudienceMutation();
              }}
            /> */}
            <PrimaryButton
              textSmall
              disabled={isDownloadingCSV}
              isLoading={isDownloadingCSV}
              onClick={() => {
                usersListCSVMutation({
                  token,
                  body: {},
                  query: usersListFilters,
                  fileName: `Lista utenti ONAV ${moment().format('DD-MM-YY')}`,
                });
              }}
            >
              <span className='text-sm underline flex flex-row'>
                <DownloadIcon className='w-4 h-4 mt-[3px]' /> <p>Scarica CSV</p>
              </span>
            </PrimaryButton>
          </div>
          <UtentiTable query={usersListQuery} />
          <Pagination
            pagination={usersListQuery.data?.meta.pagination}
            defaultPageSize={20}
          />
        </div>
      </div>
    </>
  );
};

export default ListaUtenti;
