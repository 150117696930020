import * as Yup from 'yup';
import { ValidationErrorMessages } from './errors';

export const sectionValidator = Yup.object().shape({
  name: Yup.string().required(ValidationErrorMessages.REQUIRED),
  type: Yup.string().required(ValidationErrorMessages.REQUIRED),
  city: Yup.string().required(ValidationErrorMessages.REQUIRED),
  cap: Yup.string().required(ValidationErrorMessages.REQUIRED),
  nation: Yup.string().required(ValidationErrorMessages.REQUIRED),
  region: Yup.string().required(ValidationErrorMessages.REQUIRED),
  province: Yup.string().required(ValidationErrorMessages.REQUIRED),
  iban: Yup.string()
    .min(15, 'Il campo IBAN deve essere composto almeno da 15 caratteri!')
    .required(ValidationErrorMessages.REQUIRED),
  costCenter: Yup.number().required(ValidationErrorMessages.REQUIRED),
});
