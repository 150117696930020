import TitlePage from '../../../components/TitlePage';
import PrimaryLinkFull from '../../../components/TextLink/PrimaryLinkFull';
import ReceiptForm from '../../../components/ReceiptForm';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import { useForm } from 'react-hook-form';
import {
  ReceiptEntry,
  ReceiptRequest,
  ReceiptType,
} from '../../../interfaces/receipts';
import useYupValidationResolver from '../../../libs/YupValidationResolver';
import { useMutation, useQuery } from 'react-query';
import { useAuth } from '../../../contexts/Auth';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import {
  formatReceiptType,
  getReceiptSection,
  sanitizeCurrencyValue,
} from '../../../libs/utils/helpers';
import receipts from '../../../api/receipts';
import { toast } from 'react-toastify';
import { ProductType } from '../../../interfaces/orders';
import { useLocation, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import courseParticipants from '../../../api/courseParticipants';
import QueryString from 'qs';
import { isDelegato } from '../../../libs/utils/auth';
import { receiptsValidator } from '../../../validators/receipts';
import eventParticipants from '../../../api/eventParticipants';
import { receiptToBackend } from '../../../libs/utils/receipt';
import sectionsAPIs from '../../../api/section';

export default function CreaRicevutaCorso() {
  const [{ token, profile }] = useAuth();
  const { participantId, id: courseId, eventId } = useParams();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [entries, setEntries] = useState<ReceiptEntry[]>([]);
  const [orders, setOrders] = useState([]);
  const [payments, setPayments] = useState([]);

  //Codice poco elegante, ma qs codifica i dati con la &
  useEffect(() => {
    setEntries(
      Object.entries(QueryString.parse(search?.split('entries=')[1]))?.map(
        ([, value]) => value
      ) as any
    );
    setPayments(
      Object.entries(
        QueryString.parse(search?.split('payments=')[1]?.split('entries=')[0])
      )?.map(([, value]) => value) as any
    );
    setOrders(
      Object.entries(
        QueryString.parse(search?.split('orders=')[1]?.split('payments=')[0])
      )?.map(([, value]) => value) as any
    );
  }, [search]);

  const { data: nationalSectionData, isFetched: hasGotNationalSection } =
    useQuery(
      'getNationalSection',
      () => sectionsAPIs.find({ token, query: { filters: { costCenter: 1 } } }),
      {}
    );

  const { isSuccess: isCoursePartecipantSuccess } = useQuery(
    ['findCourseParticipant', participantId],
    () => {
      if (
        participantId &&
        queryParams.get('productType') === ProductType.COURSE
      )
        return courseParticipants.findOne({
          token,
          id: participantId + '',
          query: {
            populate: [
              'profile.badge',
              'course.section',
              'profile.orders.payment',
              'profile.orders.course.section',
            ],
          },
        });
      return null;
    },
    {
      onSuccess: (_participant) => {
        if (_participant?.data) {
          const payment =
            _participant?.data?.attributes?.profile?.data?.attributes?.orders?.data?.find(
              (_order) =>
                _order.attributes.course?.data?.id === Number(courseId)
            );
          receiptForm.reset({
            entries,
            isCourse: true,
            date: moment().format('YYYY-MM-DD'),
            section: getReceiptSection(
              _participant.data,
              nationalSectionData?.data[0]?.id
            ),
            badgeNumber:
              _participant?.data?.attributes?.profile?.data?.attributes?.badge
                ?.data?.attributes?.badgeNumber,
            type: `${formatReceiptType(ReceiptType.COURSE)} ${
              _participant?.data?.attributes?.course?.data?.attributes?.title
            }`,
            fiscalCode:
              _participant?.data?.attributes?.profile?.data?.attributes
                ?.fiscalCode,
            paymentMethod: payment?.attributes.payment?.data.attributes.method,
            payments,
            orders,
            profile: {
              value: _participant?.data?.attributes?.profile?.data?.id,
              label: `${_participant?.data?.attributes?.profile?.data?.attributes?.surname}`,
            },
            description: `Pagamento corso - ${_participant?.data?.attributes?.course?.data.attributes?.title}`,
          });
        }
      },
      enabled:
        hasGotNationalSection &&
        Boolean(
          participantId && queryParams.get('productType') === ProductType.COURSE
        ),
    }
  );

  const { isSuccess: isEventPartecipantSuccess } = useQuery(
    ['findEventParticipant', participantId],
    () => {
      if (participantId && queryParams.get('productType') === ProductType.EVENT)
        return eventParticipants.findOne({
          token,
          id: participantId + '',
          query: {
            populate: [
              'profile.badge',
              'event.section',
              'profile.orders.payment',
              'profile.orders.event.section',
            ],
          },
        });
      return null;
    },
    {
      enabled: Boolean(
        participantId && queryParams.get('productType') === ProductType.EVENT
      ),
      onSuccess: (_participant) => {
        if (_participant?.data) {
          const payment =
            _participant?.data?.attributes?.profile?.data?.attributes?.orders?.data?.find(
              (_order) => _order.attributes.event?.data.id === Number(eventId)
            );
          receiptForm.reset({
            entries,
            date: moment().format('YYYY-MM-DD'),
            isEvent: true,
            section:
              _participant?.data?.attributes?.event?.data?.attributes?.section
                ?.data?.id,
            badgeNumber:
              _participant?.data?.attributes?.profile?.data?.attributes?.badge
                ?.data?.attributes?.badgeNumber,
            type: `${formatReceiptType(ReceiptType.EVENT)} ${
              _participant?.data?.attributes?.event?.data?.attributes?.title
            }`,
            fiscalCode:
              _participant?.data?.attributes?.profile?.data?.attributes
                ?.fiscalCode,
            paymentMethod: payment?.attributes.payment?.data.attributes.method,
            payments,
            orders,
            profile: {
              value: _participant?.data?.attributes?.profile?.data?.id,
              label: `${_participant?.data?.attributes?.profile?.data?.attributes?.surname}`,
            },
            description: `Pagamento evento - ${_participant?.data?.attributes?.event?.data.attributes?.title}`,
          });
        }
      },
    }
  );

  const receiptForm = useForm<ReceiptRequest, ReceiptRequest>({
    resolver: useYupValidationResolver(receiptsValidator),
    defaultValues: { entries: [], date: moment().format('YYYY-MM-DD') },
  });

  const { mutate: mutateReceiptCreation, isLoading } = useMutation(
    'createReceipt',
    receipts.create,
    {
      onSuccess: () => {
        toast.success('Ricevuta creata con successo');
        if (queryParams.get('productType') === ProductType.COURSE)
          return navigate(`/corsi/dettaglio-corso/${courseId}/ricevute`);

        navigate(`/eventi/modifica/${eventId}/ricevute`);
      },
      onError: (err: any) => {
        toast.error(err.response.data.error.message);
      },
    }
  );

  const {
    mutate: mutateFreeReceiptCreation,
    isLoading: isCreatingFreeReceipt,
  } = useMutation('createReceipt', receipts.createFree, {
    onSuccess: () => {
      toast.success('Ricevuta creata con successo');
      if (queryParams.get('productType') === ProductType.COURSE)
        return navigate(`/corsi/dettaglio-corso/${courseId}/ricevute`);

      navigate(`/eventi/modifica/${eventId}/ricevute`);
    },
    onError: (err: any) => {
      toast.error(err.response.data.error.message);
    },
  });

  const { handleSubmit } = receiptForm;
  const onSubmit = (values: ReceiptRequest) => {
    const formattedReceipt = receiptToBackend(values);

    if (queryParams.get('mode') === 'FREE' && !isDelegato(profile)) {
      return mutateFreeReceiptCreation({
        token,
        body: {
          ...formattedReceipt,
          profile: formattedReceipt.profile?.value as any,
        },
      });
    }

    return mutateReceiptCreation({
      token,
      body: {
        ...formattedReceipt,
        profile: formattedReceipt.profile?.value as any,
        entries: formattedReceipt.entries?.map((_entry) => ({
          ..._entry,
          amount: sanitizeCurrencyValue(_entry.amount),
        })),
      },
    });
  };

  const onCancel = () => {
    receiptForm.reset({});
    navigate(-1);
  };

  return (
    <>
      <TitlePage title='Dettaglio corso | Crea ricevuta' />

      <div className='mt-6'>
        <div className='my-6'>
          <h5 className='text-lg text-gray-800'>Crea ricevuta</h5>

          {(isEventPartecipantSuccess || isCoursePartecipantSuccess) && (
            <ReceiptForm
              receiptForm={receiptForm}
              treasury={false}
              isPartecipantFetched={
                isCoursePartecipantSuccess || isEventPartecipantSuccess
              }
            />
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex items-center justify-start gap-4'>
            <PrimaryLinkFull onClick={onCancel} outline>
              Annulla
            </PrimaryLinkFull>

            <PrimaryButton
              disabled={isLoading || isCreatingFreeReceipt}
              isLoading={isLoading || isCreatingFreeReceipt}
              type='submit'
            >
              Crea ricevuta
            </PrimaryButton>
          </div>
        </form>
      </div>
    </>
  );
}
