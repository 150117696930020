import { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import * as Yup from 'yup';
import InputPresentational from '../Form/InputPresentational';
import UnderlineButton from '../Buttons/UnderlineButton';
import { useForm } from 'react-hook-form';
import {
  ParticipantsAudienceForm,
  ParticipantsAudienceType,
} from '../../interfaces/audiences';
import { RadioGroup } from '@headlessui/react';
import useYupValidationResolver from '../../libs/YupValidationResolver';
import { CheckIcon } from '@heroicons/react/outline';
import classNames from '../../libs/utils/classNames';

interface Props {
  onSubmit: (formValues: ParticipantsAudienceForm) => void;
  isLoading: boolean;
}

const getParticipationsAudienceSchemaValidator = (step: number) => {
  const step1 = Yup.object().shape({
    selectedParticipantsNumber: Yup.number().when('type', {
      is: 'SELECTION',
      then: Yup.number()
        .min(1, 'Devi selezionare almeno un partecipante')
        .required('Devi selezionare almeno un partecipante'),
      otherwise: Yup.number().optional(),
    }),
    type: Yup.string().required(),
    totalParticipantsNumber: Yup.number().when('type', {
      is: 'WHOLE',
      then: Yup.number()
        .min(1, 'Nessun partecipante trovato per i filtri applicati.')
        .required('Nessun partecipante trovato per i filtri applicati.'),
      otherwise: Yup.number().optional(),
    }),
  });

  const step2 = Yup.object().shape({
    title: Yup.string()
      .min(1, 'Inserire un titolo')
      .max(1500, 'Titolo troppo lungo')
      .typeError('Inserire un titolo'),
  });

  switch (step) {
    case 1:
      return step1;
    case 2:
      return step2;
    default:
      return {};
  }
};

const getAudienceTypeOptions = ({
  selectedParticipantsNumber,
  totalParticipantsNumber,
}: ParticipantsAudienceForm) => [
  {
    type: ParticipantsAudienceType.SELECTION,
    name: `Salva solo selezionati: ${selectedParticipantsNumber}`,
    description:
      'Crea audience in base ai filtri applicati e gli utenti che hai flaggato',
  },
  {
    type: ParticipantsAudienceType.WHOLE,
    name: `Salva tutti in tutte le pagine: ${totalParticipantsNumber}`,
    description: 'Crea audience in base ai filtri applicati',
  },
];

const CreateNewParticipantsAudienceModal: React.FC<
  Props & Partial<ParticipantsAudienceForm>
> = (props) => {
  const {
    onSubmit,
    isLoading,
    selectedParticipantsNumber = 0,
    totalParticipantsNumber = 0,
  } = props;

  const DEFAULT_FORM_VALUES = {
    totalParticipantsNumber,
    selectedParticipantsNumber,
    step: 1,
    type: ParticipantsAudienceType.SELECTION,
  };

  const AUDIENCE_PARTICIPANTS_TYPES = useMemo(
    () =>
      getAudienceTypeOptions({
        selectedParticipantsNumber,
        totalParticipantsNumber,
      }),
    [selectedParticipantsNumber, totalParticipantsNumber]
  );
  const [selected, setSelected] = useState(AUDIENCE_PARTICIPANTS_TYPES[0]);
  const [open, setOpen] = useState(false);
  const [hadSubmitted, setHadSubmitted] = useState(false);
  const [step, setStep] = useState(1);

  const userCount =
    selected.type === ParticipantsAudienceType.SELECTION
      ? selectedParticipantsNumber
      : totalParticipantsNumber;

  const nextStep = () => setStep((_oldStep) => _oldStep + 1);
  const prevStep = () => setStep((_oldStep) => _oldStep - 1);
  const resetStep = () => setStep(1);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    setValue,
  } = useForm<ParticipantsAudienceForm & ParticipantsAudienceType>({
    resolver: useYupValidationResolver(
      getParticipationsAudienceSchemaValidator(step)
    ),
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const toggleModal = () => {
    setOpen((v) => !v);
    resetStep();
    setSelected(AUDIENCE_PARTICIPANTS_TYPES[0]);
    reset(DEFAULT_FORM_VALUES);
  };

  useEffect(() => {
    setValue('selectedParticipantsNumber', selectedParticipantsNumber);
    setValue('totalParticipantsNumber', totalParticipantsNumber);
    return () => {};
  }, [selectedParticipantsNumber, totalParticipantsNumber]);

  const onNext = (
    values: ParticipantsAudienceForm & ParticipantsAudienceType
  ) => {
    if (step === 1) {
      return nextStep();
    }
    return onSubmit(values);
  };

  const onPrevious = () => {
    if (step === 2) {
      return prevStep();
    }

    return toggleModal();
  };

  useEffect(() => {
    if (isLoading) {
      setHadSubmitted(true);
    }

    if (hadSubmitted && !isLoading) {
      toggleModal();
      setHadSubmitted(false);
    }
  }, [isLoading, hadSubmitted]);

  return (
    <>
      <UnderlineButton onClick={toggleModal}>
        {'Salva audience'}
      </UnderlineButton>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-10 overflow-y-auto'
          onClose={toggleModal}
        >
          <div className='flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                className='relative inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6'
              >
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Salva Audience
                </h4>

                {step === 1 && (
                  <div className='w-full px-4'>
                    <div className='mx-auto w-full'>
                      <RadioGroup
                        value={selected}
                        onChange={(_participantType: any) => {
                          setValue('type', _participantType.type);
                          setSelected(_participantType);
                        }}
                      >
                        <RadioGroup.Label className='sr-only'>
                          Server size
                        </RadioGroup.Label>
                        <div className='grid-cols-2 grid grid-flow-col space-x-4'>
                          {AUDIENCE_PARTICIPANTS_TYPES.map((_mode) => (
                            <RadioGroup.Option
                              key={_mode.name}
                              value={_mode}
                              className={({ active, checked }) =>
                                classNames(
                                  active
                                    ? 'bg-primary/5 ring-2 ring-primary/60 ring-offset-2 ring-offset-primary/75'
                                    : '',
                                  checked
                                    ? 'border-primary/75 border-2'
                                    : 'bg-white',
                                  'relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none h-[110px]'
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <div>
                                  <div className='flex w-full items-center justify-between h-[60px]'>
                                    <div className='flex items-center'>
                                      <div className='text-sm'>
                                        <RadioGroup.Label
                                          as='p'
                                          className={`font-medium  ${'text-gray-900'}`}
                                        >
                                          {_mode.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as='span'
                                          className={`inline ${'text-gray-500'}`}
                                        >
                                          <span>{_mode.description}</span>
                                        </RadioGroup.Description>
                                      </div>
                                    </div>
                                    {checked && (
                                      <div className='shrink-0 text-white'>
                                        <CheckIcon className='ml-4 h-6 w-6 bg-primary rounded-full' />
                                      </div>
                                    )}
                                  </div>
                                  {_mode.type ===
                                    ParticipantsAudienceType.SELECTION &&
                                    errors.selectedParticipantsNumber && (
                                      <p className='mt-2 text-sm text-red-500'>
                                        {
                                          errors.selectedParticipantsNumber
                                            .message
                                        }
                                      </p>
                                    )}
                                  {_mode.type ===
                                    ParticipantsAudienceType.WHOLE &&
                                    errors.totalParticipantsNumber && (
                                      <p className='mt-2 text-sm text-red-500'>
                                        {errors.totalParticipantsNumber.message}
                                      </p>
                                    )}
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <div className='px-4 py-6 '>
                      <p className='text-gray-700 mb-2'>
                        Stai per salvare un Audience con{' '}
                        <span className='text-gray-700 bg-gray-100 px-2 py-1 rounded-md'>
                          {userCount} utent{userCount === 1 ? 'e' : 'i'}{' '}
                        </span>
                      </p>
                      <p>
                        <p className='text-sm text-gray-500 mb-4 max-w-xl'>
                          Il numero di utenti indicato include tutti quelli
                          nella lista, ma le comunicazioni saranno inviate solo
                          a chi ha accettato le notifiche secondarie, in linea
                          con le preferenze privacy.
                        </p>
                      </p>
                    </div>
                    <div className='px-4 py-6 '>
                      <InputPresentational
                        id='title'
                        label='Titolo'
                        name='title'
                        type='text'
                        register={register}
                        error={errors.title}
                      />
                    </div>
                  </div>
                )}

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton textSmall onClick={onPrevious}>
                    {step === 1 ? 'Annulla' : 'Indietro'}
                  </PrimaryButton>
                  <PrimaryButton
                    disabled={isLoading}
                    isLoading={isLoading}
                    small
                    type='button'
                    onClick={() => {
                      handleSubmit(onNext)();
                    }}
                  >
                    {step === 1 ? 'Avanti' : 'Salva'}
                  </PrimaryButton>
                </div>
              </form>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CreateNewParticipantsAudienceModal;
