import React, { ReactNode } from 'react';
import classNames from '../../libs/utils/classNames';

interface StaticInputPresentationalProps {
  label: string;
  text?: string | number;
  big?: boolean;
}
const StaticInputPresentational: React.FC<StaticInputPresentationalProps> = ({
  label,
  text,
  big,
}) => {
  return (
    <div className='flex flex-row space-x-2'>
      <span className='flex my-auto'>
        <StaticInputLabel className='text-center'>{label}</StaticInputLabel>
      </span>
      <StaticInputValue big={big}>{text}</StaticInputValue>
    </div>
  );
};

interface StaticInputLabelProps {
  children: ReactNode;
  className?: string;
}

export const StaticInputLabel: React.FC<StaticInputLabelProps> = (props) => {
  return (
    <label
      className={classNames(
        'flex text-sm text-gray-500',
        props.className ?? ''
      )}
    >
      {props.children}
    </label>
  );
};

interface StaticInputValueProps {
  children: ReactNode;
  big?: boolean;
}

export const StaticInputValue: React.FC<StaticInputValueProps> = ({
  big,
  children,
}) => {
  return (
    <p
      className={classNames(
        'text-gray-800 text-center my-auto',

        big ? 'text-2xl font-bold' : ''
      )}
    >
      {children}
    </p>
  );
};

export default StaticInputPresentational;
