import { Fragment, useEffect, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import Badge from '../Badge';
import {
  ParticipantStatus,
  ParticipantsResponse,
} from '../../interfaces/participants';
import * as Yup from 'yup';
import {
  Payment,
  PaymentMethod,
  PaymentStatus,
  PaymentResponse,
  PAYMENT_STATUSES_OPTIONS,
  PaymentStatusLabel,
} from '../../interfaces/payments';
import SelectPresentational from '../Form/SelectPresentational';
import PriceInputPresentational from '../Form/PriceInputPresentational';
import { useForm } from 'react-hook-form';
import { PAYMENT_METHOD_OPTIONS } from '../../libs/utils/options';
import { useAuth } from '../../contexts/Auth';
import useYupValidationResolver from '../../libs/YupValidationResolver';
import { ValidationErrorMessages } from '../../validators/errors';
import { isSegreteriaNazionale } from '../../libs/utils/auth';

interface StatusPaymentModalProps {
  participant?: ParticipantsResponse;
  entity: 'course' | 'event';
  onSubmit: ({
    payment,
    id,
  }: {
    payment: Partial<Payment>;
    id?: number;
  }) => void;
  disabled?: boolean;
  isLoading: boolean;
}

const StatusPaymentModal: React.FC<StatusPaymentModalProps> = ({
  participant,
  onSubmit,
  entity,
  disabled = false,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  const [{ settings, profile }] = useAuth();
  const [id] = useState(participant?.attributes[entity]?.data?.id + '');
  const payment = useMemo<PaymentResponse | undefined>(
    () =>
      participant?.attributes.profile?.data?.attributes?.orders?.data?.find(
        (elem) => Number(elem.attributes[entity]?.data?.id) === Number(id)
      )?.attributes?.payment,
    [participant]
  );

  useEffect(() => {
    let hadSubmitted = false;
    if (isLoading) {
      hadSubmitted = true;
    }

    if (hadSubmitted && !isLoading) {
      setOpen((v) => !v);
    }
  }, [isLoading]);

  if (!payment) return <>-</>;

  const { control, formState, handleSubmit, setValue, watch, reset } = useForm({
    resolver: useYupValidationResolver(
      Yup.object()
        .shape({
          method: Yup.string().when('status', {
            is: (status: PaymentStatus) =>
              [PaymentStatus.COMPLETED, PaymentStatus.PARTIAL].includes(status),
            then: Yup.string()
              .required(ValidationErrorMessages.REQUIRED)
              .typeError(ValidationErrorMessages.REQUIRED),
            otherwise: Yup.string().optional().nullable(),
          }),
          amount: Yup.number().when('status', {
            is: (status: PaymentStatus) => status === PaymentStatus.PARTIAL,
            then: Yup.number()
              .typeError('Prezzo non valido')
              .required(ValidationErrorMessages.REQUIRED),
            otherwise: Yup.number().optional(),
          }),
        })
        .required()
    ),
  });
  const { errors } = formState;

  const onChangePartialPrice = (value: string) => {
    //se viene immesso un valore maggiore del costo del corso viene settato il valore del corso stesso
    const amount = Math.round(
      Number(
        participant?.attributes[entity]?.data.attributes?.subscriptionAmount ||
          0
      ) +
        Number(
          participant?.attributes[entity]?.data.attributes?.headquarterBalance
            ?.administrations?.estimatedPriceUnit || 0
        )
    );

    const limit = Number(
      [
        PaymentMethod.BANK_TRANSFER_SECTION,
        PaymentMethod.BANK_TRANSFER_SITE,
      ].includes(watch('method'))
        ? Math.round(
            Number(amount || 0) +
              Number(amount) * (settings?.bankTransferFee || 0)
          )
        : amount
    );

    if (Number(value) > limit) {
      return setValue('amount', limit + '');
    }
    setValue('amount', value);
  };

  const onSave = (values: any) => {
    onSubmit({
      id: payment?.data?.id,
      payment: values,
    });
  };

  useEffect(() => {
    reset({
      amount: payment?.data?.attributes?.amount || 0,
      method: payment?.data?.attributes?.method,
      status: payment?.data?.attributes?.status,
    });
  }, [payment]);

  const isPaymentModalDisabled = useMemo(
    () =>
      [PaymentMethod.PAYPAL, PaymentMethod.AXERVE].includes(
        payment?.data?.attributes?.method as PaymentMethod
      ) ||
      [PaymentStatus.REFUNDED_PARTIAL, PaymentStatus.REFUNDED_TOTAL].includes(
        payment?.data?.attributes?.status as PaymentStatus
      ) ||
      !payment?.data?.attributes?.status ||
      participant?.attributes.status === ParticipantStatus.CANCELED ||
      disabled,
    [participant]
  );

  return (
    <>
      <button
        type='button'
        onClick={() => setOpen(true)}
        className={`flex justify-center ${
          payment?.data?.attributes?.method === PaymentMethod.PAYPAL ||
          participant?.attributes.status === ParticipantStatus.CANCELED
            ? 'cursor-not-allowed'
            : ''
        }`}
        disabled={isPaymentModalDisabled}
      >
        {getPaymentStatusBadge(payment?.data?.attributes?.status)}
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={setOpen}
        >
          <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <form
                onSubmit={handleSubmit(onSave)}
                className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6 h-2xl'
              >
                <h4 className='text-1.5xl font-IBM text-gray-700 mb-6'>
                  Stato del pagamento
                </h4>

                <fieldset className='mt-4'>
                  <div className='space-y-4'>
                    {PAYMENT_STATUSES_OPTIONS.filter(
                      (_paymentStatus) =>
                        ![
                          PaymentStatus.REFUNDED_PARTIAL,
                          PaymentStatus.REFUNDED_TOTAL,
                          PaymentStatus.REFUNDED,
                        ].includes(_paymentStatus.id)
                    ).map((_paymentStatus) => (
                      <div
                        key={_paymentStatus.id}
                        className='flex items-center'
                      >
                        <input
                          id={_paymentStatus.id}
                          name='notification-method'
                          type='radio'
                          defaultChecked={_paymentStatus.id === watch('status')}
                          checked={_paymentStatus.id === watch('status')}
                          onChange={() => setValue('status', _paymentStatus.id)}
                          className='focus:ring-primary h-4 w-4 text-primary border-gray-300'
                        />
                        <label
                          htmlFor={_paymentStatus.id}
                          className='ml-3 block font-normal text-gray-700'
                        >
                          {_paymentStatus.title}
                        </label>
                        {((_paymentStatus.id === PaymentStatus.PARTIAL &&
                          watch('status') === PaymentStatus.PARTIAL) ||
                          (_paymentStatus.id === PaymentStatus.COMPLETED &&
                            watch('status') === PaymentStatus.COMPLETED)) && (
                          <div className='grid grid-rows-1 grid-flow-col gap-2 w-full ml-2 mt-2'>
                            {watch('status') === PaymentStatus.PARTIAL && (
                              <div>
                                <PriceInputPresentational
                                  id='amount'
                                  label='Prezzo unitario'
                                  name='amount'
                                  error={{
                                    ...errors?.amount,
                                    type: 'required',
                                  }}
                                  value={watch('amount')}
                                  type='number'
                                  className='h-[38px] w-28 mr-3'
                                  // value={partialPrice}
                                  onChange={(e) =>
                                    onChangePartialPrice(e.target.value)
                                  }
                                  disabled={
                                    !participant?.attributes[entity]?.data
                                      .attributes?.subscriptionAmount
                                  }
                                  // onChange={(e) =>
                                  //   onChangePartialPrice(e.target.value)
                                  // }
                                />
                                {!participant?.attributes[entity]?.data
                                  .attributes?.subscriptionAmount && (
                                  <p className='fixed text-sm text-red-500'>
                                    {`Questo ${
                                      entity === 'course' ? 'corso' : 'evento'
                                    } non ha un costo!`}
                                  </p>
                                )}
                              </div>
                            )}

                            <div className=''>
                              <SelectPresentational
                                name='method'
                                label='Metodo di pagamento'
                                control={control}
                                error={{ ...errors?.method, type: 'required' }}
                                options={
                                  isSegreteriaNazionale(profile)
                                    ? PAYMENT_METHOD_OPTIONS.filter(
                                        (option) =>
                                          option.value !== PaymentMethod.PAYPAL
                                      )
                                    : PAYMENT_METHOD_OPTIONS.filter(
                                        (option) => {
                                          if (
                                            option.value ===
                                            PaymentMethod.PAYPAL
                                          )
                                            return false;
                                          if (
                                            Number(
                                              participant?.attributes[entity]
                                                ?.data?.attributes?.section
                                                ?.data?.attributes.costCenter
                                            ) === 1
                                          )
                                            return (
                                              option.value !==
                                              PaymentMethod.BANK_TRANSFER_SECTION
                                            );
                                          return (
                                            option.value !==
                                            PaymentMethod.BANK_TRANSFER_SITE
                                          );
                                        }
                                      )
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </fieldset>

                <div className='flex items-center justify-end gap-4 mt-6'>
                  <PrimaryButton small type='submit' isLoading={isLoading}>
                    Salva selezione
                  </PrimaryButton>
                </div>
              </form>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export const getPaymentStatusBadge = (status?: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.COMPLETED:
      return <Badge green text={PaymentStatusLabel[PaymentStatus.COMPLETED]} />;
    case PaymentStatus.PENDING:
      return <Badge yellow text={PaymentStatusLabel[PaymentStatus.PENDING]} />;
    case PaymentStatus.FAILED:
      return <Badge orange text={PaymentStatusLabel[PaymentStatus.FAILED]} />;
    case PaymentStatus.DRAFT:
      return <Badge gray text={PaymentStatusLabel[PaymentStatus.DRAFT]} />;
    case PaymentStatus.PARTIAL:
      return (
        <Badge greenOutline text={PaymentStatusLabel[PaymentStatus.PARTIAL]} />
      );
    case PaymentStatus.CANCELED:
      return <Badge red text={PaymentStatusLabel[PaymentStatus.CANCELED]} />;
    case PaymentStatus.REFUNDED_PARTIAL:
      return (
        <Badge teal text={PaymentStatusLabel[PaymentStatus.REFUNDED_PARTIAL]} />
      );
    case PaymentStatus.REFUNDED_TOTAL:
      return (
        <Badge teal text={PaymentStatusLabel[PaymentStatus.REFUNDED_TOTAL]} />
      );
    case PaymentStatus.REFUNDED:
      return <Badge teal text={PaymentStatusLabel[PaymentStatus.REFUNDED]} />;
    default:
      return <span>-</span>;
  }
};

export default StatusPaymentModal;
