import { useFormContext } from 'react-hook-form';
import AlertMessage from '../../../../components/AlertMessage';
import ProfileCategorySelect from '../../../../components/FetchSelect/ProfileCategorySelect';
import RoleSelect from '../../../../components/FetchSelect/RoleSelection';
import SectionsSelect from '../../../../components/FetchSelect/SectionsSelect';
import SimpleCheckboxPresentational from '../../../../components/Form/SimpleCheckboxPresentational';
import SectionsModal from '../../../../components/SectionsModal';
import H3Styled from '../../../../components/Typography/H3Styled';
import UserBooleanBox from '../../../../components/UserBooleanBox';
import { useAuth } from '../../../../contexts/Auth';
import { Profile } from '../../../../interfaces/profile';
import { isDelegato } from '../../../../libs/utils/auth';
import { BadgeStatus } from '../../../../interfaces/badge';
import { IdentificationIcon } from '@heroicons/react/outline';
import { useQuery } from 'react-query';
import sectionsAPIs, { getProfileJobs } from '../../../../api/section';
import { useNavigate, useParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import UnderlineButton from '../../../../components/Buttons/UnderlineButton';
import getOnavPositionLabel from '../../../Sezioni/helpers/getOnavPositionLabel';
import InputPresentational from '../../../../components/Form/InputPresentational';

const LEGEND_ICONS = [<IdentificationIcon className='text-primary' key={1} />];

const ModificaUtenteDatiOnav = ({ userInfo }: { userInfo: Profile }) => {
  const { register, control, setValue } = useFormContext();
  const [{ profile, token }] = useAuth();
  const { id: profileId } = useParams();
  const navigate = useNavigate();

  const profileJobsQuery = useQuery('getUserJobs', () =>
    getProfileJobs({ token, profileId: Number(profileId) })
  );

  const { data: sectionDelegation } = useQuery(
    ['get-section-delegation', profile?.section?.data?.id],
    () =>
      sectionsAPIs.findDelegationBySectionId({
        id: userInfo.attributes?.section?.data?.id,
      }),
    {
      enabled: Boolean(profile?.section?.data?.id),
    }
  );

  return (
    <>
      <div className='mt-12'>
        <div className='flex justify-start flex-col sm:flex-row sm:justify-between'>
          <H3Styled>Dati ONAV utente</H3Styled>
          <div className='mt-2 sm:mt-0'>
            <ul className='flex flex-row'>
              <li className='flex flex-col sm:flex-row'>
                <strong>*</strong>
                <span className='text-sm text-gray-500 px-2'>
                  Campi obbligatori
                </span>
              </li>
              <li className='flex flex-row'>
                <IdentificationIcon className='h-5 w-5 text-primary' key={1} />
                <span className='text-sm text-gray-500 px-2'>
                  Campi necessari per acquistare la tessera ONAV
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className='grid items-start grid-cols-1 pt-6 pb-8 mb-12 md:grid-cols-2 gap-x-8 gap-y-6'>
          <div className='grid gap-6'>
            <RoleSelect
              disabled={
                isDelegato(profile) || !userInfo?.attributes?.user?.data
              } // Non puoi segliere un ruolo se non sei sereteria nazionale o se non hai ancora un utente associato
              control={control}
              defaultValue={
                userInfo?.attributes?.user?.data?.attributes?.role?.name
              }
            />
            {/* <InputPresentational
              id='title'
              label='Titolo ONAV - categoria'
              name='title'
              type='text'
              disabled
              defaultValue={
                userInfo?.attributes.profileCategory?.data?.attributes.title
              }
            /> */}
            <ProfileCategorySelect
              disabled={isDelegato(profile)}
              control={control}
              defaultValue={userInfo?.attributes?.profileCategory?.data?.id}
              name='profileCategory'
              label='Titolo ONAV - categoria'
            />
          </div>
          <div className='space-y-4'>
            {/* Se hai una tessera (badge) e nell'anno corrente non l'hai pagata (cardHistory) mostra l'avviso */}
            {userInfo?.attributes?.badge.data?.attributes?.status !==
              BadgeStatus.VALID &&
              userInfo?.attributes?.badge?.data?.attributes?.badgeNumber && (
                <AlertMessage
                  borderLeft
                  button='Vedi storico tessera'
                  href={`/utenti/modifica/${userInfo.id}/storico-tessera`}
                  text={"Rinnovo non pagato per l'anno corrente"}
                />
              )}

            <UserBooleanBox userInfo={userInfo} setValueForm={setValue} />
          </div>
        </div>
        <H3Styled>Ruoli istituzionali</H3Styled>
        <div className='w-full p-8 my-6 space-y-4 text-gray-600 rounded-lg bg-neutral-50 height-auto mb-14'>
          {match(profileJobsQuery)
            .with({ status: 'idle' }, { status: 'loading' }, () => <div></div>)
            .with({ status: 'error' }, () => (
              <div>Errore caricamento ruoli istituzionali</div>
            ))
            .with({ status: 'success' }, (query) => {
              const { data: mansioni } = query?.data;
              return mansioni.length ? (
                <div className='w-full'>
                  <table className='w-full'>
                    <thead className='text-left'>
                      <tr>
                        <th>RUOLO ISTITUZIONALE</th>
                        <th>SEZIONE</th>
                        {/* <th>MANSIONE</th> */}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {mansioni?.map((mansione) => {
                        const { section, onavPosition } = mansione?.attributes;
                        return (
                          <tr>
                            <td>
                              {' '}
                              {getOnavPositionLabel(
                                onavPosition?.data?.attributes?.role
                              )}
                            </td>
                            <td>
                              {section?.data
                                ? section?.data?.attributes?.name
                                : ''}
                            </td>
                            {/* <td>{mansione.attributes.job}</td> */}
                            <td>
                              <UnderlineButton
                                onClick={() => {
                                  navigate(
                                    `/sezioni/modifica/${mansione?.attributes?.section?.data?.id}/mansioni`
                                  );
                                }}
                              >
                                Modifica
                              </UnderlineButton>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                'Nessun ruolo istituzionale assegnato'
              );
            })
            .exhaustive()}
        </div>
        <H3Styled>Digital ONAV</H3Styled>
        <div className='w-full p-8 my-6 space-y-4 text-gray-600 rounded-lg bg-neutral-50 height-auto mb-14 md:w-1/2'>
          <fieldset className='space-y-5'>
            <div className='flex items-center'>
              <SimpleCheckboxPresentational
                id='printDigitalBadge'
                label={`Tessera SOLO digitale`}
                name='printDigitalBadge'
                defaultValue={userInfo?.attributes?.printDigitalBadge}
                register={register}
              />
            </div>

            <SimpleCheckboxPresentational
              id='printDigitalMagazine'
              label={`Rivista SOLO digitale`}
              name='printDigitalMagazine'
              defaultValue={userInfo?.attributes?.printDigitalMagazine}
              register={register}
            />
          </fieldset>
        </div>

        <H3Styled>Sezione di appartenenza</H3Styled>
        <div className='pt-6 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 pb-60'>
          <div className='grid gap-x-8 gap-y-6'>
            <SectionsSelect
              label='Sezione di appartenenza'
              control={control}
              defaultValue={userInfo?.attributes?.section?.data?.id}
              required
              legendIcons={LEGEND_ICONS}
            />
            {sectionDelegation?.name && (
              <InputPresentational
                id='delegation'
                label='Delegazione regionale di appartenenza'
                name='delegation'
                type='text'
                disabled
                value={sectionDelegation.name}
              />
            )}
          </div>
          <div>
            <label className='block text-sm text-gray-500'>
              Altre sezioni seguite
            </label>

            <SectionsModal
              defaultValues={
                userInfo?.attributes?.secondarySections?.data || []
              }
              setValueForm={setValue}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModificaUtenteDatiOnav;
