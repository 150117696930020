import { match } from 'ts-pattern';
import StaticInputPresentational from '../../components/Form/StaticInputPresentational';
import H4SpacingLetter from '../../components/Typography/H4SpacingLetter';
import FormLoading from '../../components/Layout/Loading/FormLoading';
import H3Styled from '../../components/Typography/H3Styled';
import { Statistics } from '../../interfaces/statistics';
import { UseQueryResult } from 'react-query';

interface Props {
  statisticsQuery: UseQueryResult<Statistics, unknown>;
}

const GeneralStatistics: React.FunctionComponent<Props> = (props) => {
  const { statisticsQuery } = props;
  return (
    <div className='bg-neutral-50 py-8 px-4 rounded-lg space-y-8'>
      {match(statisticsQuery)
        .with({ status: 'idle' }, { status: 'loading' }, () => (
          <FormLoading numberItems={1} />
        ))
        .with({ status: 'error' }, () => (
          <div>Errore nel recupero delle statistiche...</div>
        ))
        .with({ status: 'success' }, ({ data: statisticsData }) => (
          <>
            <div className='space-y-4'>
              <H3Styled>{`Statistiche ${statisticsData?.year} (Ultimo aggiornamento: ${statisticsData?.lastUpdate})`}</H3Styled>
              <H4SpacingLetter>Indicatori totali CORSI</H4SpacingLetter>

              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                <StaticInputPresentational
                  label='Corsi pubblicati a partire dal 1 gennaio'
                  text={statisticsData?.coursesReports?.published || 0}
                />
              </div>
            </div>

            <div className='space-y-4'>
              <H4SpacingLetter>Indicatori totali EVENTI</H4SpacingLetter>

              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                <StaticInputPresentational
                  label='Eventi pubblicati a partire dal 1 gennaio'
                  text={statisticsData?.eventsReports?.published || 0}
                />
              </div>
            </div>
            <div className='space-y-4'>
              <H4SpacingLetter>Statistiche sottoscrizioni</H4SpacingLetter>

              <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                <StaticInputPresentational
                  label={`Soci`}
                  text={statisticsData?.subscriptionsReports?.totals || 0}
                />
                <StaticInputPresentational
                  label={`Rinnovi`}
                  text={`${
                    statisticsData?.subscriptionsReports?.renewals || 0
                  } (${
                    statisticsData?.subscriptionsReports?.renewalsPercentage ||
                    0
                  } % del ${statisticsData?.year! - 1})`}
                />
                <StaticInputPresentational
                  label={`Soci nuovi`}
                  text={statisticsData?.subscriptionsReports?.newSubs || 0}
                />
                <StaticInputPresentational
                  label={`Soci riattivati`}
                  text={
                    statisticsData?.subscriptionsReports?.reenrollments || 0
                  }
                />
                <StaticInputPresentational
                  label={`Junior`}
                  text={`${
                    statisticsData?.subscriptionsReports?.juniors || 0
                  } (${
                    statisticsData?.subscriptionsReports?.juniorsPercentage || 0
                  } % sul totale)`}
                />
              </div>
            </div>
          </>
        ))
        .exhaustive()}
    </div>
  );
};

export default GeneralStatistics;
