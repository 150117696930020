import {
  Balance,
  HeadquarterBalance,
} from '../../interfaces/strapiComponents/balance';

const balanceVoiceKeys = (balance: Balance) =>
  Object.keys(balance ?? {}) as unknown as (keyof Balance)[];

const headquarterBalanceVoiceKeys = (headquarterBalance: HeadquarterBalance) =>
  Object.keys(
    headquarterBalance ?? {}
  ) as unknown as (keyof HeadquarterBalance)[];

export const getEstimatedOutflow = (
  balance: Balance,
  voicesToSkip?: string[]
) => {
  const estimatedOutflows = balanceVoiceKeys(balance).reduce(
    (estimatedOutflows, balanceVoice) => {
      const skipVoice =
        voicesToSkip?.indexOf(balanceVoice)! > -1 || balanceVoice === 'id';
      if (skipVoice) return estimatedOutflows;
      return estimatedOutflows + (balance[balanceVoice]?.estimatedOutflow || 0);
    },
    0
  );
  return estimatedOutflows;
};

export const getEstimatedIncome = (balance: Balance) => {
  const estimatedIncomes = balanceVoiceKeys(balance).reduce(
    (estimatedIncomes, balanceVoice) => {
      if (balanceVoice === 'id') return estimatedIncomes;

      return estimatedIncomes + (balance[balanceVoice]?.estimatedIncome || 0);
    },
    0
  );

  return estimatedIncomes;
};

export const getFinalOutflow = (balance: Balance, voicesToSkip?: string[]) => {
  const finalOutflows = balanceVoiceKeys(balance).reduce(
    (finalOutflows, balanceVoice) => {
      const skipVoice =
        voicesToSkip?.indexOf(balanceVoice)! > -1 || balanceVoice === 'id';
      if (skipVoice) return finalOutflows;
      return finalOutflows + (balance[balanceVoice]?.finalOutflow || 0);
    },
    0
  );

  return finalOutflows;
};

export const getFinalIncome = (balance: Balance) => {
  const finalIncomes = balanceVoiceKeys(balance).reduce(
    (finalIncomes, balanceVoice) => {
      if (balanceVoice === 'id') return finalIncomes;
      return finalIncomes + (balance[balanceVoice]?.finalIncome || 0);
    },
    0
  );
  return finalIncomes;
};

export const getEstimatedHeadquarterOutflow = (
  headquarterBalance: HeadquarterBalance
) => {
  const estimatedOutflow = headquarterBalanceVoiceKeys(
    headquarterBalance
  ).reduce((estimatedOutflow, balanceVoice) => {
    if (balanceVoice === 'id') return estimatedOutflow;
    return (
      estimatedOutflow +
      (headquarterBalance[balanceVoice]?.estimatedOutflow || 0)
    );
  }, 0);

  return estimatedOutflow;
};

export const getEstimatedHeadquarterIncome = (
  headquarterBalance: HeadquarterBalance
) => {
  const estimatedIncomes = headquarterBalanceVoiceKeys(
    headquarterBalance
  ).reduce((estimatedIncomes, balanceVoice) => {
    if (balanceVoice === 'id') return estimatedIncomes;

    return (
      estimatedIncomes +
      (headquarterBalance[balanceVoice]?.estimatedIncome || 0)
    );
  }, 0);

  return estimatedIncomes;
};

export const getFinalHeadquarterOutflow = (
  headquarterBalance: HeadquarterBalance
) => {
  const finalOutflow = headquarterBalanceVoiceKeys(headquarterBalance).reduce(
    (finalIncomes, balanceVoice) => {
      if (balanceVoice === 'id') return finalIncomes;
      return (
        finalIncomes + (headquarterBalance[balanceVoice]?.finalOutflow || 0)
      );
    },
    0
  );
  return finalOutflow;
};

export const getFinalHeadquarterIncome = (
  headquarterBalance: HeadquarterBalance
) => {
  const finalIncome = headquarterBalanceVoiceKeys(headquarterBalance).reduce(
    (finalIncomes, balanceVoice) => {
      if (balanceVoice === 'id') return finalIncomes;
      return (
        finalIncomes + (headquarterBalance[balanceVoice]?.finalIncome || 0)
      );
    },
    0
  );
  return finalIncome;
};
