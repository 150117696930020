import axios from 'axios';

import qs from 'qs';
import { ProfileListResponse, ProfileResponse } from '../interfaces/profile';

const { REACT_APP_API_URL } = process.env;
const instance = axios.create();

export const profileInfo = async ({
  id,
  token,
  filters,
}: {
  id: number;
  token: string | null;
  filters?: object;
}) => {
  const query = {
    'populate[region][populate]': '*',
    'populate[nation][populate]': '*',
    'populate[section][populate]': '*',
    'populate[province][populate]': '*',
    'populate[homeAddress][populate]': '*',
    'populate[secondarySections][populate]': '*',
    'populate[profileCategory][populate]': '*',
    'populate[delegation][populate]': '*',
    'populate[onavPosition][populate]': '*',
    'populate[profileImage][populate]': '*',
    'populate[badge][populate]': '*',
    'populate[user][populate]': '*',
    'populate[orders][populate]': '*',
    filters,
  };

  const { data } = await axios.get<ProfileResponse>(
    `${REACT_APP_API_URL}/profiles/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const profileInfoFromUser = async (id: number, token: string | null) => {
  const query = qs.stringify({
    filters: {
      user: {
        id: { $eq: id },
      },
    },
  });

  const { data } = await axios.get<ProfileListResponse>(
    `${REACT_APP_API_URL}/profiles?${query}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return profileInfo({ id: data.data[0].id, token });
};

export const profilelist = async (searchParams: any, token: string | null) => {
  const query = {
    populate: '*',

    filters: {
      isTeacher: { $eq: searchParams.get('isTeacher') },
      printDigitalMagazine: { $eq: searchParams.get('printDigitalMagazine') },
      isVolunteer: { $eq: searchParams.get('isVolunteer') },
      onavPosition: { name: { $eq: searchParams.getAll('onavPosition') } },
      section: {
        name: { $eq: searchParams.getAll('section') },
      },
      profileCategory: {
        title: { $eq: searchParams.getAll('profileCategory') },
      },
      $or: [
        {
          name: { $contains: searchParams.get('search') },
        },
        {
          surname: { $contains: searchParams.get('search') },
        },

        {
          user: {
            email: { $contains: searchParams.get('search') },
          },
        },
      ],
    },
    pagination: {
      page: searchParams.get('page'),
      pageSize: 1000,
    },
    sort: { updatedAt: 'desc' },
  };

  const { data } = await instance.get<ProfileListResponse>(
    `${REACT_APP_API_URL}/profiles?${qs.stringify(query, { skipNulls: true })}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const createProfile = async ({
  body,
  token,
}: {
  body: any;
  token: string | null;
}) => {
  //TODO: type body

  const response = await instance.post(
    `${REACT_APP_API_URL}/auth/local/register`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );

  return response;
};

export const updateProfile = async ({
  body,
  token,
  id,
}: {
  body: any;
  token: string | null;
  id: number;
}) => {
  //TODO: type body
  const response = await instance.put(
    `${REACT_APP_API_URL}/profiles/${id}`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const deleteProfile = async ({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) => {
  const response = await instance.delete(
    `${REACT_APP_API_URL}/profiles/${id}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const deleteUser = async ({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) => {
  const response = await instance.delete(`${REACT_APP_API_URL}/users/${id}`, {
    headers: { authorization: `Bearer ${token}` },
  });
  return response;
};

export const confirmUser = async ({
  id,
  token,
}: {
  id: number;
  token: string | null;
}) => {
  const response = await instance.put(
    `${REACT_APP_API_URL}/users/${id}`,
    { confirmed: true },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return response;
};

export const getTeachersSpendings = async ({
  profileId,
  token,
}: {
  profileId: any;
  token: string | null;
}) => {
  const query = {
    profileId,
  };

  const response = await axios({
    url: `${REACT_APP_API_URL}/pdf/find-teacher-spendings?${qs.stringify(
      query
    )}`, //your url
    method: 'GET',
    headers: { authorization: `Bearer ${token}` },
    responseType: 'blob', // important
  });

  if (axios.isAxiosError(response)) throw response;
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', 'file.pdf'); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const bulkUpdateProfiles = async ({
  body,
  token,
}: {
  body: { profileIds: number[]; data: object };
  token?: string | null;
}) => {
  const { data } = await instance.put<{ data: object[] }>(
    `${REACT_APP_API_URL}/profiles/bulk-update`,
    { data: body },
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const getProfiles = async ({
  query = {},
  token,
}: {
  query?: object;
  token?: string | null;
}) => {
  const { data } = await instance.get<ProfileListResponse>(
    `${REACT_APP_API_URL}/profiles?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const getOneProfile = async ({
  query = {},
  token,
  id,
}: {
  query?: object;
  token?: string | null;
  id?: string | null;
}) => {
  const { data } = await instance.get<ProfileResponse>(
    `${REACT_APP_API_URL}/profiles/${id}?${qs.stringify(query)}`,
    {
      headers: { authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const createDiploma = async ({
  query = {},
  token,
  body,
}: {
  query?: object;
  token?: string | null;
  body: {
    category: number | string;
    profile: number | string;
    courseLevel: number | string;
    evaluation?: number;
    cumLaude: boolean;
    customDate?: string;
    customLocation: number | string;
    withBackground?: boolean;
  };
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/profiles/user-diploma`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `Diploma ${body.courseLevel} ${new Date().getTime()}.pdf`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const downloadProfilesCSV = async ({
  token,
  body,
  query,
  fileName,
}: {
  token?: string | null;
  body: Object;
  query?: Object;
  fileName: string;
}) => {
  const { data } = await instance.post(
    `${REACT_APP_API_URL}/profiles/csv?${qs.stringify(query)}`,
    body,
    {
      headers: { authorization: `Bearer ${token}` },
      responseType: 'blob',
    }
  );
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(data as any);
  link.download = `${fileName}.csv`;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};
