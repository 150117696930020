interface Props {
  children: (string | number | React.ReactElement | null)[];
}

const CustomUnorderedList: React.FunctionComponent<Props> = (props) => {
  const { children } = props;
  return (
    <ul className='list-disc pl-5'>
      {children.map((_child) => (
        <li>{_child}</li>
      ))}
    </ul>
  );
};

export default CustomUnorderedList;
