import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PrimaryButton from '../Buttons/PrimaryButton';
import UnderlineButton from '../Buttons/UnderlineButton';
import InputPresentational from '../Form/InputPresentational';
import { Profile } from '../../interfaces/profile';
import * as Yup from 'yup';
import { useAuth } from '../../contexts/Auth';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { shouldUserSubscribe } from '../../libs/utils/helpers';
import ConfirmationModal from './ConfirmationModal';
import { Order, ProductType } from '../../interfaces/orders';
import { PaymentMethod, PaymentStatus } from '../../interfaces/payments';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import orders from '../../api/orders';
import { toast } from 'react-toastify';
import { PAYMENT_METHOD_OPTIONS } from '../../libs/utils/options';
import useYupValidationResolver from '../../libs/YupValidationResolver';
import { ControlledSelect } from '../Form/Selects';
import appSettings from '../../api/appSettings';
import { useDebounce } from 'use-debounce';
import { StrapiData } from '../../interfaces/commons';
import SectionsSelect from '../FetchSelect/SectionsSelect';
import { ValidationErrorMessages } from '../../validators/errors';

interface RenewCardModalProps {
  infoUser: Profile;
  subscription: Partial<StrapiData<Order>>;
  buttonType: 'table' | 'header';
  defaultYear?: number;
}

const RenewCardModal: React.FC<RenewCardModalProps> = ({
  infoUser,
  subscription,
  buttonType,
  defaultYear,
}) => {
  const [open, setOpen] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [{ token, settings }] = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { register, handleSubmit, control, reset, formState, setValue, watch } =
    useForm<{
      method?: PaymentMethod | null;
      date: string;
      amount: number;
      autoReceipt: boolean;
      year: number;
      section: number | null;
    }>({
      resolver: useYupValidationResolver(
        Yup.object().shape({
          method: Yup.string().required('Questo campo è obbligatorio'),
          date: Yup.string().required('Questo campo è obbligatorio'),
          amount: Yup.string().required('Questo campo è obbligatorio'),
          section: Yup.number().when('method', {
            is: PaymentMethod.BANK_TRANSFER_SECTION,
            then: Yup.number()
              .typeError(ValidationErrorMessages.REQUIRED)
              .required(ValidationErrorMessages.REQUIRED),
            otherwise: Yup.number().nullable(true),
          }),
          year: Yup.number()
            .min(
              2000,
              "Anno rinnovo troppo basso. L'anno deve essere superiore a 2000."
            )
            .max(
              new Date().getFullYear() + 1,
              `Anno rinnovo troppo alto. L'anno deve essere inferiore a ${
                new Date().getFullYear() + 1
              }`
            )
            .required('Questo campo è obbligatorio'),
        })
      ),
    });

  const [debouncedYear] = useDebounce(watch('year'), 500);

  const { errors } = formState;

  const { mutate: mutateOrderUpdate, isLoading: isUpdatingOrder } = useMutation(
    'updateOrderPayment',
    orders.update,
    {
      onSuccess: () => {
        queryClient.refetchQueries('getOrdersForCardHistories');
        queryClient.refetchQueries('profile2');
        toast.success('Pagamento tessera aggiornato con successo.');
        toggleRenew();
      },
      onError: (err: any) => {
        toast.error(
          err.response?.data?.error?.message || 'Ooops qualcosa è andato storto'
        );
      },
    }
  );

  const { mutate: mutateOrderCreation, data: orderData } = useMutation(
    'createOrder',
    orders.create,
    {
      onSuccess: () => {
        toggleRenew();
      },
      onError: (err: any) => {
        toast.error(
          err.response?.data?.error?.message || 'Ooops qualcosa è andato storto'
        );
      },
    }
  );

  const toggleWarning = () => setIsWarningOpen((v) => !v);
  const toggleRenew = () => {
    if (open) queryClient.invalidateQueries(['getOrdersForCardHistories']);
    setOpen((v) => !v);
  };

  const onSubmit = async (data: {
    method?: PaymentMethod | null;
    date: string;
    amount: number;
    autoReceipt: boolean;
    year: number;
    section: number | null;
  }) => {
    if (step === 1) return setStep((v) => v + 1);
    mutateOrderUpdate({
      token,
      /**
       * Se c'è orderData significa che la subscription è appena stata creata
       * altrimenti è "vecchia" e prendo l'id dalla subscription
       */
      id: orderData?.data.id || subscription.id,
      body: {
        payment: {
          date: data.date,
          method: data.method,
          status: PaymentStatus.COMPLETED,
        },
        cardHistory: { year: data.year },
        autoReceipt: data.autoReceipt,
        section: data.section,
      },
      query: { populate: '*' },
    });
  };

  const handleRenewModal = () => {
    if (!shouldUserSubscribe(infoUser)) {
      return toggleWarning();
    }
    if (!subscription.attributes?.payment) {
      return mutateOrderCreation({
        token,
        body: {
          profile: infoUser.id,
          productType: ProductType.SUBSCRIPTION,
          amount: settings?.subscription.amount,
          year: subscription?.attributes?.cardHistory?.data?.attributes?.year,
          section: null,
        },
      });
    }

    if (!!watch('year') && !!infoUser.id) {
      refetchSubSettings();
    }
    toggleRenew();
    // searchParams.delete('newBadge');
    // setSearchParams(searchParams);
  };

  useEffect(() => {
    if (infoUser?.attributes?.settings?.subscription?.amount) {
      reset({
        method: PaymentMethod.BANK_TRANSFER_SITE,
        amount: infoUser?.attributes?.settings?.subscription?.amount,
        date: moment(
          subscription?.attributes?.payment?.data?.attributes?.date ||
            new Date()
        ).format('YYYY-MM-DD'),
        year:
          defaultYear ||
          subscription?.attributes?.cardHistory?.data?.attributes?.year ||
          new Date().getFullYear(),
      });
    }
  }, [infoUser, subscription]);

  useEffect(() => {
    if (watch('method') !== PaymentMethod.BANK_TRANSFER_SECTION) {
      setValue('section', null);
    }
  }, [watch('method')]);

  const { refetch: refetchSubSettings } = useQuery(
    ['getSubSettings', debouncedYear],
    () =>
      appSettings.mySettings({
        token,
        query: {
          year: debouncedYear,
          profile: infoUser.id,
        },
      }),
    {
      enabled: !!watch('year'),
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        setValue('amount', data.subscription.amount);
      },
    }
  );

  return (
    <>
      {buttonType === 'table' && (
        <UnderlineButton small onClick={handleRenewModal}>
          {infoUser.attributes.badge.data?.attributes?.badgeNumber
            ? 'Rinnova'
            : 'Associa utente'}
        </UnderlineButton>
      )}

      {buttonType === 'header' && (
        <button
          onClick={() => setOpen(true)}
          type='button'
          className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30'
        >
          Rinnova selezionati
        </button>
      )}

      <ConfirmationModal
        isOpen={isWarningOpen}
        toggle={toggleWarning}
        title='Profilo incompleto'
        subtitle='Il profilo di questo utente è incompleto. Prima di procedere è necessario compilare i dati del suo profilo.'
        textButton='Vai'
        onConfirm={() => navigate(`/utenti/modifica/${infoUser.id}`)}
        cancelTextButton='Annulla'
      />

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as='div'
          className='fixed z-10 inset-0 overflow-y-auto'
          onClose={toggleRenew}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'
          >
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6'>
                <h4 className='text-1.5xl font-IBM font-light text-gray-700 mb-6'>
                  Rinnova tessera
                </h4>

                {step === 1 && (
                  <>
                    <p className='text-gray-500 font-light'>
                      Confermi di voler rinnovare la tessera dell’utente{' '}
                      {infoUser.attributes.surname +
                        ' ' +
                        infoUser.attributes.name}{' '}
                      per l'anno indicato ?
                    </p>

                    <div
                      className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8'
                      key={'renewForm'}
                    >
                      <InputPresentational
                        id='date'
                        label='Data'
                        name='date'
                        type='date'
                        register={register}
                        required
                      />
                      {/* TODO: Aggiungere il bonifico come pagamento di default non tramite ID (potrebbe cambiare) */}
                      <ControlledSelect
                        name='method'
                        label='Metodo di pagamento'
                        options={PAYMENT_METHOD_OPTIONS.filter(
                          (option) => option.value !== PaymentMethod.PAYPAL
                        )}
                        control={control as any}
                        error={errors.method}
                      />
                      {watch('method') ===
                        PaymentMethod.BANK_TRANSFER_SECTION && (
                        <SectionsSelect
                          control={control}
                          required
                          error={{ ...errors?.section, type: 'required' }}
                          register={register}
                          excludeCostCenters={[1]}
                        />
                      )}
                      <InputPresentational
                        id='amount'
                        label='Importo'
                        name='amount'
                        type='number'
                        register={register}
                        disabled
                      />
                      <InputPresentational
                        id='year'
                        label='Anno'
                        name='year'
                        type='number'
                        register={register}
                        error={errors.year}
                        disabled={!!defaultYear}
                      />
                    </div>

                    <div className='flex items-center justify-end gap-4 mt-6'>
                      <PrimaryButton textSmall onClick={toggleRenew}>
                        Annulla
                      </PrimaryButton>
                      <PrimaryButton onClick={handleSubmit(onSubmit)}>
                        Avanti
                      </PrimaryButton>
                    </div>
                  </>
                )}
                {step === 2 && (
                  <>
                    <p className='text-gray-500 font-light'>
                      Creare automaticamente una ricevuta per questa tessera?
                    </p>

                    <div className='flex items-center justify-end gap-4 mt-6'>
                      <PrimaryButton
                        textSmall
                        onClick={() => setStep((v) => v - 1)}
                        disabled={isUpdatingOrder}
                        isLoading={isUpdatingOrder}
                      >
                        Indietro
                      </PrimaryButton>
                      <PrimaryButton
                        disabled={isUpdatingOrder}
                        outline
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isUpdatingOrder}
                      >
                        no
                      </PrimaryButton>
                      <PrimaryButton
                        onClick={() =>
                          handleSubmit((values) =>
                            onSubmit({ ...values, autoReceipt: true })
                          )()
                        }
                        disabled={isUpdatingOrder}
                        isLoading={isUpdatingOrder}
                      >
                        Sì
                      </PrimaryButton>
                    </div>
                  </>
                )}
              </div>
            </Transition.Child>
          </form>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RenewCardModal;
